import { getNames } from 'i18n-iso-countries';
import { orderBy } from 'lodash';

export type SelectOption = Record<string, string>;

export const getCountryNames = (locale: string) => {
  const names = getNames(locale);
  if (Object.keys(names).length === 0) {
    return getNames('en');
  }
  return names;
};

export function getCountriesSelectOptions(locale: string): SelectOption[] {
  const countriesDict = getCountryNames(locale);

  const options = Object.keys(countriesDict).reduce(
    (opt: SelectOption[], countryKey: string) => {
      opt.push({ label: countriesDict[countryKey], value: countryKey });
      return opt;
    },
    [],
  );

  return orderBy(options, ['label']);
}
