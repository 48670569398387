import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Outlet,
  useLocation,
  useNavigate,
  generatePath,
  Link as ReactLink,
} from 'react-router';
import { AltArrowBack } from '@airhelp/icons';
import {
  Box,
  Flex,
  Heading,
  Link,
  type Tab,
  TabList,
  Tabs,
  useBreakpointValue,
} from '@chakra-ui/react';
import JourneyActions from 'components/Journey/JourneyActions';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot';
import { isRtl } from 'utils/textDirection/isRtl';
import { type Journey } from 'models/journey/Journey';
import { useTopNav } from 'components/Layouts/BaseLayout/TopNav/TopNavProvider';
import GenerateBreadcrumbs, {
  type BreadcrumbItem,
} from 'elements/GenerateBreadcrumbs';
import CustomTab from './CustomTab';

export enum JourneyTab {
  FLIGHTS = 'flights',
  INSURANCE = 'insurance',
}

interface Tab {
  eventName: JourneyTab;
  key: JourneyTab;
  label: string;
  isActive: boolean;
  path: string;
  notification?: boolean;
}

const JourneyView = ({ journey }: { journey: Journey }) => {
  const { t, i18n } = useTranslation();
  const locale = useLocale();
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageInteractions } = useTrackEvent();

  const topNav = useTopNav();
  const TopBarPortal = topNav.TopBarPortal;

  usePageNavigationHeader(
    {
      title: t('navigation.your_trip'),
      backPath: generatePath(PAGE_PATHS.JOURNEYS_PAGE),
      actionSpace: <JourneyActions journey={journey} />,
    },
    [journey.id],
  );

  const airPayoutProtected = journey.protections.airPayout.protected;
  const airLuggageProtected = journey.protections.airLuggage.protected;

  const showInsuranceTab = airPayoutProtected || airLuggageProtected;

  const tabs: Tab[] = useMemo(() => {
    const tabsArray: Tab[] = [];
    if (showInsuranceTab) {
      tabsArray.push({
        eventName: JourneyTab.FLIGHTS,
        key: JourneyTab.FLIGHTS,
        label: t('common.flights'),
        isActive: location.pathname.includes(JourneyTab.FLIGHTS),
        path: PAGE_PATHS.JOURNEY.FLIGHTS_PAGE,
      });
      tabsArray.push({
        eventName: JourneyTab.INSURANCE,
        key: JourneyTab.INSURANCE,
        label: t('common.insurance'),
        isActive: location.pathname.includes(JourneyTab.INSURANCE),
        path: PAGE_PATHS.JOURNEY.INSURANCE_PAGE,
        notification: journey.displayAirBundleActionNotification,
      });
    }

    return tabsArray;
  }, [locale, location]);

  const onTabClick = (tab: Tab) => {
    trackPageInteractions('clicked', tab.eventName);

    navigate(generatePath(tab.path, { id: journey.id.toString() }));
  };

  const isFitted = useBreakpointValue({ base: true, md: undefined });
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const journeyName = journey.generateDisplayName(t, i18n.language);

  const journeyBreadcrumb: BreadcrumbItem[] = [
    { id: 1, path: PAGE_PATHS.HOME_PAGE, label: t('navigation.home') },
    { id: 2, path: PAGE_PATHS.JOURNEYS_PAGE, label: t('navigation.trips') },
    { id: 3, label: journeyName },
  ];

  return (
    <>
      <Box width="100%">
        <Flex mb={8} alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            {isDesktop ? (
              <Link
                as={ReactLink}
                to={generatePath(PAGE_PATHS.JOURNEYS_PAGE)}
                _hover={{ textUnderline: 'none' }}
                _visited={{}}
              >
                <AltArrowBack
                  width={5}
                  height={5}
                  me={2}
                  color="greyscale.600"
                  transform={`rotate(${isRtl(locale.locale) ? '180deg' : '0'})`}
                  _hover={{ color: 'primary.600' }}
                />
              </Link>
            ) : null}
            <Heading variant="h4" data-testid="journey-name">
              {journeyName}
            </Heading>
          </Flex>
          {isDesktop ? <JourneyActions journey={journey} /> : null}
        </Flex>
        <Tabs
          mb={showInsuranceTab ? 4 : 0}
          isFitted={isFitted}
          index={tabs.findIndex((tab) => tab.isActive)}
        >
          <TabList border="none">
            {tabs.map((tab) => (
              <CustomTab
                key={`journey-details-tab-${tab.key}`}
                onClick={() => {
                  onTabClick(tab);
                }}
                dataTestId={`journey-details-tab-${tab.key}`}
              >
                {tab.label}
                {tab.notification ? <PulsatingNotificationDot /> : null}
              </CustomTab>
            ))}
          </TabList>
        </Tabs>
        <Outlet />
      </Box>
      <TopBarPortal>
        <GenerateBreadcrumbs breadcrumbData={journeyBreadcrumb} />
      </TopBarPortal>
    </>
  );
};

export default JourneyView;
