import { useState, useEffect, useCallback } from 'react';
import type { Dispatch, SetStateAction } from 'react';

function useLocalStorage<T>(
  key: string,
  defaultValue?: T,
): [T | null, Dispatch<SetStateAction<T | null>>] {
  const [value, setValue] = useState<T | null>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue
        ? (JSON.parse(storedValue) as T)
        : (defaultValue ?? null);
    } catch (error) {
      return defaultValue ?? null;
    }
  });

  const handleStorageChange = useCallback(
    (event: StorageEvent) => {
      if (event.key === key) {
        setValue(event.newValue ? (JSON.parse(event.newValue) as T) : null);
      }
    },
    [key],
  );

  const handleCustomChange = useCallback((event: Event) => {
    const customEvent = event as CustomEvent;
    setValue(customEvent.detail as T | null);
  }, []);

  useEffect(() => {
    // Sync localStorage and dispatch custom event when value changes
    if (value === null || value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }

    window.dispatchEvent(
      new CustomEvent(`local-storage-${key}-change`, { detail: value }),
    );

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener(`local-storage-${key}-change`, handleCustomChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener(
        `local-storage-${key}-change`,
        handleCustomChange,
      );
    };
  }, [value, key, handleStorageChange, handleCustomChange]);

  return [value, setValue];
}

export default useLocalStorage;
