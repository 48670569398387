import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { User } from '@airhelp/icons';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import PulsatingNotificationDot from 'elements/PulsatingNotificationDot';

const PulsatingNotification = () => (
  <Box
    width="8px"
    height="8px"
    borderRadius="50%"
    position="absolute"
    bottom="1px"
    right="1px"
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box position="relative">
      <PulsatingNotificationDot width="6px" height="6px" />
    </Box>
  </Box>
);

interface UserAvatarProps {
  withNotification?: boolean;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ withNotification }) => {
  const { userProfile } = useMyProfile();
  const avatarContent = userProfile?.firstName ? (
    <Text
      color="primary.600"
      fontSize="sm"
      fontWeight="500"
      data-testid="user-first-name"
    >
      {userProfile.firstName?.charAt(0).toLocaleUpperCase()}
    </Text>
  ) : (
    <User width={4} height={4} color="primary.600" data-testid="user-avatar" />
  );

  return (
    <Box position="relative">
      <Flex
        width={8}
        height={8}
        justifyContent="center"
        alignItems="center"
        backgroundColor="primary.100"
        borderRadius="full"
      >
        {avatarContent}
      </Flex>
      {withNotification ? <PulsatingNotification /> : null}
    </Box>
  );
};

export default UserAvatar;
