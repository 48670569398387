import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box, Text } from '@chakra-ui/react';
import { camelCase, keyBy } from 'lodash';
import {
  type BankFieldsList,
  InsuranceType,
  type JourneyWithMeta,
} from '@airhelp/plus';
import {
  airPayoutPaymentReducer,
  AirPayoutPaymentTypes,
  airPayoutInitialState,
} from 'reducers/airPayoutPaymentReducer/airPayoutPaymentReducer';
import { sentryUtils } from 'utils';
import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import PayoutForm from 'components/Journey/AirBundle/Payment/PayoutForm';
import { setVeriffUrl } from 'utils/veriff';
import { useTrackEvent } from 'utils/tracking/hooks';
import { anyDetailsFormInvalid } from 'utils/airBundle/airBundle';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import { type Journey } from 'models/journey/Journey';
import useTriggerAirPayoutJourneys from 'hooks/api/airBundle/useTriggerAirPayoutJourneys';
import useUploadAirBundleDocument from 'hooks/api/airBundle/useUploadAirBundleDocument';
import { ErrorBadge } from 'elements';
import ContactLink from 'elements/ContactLink/ContactLink';
import DocumentsDetails from './DocumentsDetails';
import { airPayoutDocumentMappings } from './airPayoutDocumentsConfig';

const insuranceType = InsuranceType.AIR_PAYOUT;

interface IComponent {
  journeyListData: AirPayoutJourney[];
  onTriggerSuccessful: (airRequestId?: number) => void;
  journeyData: Journey;
}

const PayoutPaymentView: React.FC<IComponent> = ({
  journeyListData,
  onTriggerSuccessful,
  journeyData,
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const triggerPayout = useTriggerAirPayoutJourneys();
  const uploadDocument = useUploadAirBundleDocument();
  const { id: journeyId } = journeyData;

  const form = useForm({ mode: 'onSubmit' });
  const selectedCountry = form.getValues('country')?.value;

  const [reducerData, dispatch] = useReducer(
    airPayoutPaymentReducer,
    airPayoutInitialState,
  );

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [airRequestId, setAirRequestId] = useState<number | undefined>();
  const [bankDetailsCollected, setBankDetailsCollected] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDocumentsFinished, setUploadDocumentsFinished] =
    useState<BankFieldsList | null>(null);
  const [isValidDataDetailsForm, setIsValidDataDetailsForm] = useState({});

  const setFormsValidity = (id, s) => {
    setIsValidDataDetailsForm({ ...isValidDataDetailsForm, [id]: s });
  };

  const isMultiPax = journeyListData.length > 1;
  const beneficiaryFullName = journeyListData[0]?.name;

  const reducerDataById = keyBy(reducerData.airPayoutJourneys, 'id');

  const availablePayoutRequestedPax = journeyListData.filter(
    (pax) => pax.payoutAllowed,
  ).length;

  useEffect(() => {
    if (airRequestId && !submitError) {
      onTriggerSuccessful(airRequestId);
    }
  }, [airRequestId, submitError]);

  const handleError = (error: unknown) => {
    setSubmitError(true);
    setUploadInProgress(false);
    sentryUtils.captureException(error);
  };

  const isDisabled = !bankDetailsCollected
    ? false
    : reducerData.airPayoutJourneys.length !== availablePayoutRequestedPax ||
      anyDetailsFormInvalid(isValidDataDetailsForm);

  const resetReducer = () => {
    dispatch({
      type: AirPayoutPaymentTypes.RESET,
    });
  };

  const savePayout = (data: BankFieldsList) => {
    const params = {
      countryId: data.country?.value || '',
      journeyId,
      bankFields: { ...data, countryOfResidence: data?.residence?.value },
      airPayoutJourneys: reducerData.airPayoutJourneys,
    };

    triggerPayout.mutate(params, {
      onError: (error) => {
        handleError(error);
      },
      onSuccess: (response: JourneyWithMeta) => {
        if (response.meta?.airRequestId) {
          const responseAirRequestId = response.meta.airRequestId;
          setVeriffUrl(
            journeyData.id,
            response.meta.sessionUrl,
            insuranceType,
            responseAirRequestId,
          );
          setAirRequestId(airRequestId);
        } else {
          setAirRequestId(-1);
        }
        setUploadInProgress(false);
        trackPageInteractions('airPayout paid', 'ahp airPayout page');
      },
    });
  };

  const onSubmit = (data: BankFieldsList) => {
    setSubmitError(false);
    setUploadInProgress(true);

    const promises = reducerData.airPayoutJourneys.map((pax) => {
      const resourceId = journeyId;
      const { id } = pax;

      const uploadPromises = airPayoutDocumentMappings
        .filter(({ fileKey }) => pax[fileKey])
        .map(({ fileKey, documentType }) => {
          return uploadDocument
            .mutateAsync({
              file: pax[fileKey],
              insuranceType,
              documentType,
              insuranceId: id,
              resourceId,
            })
            .then((result) => ({
              documentType,
              result,
            }));
        });

      return Promise.all(uploadPromises).then((results) => {
        const response = { id };
        results.forEach(({ documentType, result }) => {
          const camelCaseKey = camelCase(documentType);
          response[camelCaseKey] = result;
        });
        return response;
      });
    });

    Promise.all(promises)
      .then((results) => {
        results.forEach((result) => {
          dispatch({
            type: AirPayoutPaymentTypes.EDIT_PASSENGER,
            payload: result,
          });
        });

        setUploadDocumentsFinished(data);
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (uploadDocumentsFinished) {
      savePayout({ ...uploadDocumentsFinished });
    }
  }, [uploadDocumentsFinished]);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-bundle-payout-title"
        >
          {bankDetailsCollected
            ? t('common.documents')
            : t('instant_cash.payout.bank_details')}
        </Text>

        {bankDetailsCollected ? (
          journeyListData.map((airPayoutJourney) => (
            <DocumentsDetails
              key={airPayoutJourney.id}
              airPayoutJourney={airPayoutJourney}
              dispatch={dispatch}
              isMultiPax={isMultiPax}
              reducerDataById={reducerDataById}
              setFormsValidity={setFormsValidity}
              selectedCountry={selectedCountry}
            />
          ))
        ) : (
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            uploadInProgress={uploadInProgress}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
            insuranceType={insuranceType}
          />
        )}
      </Box>
      {isMultiPax && bankDetailsCollected ? (
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" display="inline-flex" mb={1} />}
          variant="secondary"
          mt={{ base: 4, md: 5 }}
        >
          {t('common.fill_out_application_separately')}
        </InfoBox>
      ) : null}
      {submitError ? (
        <ErrorBadge data-testid="backend-error">
          <ContactLink tKey="instant_cash.payout.request_payment_error" />
        </ErrorBadge>
      ) : null}
      <AirBundlePayoutActions
        form={form}
        uploadInProgress={uploadInProgress}
        bankDetailsCollected={bankDetailsCollected}
        setBankDetailsCollected={setBankDetailsCollected}
        onSubmit={onSubmit}
        submitError={submitError}
        isDisabled={isDisabled}
        insuranceType={insuranceType}
        resetReducer={resetReducer}
      />
    </>
  );
};

export default PayoutPaymentView;
