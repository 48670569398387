import React, { useEffect } from 'react';
import { generatePath, useParams, Navigate } from 'react-router';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { useClaimDetails } from '../../hooks/useClaimDetails';
import { useDocumentRequestRedirectLink } from '../../components/Claim/Documents/useDocumentRequestRedirectLink';

const ClaimDocumentsRedirectPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { isEnabled, isLoading } = useClaimDetails();

  const { id } = useParams();
  const redirect = useDocumentRequestRedirectLink({ claimId: Number(id) });

  useEffect(() => {
    if (isEnabled && !redirect.isLoading) {
      window.location.replace(redirect.link);
    }
  }, [isEnabled, redirect.isLoading, redirect.link]);

  if (isLoading || redirect.isLoading) {
    return <Loader dataTestId="loader" />;
  }

  if (!isEnabled) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }
};

export default track({ page_type: 'Claim.DocumentsRedirect' })(
  ClaimDocumentsRedirectPage,
);
