import React from 'react';
import { Trans } from 'react-i18next';
import { Heading, Text, VStack } from '@chakra-ui/react';
import { Card } from 'elements';
import { type LoungePass } from 'models/LoungePass';
import PassDetails from './PassDetails';

interface IComponent {
  loungePasses: LoungePass[];
}

const PassesList: React.FC<IComponent> = ({ loungePasses }) => {
  return (
    <Card
      maxWidth={{ base: '100%', lg: '785px' }}
      px={{ base: 4, md: 6, xl: 8 }}
      py={{ base: 5, md: 6, xl: 8 }}
      rowGap={{ base: 4, md: 8 }}
      data-testid="lounge-passes-container"
    >
      <Heading variant="h5">
        <Trans
          i18nKey="lounges.your_available_lounge_passes"
          values={{ count: loungePasses.length }}
          components={{
            strong: (
              <Text
                as="span"
                fontSize="inherit"
                fontWeight="bold"
                color="danger.500"
                display="inline-block"
              />
            ),
          }}
        />
      </Heading>
      <VStack width="100%" spacing={3}>
        {loungePasses.map((loungePass, key) => (
          <PassDetails
            loungePass={loungePass}
            ordinalNumber={key + 1}
            key={`lounge-pass-${loungePass.passCode}}-${loungePass.validUntil}`}
          />
        ))}
      </VStack>
    </Card>
  );
};

export default PassesList;
