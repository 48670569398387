import React, { useState } from 'react';
import { type AirBundleSubscription } from 'models/userProfile/AirBundleSubscription';
import { type Journey } from 'models/journey/Journey';
import AirBundleAppliedModal from '../AirBundleAppliedModal';
import AirBundleRejectedModal from '../AirBundleRejectedModal';

interface IComponent {
  journey: Journey;
  airBundleSubscriptionData: AirBundleSubscription;
}

const AirBundleModal: React.FC<IComponent> = ({
  journey,
  airBundleSubscriptionData,
}) => {
  const [showAirBundleAppliedModal, setShowAirBundleAppliedModal] =
    useState(false);

  const airPayoutProtected = journey.protections.airPayout.protected;
  const airLuggageProtected = journey.protections.airLuggage.protected;

  const isAirBundleApplied =
    airPayoutProtected || airLuggageProtected || showAirBundleAppliedModal;
  const showAirBundleRejected =
    !showAirBundleAppliedModal &&
    (journey.airLuggageRejected || journey.airPayoutRejected);

  if (showAirBundleRejected) {
    return (
      <AirBundleRejectedModal
        journey={journey}
        airBundleSubscriptionData={airBundleSubscriptionData}
        setShowAirBundleAppliedModal={setShowAirBundleAppliedModal}
      />
    );
  }

  if (isAirBundleApplied) {
    return (
      <AirBundleAppliedModal
        airBundleSubscriptionData={airBundleSubscriptionData}
        journey={journey}
      />
    );
  }

  return null;
};

export default AirBundleModal;
