import { useLocation } from 'react-router';
import { PAGE_PATHS } from 'config/routes';

// ex: const config = new Set<string>([PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE]);
const config = new Set<string>([PAGE_PATHS.MY_ACCOUNT.PLAN_MANAGEMENT_PAGE]);

function useHideSideNav() {
  const location = useLocation();
  return config.has(location.pathname);
}

export default useHideSideNav;
