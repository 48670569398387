import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';
import { planBenefits } from 'components/MyAccountView/PlanManagementView/plan-management-config';

const PlanDetailsLabels: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flex={{ base: 'none', md: 'inherit' }}
      width={{ base: '165px', md: '190px' }}
      px={{ base: 4, md: 4 }}
      mb={{ base: 0, md: 6 }}
      alignItems="flex-end"
      justifyContent="flex-end"
      flexDirection="column"
    >
      {planBenefits.map((item) => (
        <Flex
          width="100%"
          color="primary.900"
          fontSize="sm"
          lineHeight="normal"
          justifyContent="flex-start"
          alignItems="center"
          height="62px"
          overflow="hidden"
          key={item.key}
          data-testid={`plan-detail-label-${item.key}`}
        >
          {item.label || (item.label_tkey && t(item.label_tkey))}
        </Flex>
      ))}
    </Flex>
  );
};

export default PlanDetailsLabels;
