import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useSearchParams } from 'react-router';
import { track } from 'react-tracking';
import PlanManagementView from 'components/MyAccountView/PlanManagementView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castStringParam } from 'utils/params';

const PlanManagementPage: React.FC = () => {
  usePageTracking();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('my_account.my_benefits.plans_compared.pick_your_plan'),
    backPath: generatePath(PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE),
  });

  const [searchParams] = useSearchParams();

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data: offersData,
  } = useComputedOffers({
    params: {
      renew: false,
      upgrade: true,
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  const {
    isLoading: isLoadingUserProfile,
    isError: isErrorProfile,
    data: userProfile,
  } = useFetchMyProfile();

  if (isLoadingUserProfile || isLoadingOffers) {
    return <Loader dataTestId="loader" />;
  }

  const offers = offersData?.offers;
  const upgradeOffers = offersData?.upgradeOffers;

  if (isErrorProfile || isErrorOffers || !offers || !userProfile) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  return (
    <PlanManagementView
      userProfile={userProfile}
      offers={offers}
      upgradeOffers={upgradeOffers}
    />
  );
};

export default track({ page_type: 'MyAccount.PlanManagement' })(
  PlanManagementPage,
);
