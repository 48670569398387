import React from 'react';
import { generatePath } from 'react-router';
import { buildSteps, step } from '@airhelp/funnel';
import { ReactRouterFunnel, useFunnelInit } from '@airhelp/funnel/react-router';
import { ContentSpinner } from '@airhelp/react';
import { PAGE_PATHS } from 'config/routes';
import { NotFoundPage } from 'pages';
import {
  AnnualPage,
  CheckoutPage,
  CompletePage,
  PaymentSucceededPage,
  ProcessingPaymentPage,
} from 'pages/Trips/Upgrade';

export interface ComponentPageProps {
  defaultActiveStep: number;
  token: string;
}

export const useInitTripsFunnel = (
  props: Omit<ComponentPageProps, 'defaultActiveStep'>,
) => {
  return useFunnelInit(() => {
    const { token } = props;

    const basePath = generatePath(PAGE_PATHS.TRIPS.UPGRADE_PAGE, {
      token: token?.toString() || '',
    });

    return {
      basePath,
      steps: buildSteps([
        step({
          name: 'annual',
          uri: '/annual',
          Component: () => <AnnualPage />,
        }),
        step({
          name: 'complete',
          uri: '/complete',
          Component: () => <CompletePage />,
        }),
        step({
          name: 'checkout',
          uri: '/checkout',
          Component: () => <CheckoutPage />,
        }),
        step({
          name: 'processing-payment',
          uri: '/processing-payment',
          Component: () => <ProcessingPaymentPage />,
        }),
        step({
          name: 'payment-succeeded',
          uri: '/payment-succeeded',
          Component: () => <PaymentSucceededPage />,
        }),
      ]),
    };
  });
};

export const TripsFunnel = (
  props: Omit<ComponentPageProps, 'defaultActiveStep'>,
) => {
  const definition = useInitTripsFunnel(props);
  return (
    <ReactRouterFunnel
      definition={definition}
      loadingIndicator={<ContentSpinner />}
      notFoundStep={<NotFoundPage />}
    />
  );
};
