import * as ClaimsPages from 'pages/Claims';
import { Redirect } from 'utils/Redirect';
import { PAGE_PATHS } from 'config/routes';
import { Layout } from '../components/Claim/Layout';

export const ClaimsRoutes = [
  {
    path: 'claims',
    element: <ClaimsPages.IndexPage />,
  },
  {
    path: 'claims/:id',
    element: <Layout />,
    errorElement: <Redirect to={PAGE_PATHS.CLAIMS_PAGE} />,
    children: [
      {
        path: '',
        element: <ClaimsPages.ClaimProgressPage />,
      },
      {
        path: 'compensation',
        element: <ClaimsPages.ClaimCompensationPage />,
      },
      {
        path: 'documents',
        element: <ClaimsPages.ClaimDocumentsPage />,
      },
      {
        path: 'documents-redirect',
        element: <ClaimsPages.ClaimDocumentsRedirectPage />,
      },
      {
        path: 'details',
        element: <ClaimsPages.ClaimDetailsPage />,
      },
      {
        path: 'history',
        element: <ClaimsPages.ClaimHistoryPage />,
      },
    ],
  },
];
