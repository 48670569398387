import React from 'react';
import { generatePath, Navigate, useSearchParams } from 'react-router';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

const IndexPage = () => {
  const { userProfile } = useMyProfile();
  const [searchParams] = useSearchParams();

  if (!userProfile) {
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.EMAIL_PAGE),
          search: searchParams.toString(),
        }}
        state={{ from: PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE }}
      />
    );
  }

  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
        search: searchParams.toString(),
      }}
    />
  );
};

export default IndexPage;
