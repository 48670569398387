import { Box, Flex } from '@chakra-ui/react';
import React, { useRef, type PropsWithChildren } from 'react';
import appTheme from 'components/ChakraProvider/theme';
import useLocalStorage from 'hooks/useLocalStorage';
import useHideSideNav from 'hooks/routing/useHideSideNav';
import TopNav from './TopNav';
import NavBar from './SideNav/NavBar';
import { SideNavProvider } from './SideNav/SideNavProvider';
import { TopNavProvider } from './TopNav/TopNavProvider';
import TopBanners from './TopBanners';

const BaseLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const [layoutCollapse] = useLocalStorage('layout-collapsed');
  const navbarPortalRef = useRef(null);
  const topPortalRef = useRef(null);

  const hideSideNav = useHideSideNav();

  const getMarginLeft = () => {
    if (hideSideNav) {
      return 0;
    }
    return { base: 0, md: '88px', lg: layoutCollapse ? '88px' : '278px' };
  };

  return (
    <SideNavProvider value={{ portalRef: navbarPortalRef }}>
      <TopNavProvider value={{ portalRef: topPortalRef }}>
        <Box position="sticky" top="0" insetStart="0" right="0" zIndex="11">
          <TopBanners />
          <TopNav />
        </Box>
        <Flex
          marginLeft={getMarginLeft()}
          justifyContent="center"
          transition="margin 300ms ease-in-out"
        >
          <Flex
            width="100%"
            maxW={{
              base: '100%',
              xlg: appTheme.breakpoints.xlg,
            }}
            p={{ base: 4, md: 8 }}
            marginBottom={{ base: '78px', md: 0 }}
            overflow="hidden"
          >
            {children}
          </Flex>
          {hideSideNav ? null : <NavBar />}
        </Flex>
      </TopNavProvider>
    </SideNavProvider>
  );
};

export default BaseLayout;
