import React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from '@chakra-ui/react';
import { type ClaimEnquiry } from 'models/Claim';
import flightsChecked from 'assets/images/flights-checked-formal@2.png';
import supportFormal from 'assets/images/support-formal@2x.png';
import { useZowie } from 'contexts/ZowieContext';
import ActionCard from 'elements/ActionCard';
import { useTrackEvent } from 'utils/tracking/hooks';
import { LinkTarget } from 'types/utils';
import { getWebappNewClaimUrl } from 'utils/sites';
import { useSideNav } from 'components/Layouts/BaseLayout/SideNav/SideNavProvider';
import { ClaimReferralCard } from 'components/Claim/Layout';
import ClaimsList from './ClaimsList';

interface IComponent {
  claimEnquiries: ClaimEnquiry[];
}

const ClaimsView: React.FC<IComponent> = ({ claimEnquiries }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { openZowieChat } = useZowie();

  const sideNav = useSideNav();
  const NavigationBarPortal = sideNav.NavigationBarPortal;

  const onAddClaimClick = () => {
    trackCtaClick('add new claim', 'claims page');
  };

  const onSupportClick = () => {
    openZowieChat();
    trackCtaClick('need help', 'claims page');
  };

  return (
    <>
      <VStack spacing={12} width="100%">
        <HStack width="100%" spacing={4} alignItems="stretch">
          <ActionCard
            header={t('dashboard.how_can_we_help.add_claim')}
            description={t('claims.submit_claim_desc')}
            handleClick={onAddClaimClick}
            imageSrc={flightsChecked}
            externalLink={getWebappNewClaimUrl()}
            externalLinkTarget={LinkTarget.SELF}
            data-testid="action-card-add-claim"
          />
          <ActionCard
            header={t('dashboard.how_can_we_help.chat_with_us')}
            description={t('dashboard.our_team_for_you')}
            handleClick={onSupportClick}
            imageSrc={supportFormal}
            data-testid="action-card-support"
          />
        </HStack>
        <ClaimsList claimEnquiries={claimEnquiries} />
      </VStack>
      <NavigationBarPortal>
        <ClaimReferralCard />
      </NavigationBarPortal>
    </>
  );
};

export default ClaimsView;
