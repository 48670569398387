import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Input } from '@airhelp/react';
import { Info, Ok } from '@airhelp/icons';
import {
  Box,
  Button,
  Flex,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { type BillingManagementAddress } from '@airhelp/plus';
import { isEqual } from 'lodash';
import { addressFieldMaxLength, fullNamePattern } from 'utils/validations';
import {
  getCountriesSelectOptions,
  getCountryNames,
} from 'utils/i18n/countries';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';
import useUpdateBillingAddress from 'hooks/api/billingManagement/useUpdateBillingAddress';
import { type Option } from 'types/select';

interface IComponent {
  onClose: () => void;
  billingAddress: BillingManagementAddress;
}

const UpdateBillingInformationForm: React.FC<IComponent> = ({
  onClose,
  billingAddress,
}) => {
  const { t, i18n } = useTranslation();
  const { trackCtaClick, trackPageInteractions } = useTrackEvent();
  const locale = useLocale();
  const toast = useToast();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const form = useForm({ mode: 'onChange' });
  const { control, formState, handleSubmit } = form;
  const { errors, dirtyFields } = formState;

  const noChanges = isEqual(dirtyFields, {});

  const {
    name,
    countryCode,
    addressLine1,
    addressLine2,
    state,
    postalCode,
    city,
  } = billingAddress;

  const countriesOptions = getCountriesSelectOptions(i18n.language);

  const countries = getCountryNames(locale.locale);

  const updateBillingAddress = useUpdateBillingAddress();
  const { isError, isPending: isLoading } = updateBillingAddress;

  const handleCountrySelect = (
    item: Option,
    onChange: (item: Option) => void,
  ) => {
    onChange(item);
  };

  const FULL_NAME_MAX_LENGTH = 128;
  const formHasErrors = Object.keys(errors).length > 0;

  const handleUpdateAddress = (data) => {
    trackCtaClick('update billing address', 'ahp payment management');
    onSubmit(data);
  };

  const onSubmit = (data) => {
    const payload = {
      name: data.fullName,
      address: {
        line1: data.streetAddress1,
        line2: data.streetAddress2 || null,
        city: data.city,
        country: data.country.value,
        postal_code: data.postalCode || null,
        state: data.state || null,
      },
    };

    updateBillingAddress.mutate(
      { payload },
      {
        onSuccess: () => {
          trackPageInteractions(
            'billing address updated',
            'ahp payment management',
          );
          toast({
            title: (
              <span data-testid="billing-address-updated">
                {t('payment_management.address_updated')}
              </span>
            ),
            variant: 'success',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
          onClose();
        },
        onError: () => {
          trackPageInteractions(
            'billing address error',
            'ahp payment management',
          );
          toast({
            title: (
              <span data-testid="billing-address-no-updated">
                {t('payment_management.address_not_updated')}
              </span>
            ),
            variant: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
          });
        },
      },
    );
  };
  return (
    <Flex
      onSubmit={handleSubmit(handleUpdateAddress)}
      justifyContent="space-between"
      flexDirection="column"
      height="calc(100% - 56px)"
      as="form"
    >
      <Box>
        <Controller
          name="fullName"
          control={control}
          rules={{
            required: { value: true, message: t('errors.required') },
            pattern: {
              value: fullNamePattern,
              message: t('errors.please_add_last_name'),
            },
            maxLength: {
              value: FULL_NAME_MAX_LENGTH,
              message: t('errors.too_long', {
                attribute: t('user.full_name'),
                max: FULL_NAME_MAX_LENGTH,
              }),
            },
          }}
          defaultValue={name || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={t('user.full_name')}
                value={value}
                placeholder={t('user.full_name')}
                onChange={onChange}
                maxLength={FULL_NAME_MAX_LENGTH}
                data-testid="full-name"
              />
            </Box>
          )}
        />
        <Controller
          name="country"
          control={control}
          rules={{ required: { value: true, message: t('errors.required') } }}
          defaultValue={
            countryCode
              ? { label: countries[countryCode], value: countryCode }
              : ''
          }
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Autocomplete
                name={fieldName}
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                label={t('common.country')}
                value={value}
                options={countriesOptions}
                placeholder={t('common.country')}
                isSearchable
                handleChange={(val) => {
                  handleCountrySelect(val as Option, onChange);
                }}
                dataTestId="country"
              />
            </Box>
          )}
        />
        <Controller
          name="streetAddress1"
          control={control}
          rules={{
            required: { value: true, message: t('errors.required') },
            pattern: { value: /^\S+/, message: t('errors.required') },
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.street_address_line'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue={addressLine1 || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={t('form.street_address_line', { number: 1 })}
                value={value}
                placeholder={t('form.street_address_line', { number: 1 })}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="street-address-1"
              />
            </Box>
          )}
        />
        <Controller
          name="streetAddress2"
          control={control}
          rules={{
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.street_address_line', { number: 2 }),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue={addressLine2 || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={`${t('form.street_address_line', { number: 2 })} ${t('optional')}`}
                value={value}
                placeholder={t('form.street_address_line', { number: 2 })}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="street-address-2"
              />
            </Box>
          )}
        />
        <Controller
          name="postalCode"
          control={control}
          rules={{
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.zip_or_postal_code'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue={postalCode || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={`${t('form.zip_or_postal_code')} ${t('optional')}`}
                value={value}
                placeholder={t('form.zip_or_postal_code')}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="zip-code"
              />
            </Box>
          )}
        />
        <Controller
          name="state"
          control={control}
          rules={{
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.state_or_province'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue={state || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={`${t('form.state_or_province')} ${t('optional')}`}
                value={value}
                placeholder={t('form.state_or_province')}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="state"
              />
            </Box>
          )}
        />
        <Controller
          name="city"
          control={control}
          rules={{
            required: { value: true, message: t('errors.required') },
            maxLength: {
              value: addressFieldMaxLength,
              message: t('errors.too_long', {
                attribute: t('form.city'),
                max: addressFieldMaxLength,
              }),
            },
          }}
          defaultValue={city || ''}
          render={({
            field: { onChange, name: fieldName, value },
            fieldState: { invalid, error },
          }) => (
            <Box width="100%" minHeight="106px">
              <Input
                isInvalid={invalid}
                errorMessage={error?.message || ''}
                name={fieldName}
                label={t('form.city')}
                value={value}
                placeholder={t('form.city')}
                onChange={onChange}
                maxLength={addressFieldMaxLength}
                data-testid="city"
              />
            </Box>
          )}
        />
      </Box>
      <Button
        onClick={handleSubmit(handleUpdateAddress)}
        width="100%"
        fontWeight={500}
        mt={{ base: 4, md: 8 }}
        isLoading={isLoading}
        isDisabled={formHasErrors || isLoading || isError || noChanges}
        data-testid="btn-update-billing-address-action"
      >
        {t('payment_management.update_billing_address')}
      </Button>
    </Flex>
  );
};
export default UpdateBillingInformationForm;
