import React from 'react';
import { AltArrowForward } from '@airhelp/icons';
import { HStack, Text } from '@chakra-ui/react';
import { generatePath, Link } from 'react-router';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';

export interface BreadcrumbItem {
  label: string;
  path?: string;
  id: number;
}

const GenerateBreadcrumbs: React.FC<{ breadcrumbData: BreadcrumbItem[] }> = ({
  breadcrumbData,
}) => {
  const { locale } = useLocale();

  return (
    <HStack spacing={1}>
      {breadcrumbData.map((item, index) => (
        <HStack key={item.id} spacing={1}>
          {item.path ? (
            <Text color="primary.500" fontSize="sm">
              <Link to={generatePath(item.path)}>{item.label}</Link>
            </Text>
          ) : (
            <Text color="greyscale.600" fontSize="sm">
              {item.label}
            </Text>
          )}
          {index < breadcrumbData.length - 1 && (
            <AltArrowForward
              boxSize={5}
              color="greyscale.500"
              transform={isRtl(locale) ? 'rotate(180deg)' : undefined}
            />
          )}
        </HStack>
      ))}
    </HStack>
  );
};

export default GenerateBreadcrumbs;
