import React, { useState } from 'react';
import { generatePath, Navigate, useLocation } from 'react-router';
import { track } from 'react-tracking';
import { Center } from '@chakra-ui/react';
import LoginView from 'components/LoginView';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';

type LoginLocationState = Record<string, string>;

const LoginPage: React.FC = () => {
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();
  const location = useLocation();
  const locationState = location.state as LoginLocationState;

  // Leszek Kmiec - 2024-10-01
  // we need to use the `from` state from the location to support redirects
  // eslint-disable-next-line react/hook-use-state -- We need to use useState to get the 'from' state from the location for redirects
  const [from] = useState<string>(
    locationState?.from
      ? locationState?.from
      : generatePath(PAGE_PATHS.HOME_PAGE),
  );

  usePageTracking();

  if (isUserProfileLoading) {
    return (
      <Center>
        <Loader dataTestId="loader" />
      </Center>
    );
  }

  if (!userProfile) {
    return <LoginView />;
  }

  return <Navigate to={{ pathname: from }} />;
};

export default track({ page_type: 'Login' })(LoginPage);
