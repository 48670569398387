import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router';
import { ID as Id, AltArrowForward } from '@airhelp/icons';
import { Button, Flex, HStack, VStack, Text } from '@chakra-ui/react';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import type { UserProfile } from 'models/userProfile/UserProfile';
import { useTrackEvent } from 'utils/tracking/hooks';
import { isRtl } from 'utils/textDirection/isRtl';
import { PAGE_PATHS } from 'config/routes';

const NoPlanInfo: React.FC<{
  userProfile: UserProfile;
}> = ({ userProfile }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { locale } = useLocale();

  const policy = userProfile.policy;
  const upsellEnabled = policy.dashboard?.upsellEnabled;

  return (
    <Flex
      flexDirection={{ base: 'column', lg: 'row' }}
      bg="greyscale.100"
      p={{ base: 4, lg: 6 }}
      borderRadius="lg"
      gap={{ base: 4, lg: 8 }}
      justifyContent="space-between"
    >
      <VStack
        w={{ base: '100%', lg: '33%' }}
        alignItems="flex-start"
        justifyContent="center"
        spacing={0}
      >
        <Text fontWeight="500" fontSize="lg">
          {t('common.your_plan')}
        </Text>
      </VStack>
      <Flex
        w={{ base: '100%', lg: '67%' }}
        alignContent="center"
        justifyContent="space-between"
        flexDirection={{ base: 'column', lg: 'row' }}
        rowGap={4}
      >
        <HStack>
          <Id
            boxSize={9}
            p={2}
            bg="primary.100"
            color="primary.500"
            borderRadius="md"
            me={3}
          />
          <Text color="greyscale.600" fontSize="md">
            {t('my_account.my_benefits.your_plan.currently_no_plan')}
          </Text>
        </HStack>
        {upsellEnabled ? (
          <Button
            width={{ base: '100%', lg: 'auto' }}
            as={ReactLink}
            size="s"
            onClick={() => {
              trackCtaClick('check our plans', 'my benefits page');
            }}
            to={{
              pathname: generatePath(
                PAGE_PATHS.MY_ACCOUNT.PLAN_MANAGEMENT_PAGE,
              ),
            }}
            rightIcon={
              <AltArrowForward
                transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
              />
            }
            data-testid="btn-check-our-plans"
          >
            {t('common.check_our_plans')}
          </Button>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default NoPlanInfo;
