import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Info, Ok } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { postPersonalDetailsProvided } from '@airhelp/plus';
import { useAhPlusHTTPClient } from '@airhelp/plus/react';
import {
  Button,
  Flex,
  Link,
  Text,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { isEqual } from 'lodash';
import { useUpdateUserProfile } from '@airhelp/webapp/react';
import { type UpdateUserProfilePayload } from '@airhelp/webapp';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { ErrorBadge } from 'elements';
import { useTrackEvent } from 'utils/tracking/hooks';
import { getWebsitePageUrl } from 'utils/sites';
import { type UserProfile } from 'models/userProfile/UserProfile';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import FormInputs from './FormInputs';

interface IComponent {
  actionLabel?: string;
  onDataSaved?: () => void;
  onSubmit?: () => void;
  responsive?: boolean;
  userProfile: UserProfile;
}
const MyAccountForm: React.FC<IComponent> = ({
  actionLabel,
  onDataSaved,
  onSubmit: onSubmitCallback,
  responsive,
  userProfile,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();
  const { trackCtaClick } = useTrackEvent();
  const ahPlusClient = useAhPlusHTTPClient();
  const { reloadProfile } = useMyProfile();

  const {
    mutate: handleUpdateUserProfile,
    isPending: isLoading,
    isError,
  } = useUpdateUserProfile();

  const { handleSubmit, control, formState, reset, getValues, trigger } =
    useForm<UpdateUserProfilePayload>({
      mode: 'onChange',
    });
  const { isValid, errors, dirtyFields, isSubmitSuccessful } = formState;
  const noChanges = isEqual(dirtyFields, {});

  const isDisabled = !isValid || isError || (responsive && noChanges);

  const termsUrl = getWebsitePageUrl('termsPath', locale);
  const privacyUrl = getWebsitePageUrl('privacyPath', locale);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful]);

  const onSubmit = (data: UpdateUserProfilePayload) => {
    onSubmitCallback ? onSubmitCallback() : null;

    handleUpdateUserProfile(
      { ...data },
      {
        onError: () => {
          toast({
            title: <span>{t('errors.something_went_wrong')}</span>,
            variant: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
          });
        },
        onSuccess: async () => {
          trackCtaClick('update my details', 'my account page');

          await postPersonalDetailsProvided({ client: ahPlusClient });
          await reloadProfile();

          toast({
            title: t('my_account.details.your_details_updated'),
            variant: 'success',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
          onDataSaved && onDataSaved();
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormInputs
        control={control}
        errors={errors}
        userProfile={userProfile}
        responsive={responsive}
        trigger={trigger}
      />
      <InfoBox
        isChat
        icon={<Info width={5} height="auto" color="primary.500" me={2} />}
      >
        <Text fontSize="md">
          {t('my_account.details.phone_number_for_lounges_and_claim_only')}
        </Text>
      </InfoBox>
      {isError ? (
        <Flex justifyContent="center" mt={-2} mb={6}>
          <ErrorBadge data-testid="error-text" />
        </Flex>
      ) : null}
      <Flex
        width="100%"
        mt={6}
        flexDirection={
          responsive ? { base: 'column', md: 'row-reverse' } : 'column'
        }
        gap={6}
        alignItems={
          responsive ? { base: 'flex-start', md: 'center' } : 'center'
        }
        justifyContent="space-between"
      >
        <Button
          size="m"
          textAlign="center"
          onClick={handleSubmit(onSubmit)}
          isDisabled={isDisabled}
          isLoading={isLoading}
          width={responsive ? { base: '100%', md: ' auto' } : '100%'}
          data-testid="update-personal-data-button"
          whiteSpace="nowrap"
          flexShrink={0}
        >
          {actionLabel}
        </Button>
        <Flex>
          <Text fontSize="sm" textAlign={responsive ? 'left' : 'center'}>
            <Trans
              i18nKey="common.check_tc_and_privacy_statement"
              values={{ privacyUrl, termsUrl }}
              components={{
                a: (
                  <Link
                    isExternal
                    fontSize="sm"
                    fontWeight="normal"
                    _visited={{}}
                  />
                ),
              }}
            />
          </Text>
        </Flex>
      </Flex>
    </form>
  );
};

export default MyAccountForm;
