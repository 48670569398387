import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import {
  Center,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack,
  Text,
  Button,
  ListItem,
  UnorderedList,
  Flex,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import messageFormal from 'assets/images/air-bundle/message-formal.png';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type TranslationKey } from 'types/i18n';
import clearLocationHistory from 'utils/clearLocationHistory';
import { airLuggagePolicy, airPayoutPolicy } from 'utils/insurancePolicy';
import { type AirBundleSubscription } from 'models/userProfile/AirBundleSubscription';
import { type Journey } from 'models/journey/Journey';

interface IComponent {
  journey: Journey;
  airBundleSubscriptionData: AirBundleSubscription;
  setShowAirBundleAppliedModal: (showAirBundleAppliedModal: boolean) => void;
}

const AirBundleRejectedModal: React.FC<IComponent> = ({
  journey,
  airBundleSubscriptionData,
  setShowAirBundleAppliedModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const { userProfile } = useMyProfile();

  const airPayoutProtected = journey.protections.airPayout.protected;
  const airLuggageProtected = journey.protections.airLuggage.protected;

  const {
    airPayoutCreditsLeft,
    airPayoutUnlimitedAccess,
    hasCredit,
    airPayoutIpidVersion,
    airLuggageIpidVersion,
  } = airBundleSubscriptionData;

  const required24hDepartureTime =
    userProfile?.policy.benefits?.required24hDepartureTime;
  const departureThresholdHours = userProfile?.policy.benefits
    ? userProfile.policy.benefits.departureThresholdHours
    : 48;

  const handleClose = () => {
    clearLocationHistory(navigate);
    onClose();
  };

  const handleContinue = () => {
    if (airPayoutProtected || airLuggageProtected) {
      setShowAirBundleAppliedModal(true);
    }
    onClose();
  };

  const getTitle = () => {
    if (journey.airPayoutRejected && journey.airLuggageRejected) {
      return t(
        'air_payout.modal.success.protect_trip_info.title_air_payout_and_air_luggage',
      );
    } else if (journey.airPayoutRejected) {
      return t('air_payout.modal.success.protect_trip_info.title_air_payout');
    }
    return t('air_payout.modal.success.protect_trip_info.title_air_luggage');
  };

  const getSubtitle = () => {
    if (journey.airPayoutRejected && journey.airLuggageRejected) {
      return t(
        'air_payout.modal.success.unable_protect_air_payout_and_air_luggage',
      );
    } else if (journey.airPayoutRejected) {
      return t('air_payout.modal.success.unable_protect_air_payout');
    }
    return t('air_payout.modal.success.unable_protect_air_luggage');
  };

  const getExclusionsMentionedKey = () => {
    if (journey.airPayoutRejected && journey.airLuggageRejected) {
      return 'air_payout.modal.success.protect_trip_info.exclusions_mentioned_air_payout_and_air_luggage';
    } else if (journey.airPayoutRejected) {
      return 'air_payout.modal.success.protect_trip_info.exclusions_mentioned_air_payout';
    }
    return 'air_payout.modal.success.protect_trip_info.exclusions_mentioned_air_luggage';
  };

  if (!airPayoutUnlimitedAccess && !hasCredit && !userProfile) {
    return null;
  }

  const airLuggageIpidUrl = airLuggagePolicy(airLuggageIpidVersion);
  const airPayoutIpidUrl = airPayoutPolicy(airPayoutIpidVersion);

  interface InfoBoxDetail {
    tkey: TranslationKey;
    value?: {
      airPayoutTermsUrl?: string;
      airLuggageTermsUrl?: string;
      timeToDeparture?: number;
      count?: number;
    };
  }
  const infoBoxDetails: InfoBoxDetail[] = [
    {
      tkey: required24hDepartureTime
        ? 'air_payout.modal.success.protect_trip_info.until_scheduled_departure'
        : 'air_payout.modal.success.protect_trip_info.until_departure',

      value: { count: departureThresholdHours },
    },
    {
      tkey: 'air_payout.modal.success.protect_trip_info.no_flights_delayed',
    },
    {
      tkey: 'air_payout.modal.success.protect_trip_info.no_flights_canceled',
    },
    {
      tkey: getExclusionsMentionedKey(),
      value: {
        airPayoutTermsUrl: airPayoutIpidUrl,
        airLuggageTermsUrl: airLuggageIpidUrl,
      },
    },
  ];

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent maxW="400px" backgroundColor="greyscale.100">
        <Center background="greyscale.100" w="100%" borderTopRadius="md">
          <ModalCloseButton
            borderRadius="sm"
            boxSize={2}
            top={6}
            right={6}
            backgroundColor="greyscale.300"
            sx={{
              svg: { boxSize: 2.5 },
              _hover: { backgroundColor: 'primary.200' },
              _active: {
                backgroundColor: 'primary.800',
                color: 'greyscale.200',
              },
            }}
            position="absolute"
            data-testid="modal-close-button"
          />
          <Image src={messageFormal} margin={6} />
        </Center>
        <VStack
          alignItems="center"
          mt={4}
          mb={6}
          mx={6}
          backgroundColor="greyscale.100"
          spacing={6}
        >
          <Flex flexDirection="column" alignItems="center">
            <Text fontWeight="700" fontSize="3xl">
              {t('common.sorry')}
            </Text>
            <Text
              fontWeight="500"
              fontSize="lg"
              textAlign="center"
              data-testid="subtitle-details"
            >
              {getSubtitle()}
            </Text>
          </Flex>
          {!airPayoutUnlimitedAccess && hasCredit ? (
            <Text
              color="greyscale.700"
              fontSize="md"
              textAlign="center"
              data-testid="credit-details"
            >
              {Boolean(airPayoutCreditsLeft) &&
                t('air_payout.modal.success.wont_charge', {
                  count: airPayoutCreditsLeft,
                })}{' '}
              {t('air_payout.modal.success.dont_forget')}
            </Text>
          ) : null}
          <InfoBox
            isChat
            icon={<Info color="primary.500" />}
            variant="secondary"
            data-testid="info-box-details"
          >
            <Text color="greyscale.700" fontSize="md" fontWeight="normal">
              {getTitle()}
            </Text>
            <UnorderedList listStyleType="none" mt={6} p={0} ms={0}>
              {infoBoxDetails.map((infoBoxDetail) => (
                <ListItem key={infoBoxDetail.tkey} display="flex">
                  <Text color="greyscale.700" fontSize="md" fontWeight="normal">
                    {'- '}
                    <Trans
                      // invesigate why this is working only when casted to TranslationKey[]
                      i18nKey={
                        infoBoxDetail.tkey as unknown as TranslationKey[]
                      }
                      values={infoBoxDetail.value}
                      components={{
                        a: (
                          <Link
                            color="primary.500"
                            fontSize="md"
                            fontWeight="normal"
                            _visited={{}}
                            isExternal
                          />
                        ),
                      }}
                    />
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </InfoBox>
          <Button
            onClick={
              airPayoutProtected || airLuggageProtected
                ? handleContinue
                : handleClose
            }
            w="100%"
            variant="primary"
            size="xs"
            fontSize="sm"
            data-testid="action-button"
          >
            {airPayoutProtected || airLuggageProtected
              ? t('common.continue')
              : t('common.check_trip_details')}
          </Button>
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export default AirBundleRejectedModal;
