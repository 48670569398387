import React, { type PropsWithChildren } from 'react';
import { AuthHTTPClientProvider } from '@airhelp/auth/react';
import { AhPlusHTTPClientProvider } from '@airhelp/plus/react';
import { WebappHTTPClientProvider } from '@airhelp/webapp/react';
import { LiftoffHTTPClientProvider } from '@airhelp/liftoff/react';
import { CoralHTTPClientProvider } from '@airhelp/coral/react';
import {
  ahplusClient,
  authClient,
  webappClient,
  coralClient,
  liftoffClient,
} from './clients';

const HTTPClientProviders = ({ children }: PropsWithChildren) => {
  return (
    <AuthHTTPClientProvider client={authClient}>
      <WebappHTTPClientProvider client={webappClient}>
        <CoralHTTPClientProvider client={coralClient}>
          <AhPlusHTTPClientProvider client={ahplusClient}>
            <LiftoffHTTPClientProvider client={liftoffClient}>
              {children}
            </LiftoffHTTPClientProvider>
          </AhPlusHTTPClientProvider>
        </CoralHTTPClientProvider>
      </WebappHTTPClientProvider>
    </AuthHTTPClientProvider>
  );
};

export default HTTPClientProviders;
