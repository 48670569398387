import { useClaimDocuments } from '@airhelp/liftoff/react';
import { useMemo } from 'react';
import { type FetchClaimDocumentsParams } from '@airhelp/liftoff';

export function useIsDocumentRequestOpen({
  claimId,
}: FetchClaimDocumentsParams) {
  const { data } = useClaimDocuments({ params: { claimId } });

  return {
    isOpen: useMemo(() => {
      return (
        data?.some(
          (record) =>
            record.documentRequest.id !== null ||
            record.procurement.id !== null,
        ) ?? false
      );
    }, [data]),
  };
}
