import {
  AirplaneLandingAirstrip,
  AirplaneTakeOffAirstrip,
} from '@airhelp/icons';
import { type CountryCode, Flag } from '@airhelp/flags';
import {
  Text,
  Box,
  Flex,
  Heading,
  HStack,
  VStack,
  Tag,
  type FlexProps,
} from '@chakra-ui/react';
import { formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { fontFamilyFraktion } from '@airhelp/design-tokens';

type FlightCheckpointProps =
  | ({
      variant: 'base';
    } & FlightCheckpointBaseProps)
  | ({
      variant: 'info' | 'on-time' | 'disrupted' | 'cancelled';
    } & FlightCheckpointBaseProps &
      FlightCheckpointStatusProps);

interface FlightCheckpointBaseProps {
  arrivalCountryCode: CountryCode;
  departureCountryCode: CountryCode;
  departureDate: Date;
  arrivalAirportHeader: string;
  arrivalAirportSubheader: string;
  arrivalAirportTerminal?: string;
  departureAirportHeader: string;
  departureAirportSubheader: string;
  departureAirportTerminal?: string;
  isRtl?: boolean;
}

interface FlightCheckpointStatusProps {
  flightNumber: string;
  airlineName: string;
}

export function FlightCheckpoint(props: FlightCheckpointProps) {
  const {
    arrivalAirportHeader,
    arrivalAirportSubheader,
    arrivalAirportTerminal,
    departureAirportHeader,
    departureAirportSubheader,
    departureAirportTerminal,
    arrivalCountryCode,
    departureCountryCode,
    departureDate,
    isRtl,
  } = props;
  const { t } = useTranslation();
  return (
    <>
      <VStack
        backgroundColor="white"
        border="1px solid"
        borderColor="greyscale.400"
        rounded="2xl"
        p={4}
        w="full"
      >
        {props.variant !== 'base' && (
          <CheckpointStatus
            variant={props.variant}
            flightNumber={props.flightNumber}
            airlineName={props.airlineName}
            date={formatDate(departureDate, 'dd MMM')}
            i18n={{
              infoTag: t('claims.flight_details.status.info'),
              onTimeTag: t('claims.flight_details.status.on_time'),
              disruptedTag: t('claims.flight_details.status.disrupted'),
              cancelledTag: t('claims.flight_details.status.cancelled'),
            }}
          />
        )}
        <VStack w="full" pt={4}>
          <AirportEntry
            isRtl={isRtl}
            variant="departure"
            countryCode={departureCountryCode}
            header={departureAirportHeader}
            subheader={departureAirportSubheader}
            terminal={departureAirportTerminal}
          />
        </VStack>

        <HStack rounded="lg" backgroundColor="greyscale.300" w="full">
          {/* TODO: Uncomment when flight time will be available*/}
          {/*<Box h="full" w={0.5} backgroundColor="white" />*/}
          {/*<HStack flex={1} justifyContent="space-between" py={3} pe={3} ps={2}>*/}
          {/*  <Text*/}
          {/*    color="greyscale.700"*/}
          {/*    fontSize={'sm'}*/}
          {/*    fontWeight={'bold'}*/}
          {/*    textTransform={'uppercase'}*/}
          {/*  >*/}
          {/*    {formatDate(date, 'mm:ss')}*/}
          {/*  </Text>*/}
          {/*  <Clock color="greyscale.600" />*/}
          {/*</HStack>*/}
        </HStack>
      </VStack>
      <VStack
        backgroundColor="white"
        border="1px solid"
        borderColor="greyscale.400"
        rounded="2xl"
        p={4}
        w="full"
      >
        <AirportEntry
          variant="arrival"
          countryCode={arrivalCountryCode}
          header={arrivalAirportHeader}
          subheader={arrivalAirportSubheader}
          terminal={arrivalAirportTerminal}
          isRtl={isRtl}
        />
      </VStack>
    </>
  );
}

function AirportEntry({
  variant,
  countryCode,
  header,
  subheader,
  terminal,
  isRtl,
}: {
  variant: 'departure' | 'arrival';
  countryCode: CountryCode;
  header: string;
  subheader: string;
  terminal?: string;
  isRtl?: boolean;
}) {
  return (
    <HStack spacing={2} w="full">
      <CheckpointMarker
        variant={variant}
        countryCode={countryCode}
        mx={4}
        isRtl={isRtl}
      />
      <VStack align="start" flex={1} spacing={0}>
        <Heading variant="h5" fontSize="sm" mb={0.5} color="greyscale.700">
          {header}
        </Heading>
        <Text fontSize="xs" color="greyscale.600" lineHeight={1}>
          {subheader}
        </Text>
        <Text fontSize="xs" color="greyscale.600" fontWeight="bold">
          {terminal}
        </Text>
      </VStack>
    </HStack>
  );
}

const STATUS_VARIANT_PROPS = {
  info: {
    background: 'primary.200',
    foreground: 'primary.500',
    tagColor: 'info',
  },
  'on-time': {
    background: 'success.100',
    foreground: 'success.600',
    tagColor: 'success',
  },
  disrupted: {
    background: 'warning.100',
    foreground: 'warning.500',
    tagColor: 'warning',
  },
  cancelled: {
    background: 'danger.200',
    foreground: 'danger.400',
    tagColor: 'danger',
  },
};

function CheckpointStatus({
  variant,
  flightNumber,
  airlineName,
  i18n,
  date,
}: {
  variant: 'info' | 'on-time' | 'disrupted' | 'cancelled';
  flightNumber: string;
  airlineName: string;
  date: string;
  i18n: {
    infoTag: string;
    onTimeTag: string;
    disruptedTag: string;
    cancelledTag: string;
  };
}) {
  const variantProps = STATUS_VARIANT_PROPS[variant];
  return (
    <HStack
      backgroundColor={variantProps.background}
      color={variantProps.foreground}
      ps={4}
      pe={2}
      py={2}
      rounded="lg"
      justifyContent="space-between"
      w="full"
    >
      <HStack
        spacing={1}
        textTransform="uppercase"
        flexShrink={0}
        minW={0}
        flex={1}
      >
        <Text
          fontWeight="bold"
          color={variantProps.foreground}
          fontSize="11px"
          letterSpacing="-0.014em"
          fontFamily={fontFamilyFraktion}
        >
          {flightNumber}
        </Text>
        <Text color={variantProps.foreground} fontSize="8px">
          •
        </Text>
        <Text
          fontWeight="bold"
          color={variantProps.foreground}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          minW={0}
          fontSize="11px"
          letterSpacing="-0.014em"
          fontFamily={fontFamilyFraktion}
        >
          {airlineName}
        </Text>
        <Text color={variantProps.foreground} fontSize="8px">
          •
        </Text>
        <Text
          fontWeight="bold"
          color={variantProps.foreground}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          minW="max-content"
          fontSize="11px"
          letterSpacing="-0.014em"
          fontFamily={fontFamilyFraktion}
        >
          {date}
        </Text>
      </HStack>
      <Tag
        colorScheme={variantProps.tagColor}
        size="xs"
        fontSize="11px"
        letterSpacing="-0.014em"
        fontFamily={fontFamilyFraktion}
        rounded="md"
      >
        {
          {
            info: i18n.infoTag,
            'on-time': i18n.onTimeTag,
            disrupted: i18n.disruptedTag,
            cancelled: i18n.cancelledTag,
          }[variant]
        }
      </Tag>
    </HStack>
  );
}

function CheckpointMarker({
  variant,
  countryCode,
  isRtl = false,
  ...props
}: {
  variant: 'departure' | 'arrival';
  countryCode: CountryCode;
  isRtl?: boolean;
} & FlexProps) {
  return (
    <Flex
      justify="center"
      align="center"
      pos="relative"
      w="42px"
      h="42px"
      p={2}
      border="1px solid"
      borderColor="greyscale.300"
      rounded="full"
      {...props}
    >
      <Box p={2}>
        {variant === 'departure' ? (
          <AirplaneTakeOffAirstrip
            color="primary.600"
            transform={isRtl ? 'scale(-1, 1)' : undefined}
          />
        ) : (
          <AirplaneLandingAirstrip
            color="primary.600"
            transform={isRtl ? 'scale(-1, 1)' : undefined}
          />
        )}
      </Box>
      <Box pos="absolute" right={0} bottom={0} transform="translate(25%, 25%)">
        <Flag code={countryCode} size="xs" rounded />
      </Box>
    </Flex>
  );
}
