import { type FetchClaimDocumentsResponse } from '@airhelp/liftoff';
import { useTranslation } from 'react-i18next';
import { Text, VStack } from '@chakra-ui/react';
import { type Claim } from '@airhelp/webapp';
import { Header } from '../../DetailsBox/DetailsBox';
import { DocumentButton } from '../../DocumentButton';
import { useDocumentRequestRedirectLink } from './useDocumentRequestRedirectLink';

type PassengerDocumentsProps = FetchClaimDocumentsResponse[number] & {
  claimId: Claim['id'];
};

export function PassengerDocuments({
  claimId,
  passenger,
  documents,
}: PassengerDocumentsProps) {
  const { t } = useTranslation();
  const { link } = useDocumentRequestRedirectLink({ claimId });

  function getPassengerTypeLabel() {
    switch (true) {
      case passenger.minor:
        return t('claims.documents.passenger.minor');
      case passenger.type === 'Claimant':
        return t('claims.documents.passenger.main');
      default:
        return t('claims.documents.passenger.fellow');
    }
  }

  function onDocumentClick() {
    window.location.replace(link);
  }

  const renderNoDocumentsMessage = () => (
    <Text fontSize="sm" color="primary.900" fontWeight="medium">
      {t('claims.documents.no_passenger_documents')}
    </Text>
  );

  function renderDocuments() {
    if (!documents.length) {
      return renderNoDocumentsMessage();
    }

    return documents.map((document) => {
      const isDocumentRequestOpen =
        document.procuredDocument.id !== null ||
        document.requestedDocument.id !== null;
      const isButtonDisabled =
        !isDocumentRequestOpen || document.status !== 'missing';

      return (
        <DocumentButton
          key={document.id}
          status={document.status}
          type={document.type}
          onClick={onDocumentClick}
          disabled={isButtonDisabled}
        />
      );
    });
  }

  return (
    <VStack
      data-testid="passenger-documents"
      background="greyscale.100"
      rounded="xl"
      w="full"
      spacing={5}
      align="stretch"
      p={{
        base: 4,
        md: 6,
        lg: 8,
      }}
    >
      <Header title={passenger.name} subtitle={getPassengerTypeLabel()} />
      <VStack
        spacing={2}
        align="stretch"
        ps={{
          base: 0,
          lg: 12,
        }}
      >
        {renderDocuments()}
      </VStack>
    </VStack>
  );
}
