import { Heading, VStack } from '@chakra-ui/react';
import { Chat, Envelope, Help } from '@airhelp/icons';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import config from 'config';
import SupportBoxItem from 'elements/SupportBoxItem';
import { isRtl } from 'utils/textDirection/isRtl';
import { useZowie } from '../../contexts/ZowieContext';

export function SupportBox() {
  const { t } = useTranslation();
  const { openZowieChat } = useZowie();
  const { locale } = useLocale();

  return (
    <VStack
      gap={4}
      p={{
        base: 4,
        md: 6,
      }}
      borderRadius="xl"
      bg="white"
      align="start"
    >
      <Heading
        display={{
          base: 'none',
          xl: 'flex',
        }}
        fontSize="xl"
        as="h3"
      >
        {t('common.support.how_can_we_help')}
      </Heading>

      <VStack gap={0} w="full">
        <SupportBoxItem
          icon={<Chat />}
          title={t('common.support.chat')}
          description={t('common.support.our_team_for_you')}
          onClick={openZowieChat}
          dataTestId="support-box-chat"
          isRtl={isRtl(locale)}
        />
        <SupportBoxItem
          icon={<Envelope />}
          title={t('common.support.customer_support')}
          description={t('common.support.contact_support')}
          dataTestId="support-box-customer-support"
          link={config.contactUrl}
          isRtl={isRtl(locale)}
        />
        <SupportBoxItem
          icon={<Help />}
          title={t('common.support.faq')}
          dataTestId="support-box-faq"
          description={t('common.support.we_answer')}
          link={config.helpUrl.replace('{{locale}}', locale)}
          isRtl={isRtl(locale)}
        />
      </VStack>
    </VStack>
  );
}
