import React from 'react';
import { NavLink, useMatch } from 'react-router';
import { type IconType } from '@airhelp/icons';
import { Link, Text, Icon, Box, Flex, Tooltip } from '@chakra-ui/react';
import useLocalStorage from 'hooks/useLocalStorage';

export interface NavBarItem {
  icon: IconType;
  hoverIcon: IconType;
  label: string;
  route?: string;
  action?: () => void;
  dataTestId: string;
}

const NavItem: React.FC<{ item: NavBarItem }> = ({ item }) => {
  const routeActive = useMatch(item.route ?? '');
  const [layoutCollapse] = useLocalStorage('layout-collapsed');
  const iconActive = Boolean(routeActive);

  return (
    <Box
      display={{ base: 'inline-flex', md: 'block' }}
      height={{ base: '100%', md: 'initial' }}
      onClick={item.action}
    >
      <Tooltip
        display={{
          base: 'none',
          md: 'block',
          lg: layoutCollapse ? 'block' : 'none',
        }}
        label={item.label}
        fontWeight="normal"
        bgColor="greyscale.800"
        color="greyscale.200"
        borderRadius="md"
        p={2}
        placement="right"
        gutter={36}
      >
        <Link
          as={NavLink}
          to={item.route || '#'}
          display="flex"
          alignItems="center"
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent={{ base: 'center', md: 'flex-start' }}
          width="100%"
          height={{ base: 'initial', md: 6 }}
          cursor="pointer"
          data-testid={item.dataTestId}
          _hover={{
            borderRadius: { base: 8, md: 0 },
            '&.active': {
              borderRadius: 0,
              svg: { color: 'primary.500' },
              label: { color: 'primary.500', textDecoration: 'none' },
            },
            svg: { color: { base: 'greyscale.600', md: 'primary.500' } },
            label: { color: { base: 'greyscale.600', md: 'primary.500' } },
          }}
          _activeLink={{
            svg: { color: 'primary.500' },
            label: {
              color: 'primary.500',
              fontWeight: { base: 'normal', md: 'medium' },
            },
            _after: {
              content: '""',
              position: 'absolute',
              right: 0,
              width: { base: 0, md: '2px' },
              height: { base: 0, md: '24px' },
              borderRadius: '2px 0 0 2px',
              backgroundColor: 'primary.500',
            },
          }}
        >
          <Icon
            as={iconActive ? item.hoverIcon : item.icon}
            color="greyscale.600"
            me={{ base: 0, lg: layoutCollapse ? 0 : 3 }}
          />

          <Flex
            width="100%"
            justifyContent={{ base: 'center', md: 'flex-start' }}
            transition="opacity 300ms linear"
            opacity={{ base: 1, md: layoutCollapse ? 0 : 1 }}
          >
            <Text
              display={{
                base: 'block',
                md: 'none',
                lg: layoutCollapse ? 'none' : 'block',
              }}
              as="label"
              fontSize={{ base: 'xs', md: 'md' }}
              color="greyscale.600"
              textOverflow="ellipsis"
              whiteSpace={{ base: 'nowrap', md: 'normal' }}
              cursor="pointer"
              mt={{ base: 1, md: 0 }}
            >
              {item.label}
            </Text>
          </Flex>
        </Link>
      </Tooltip>
    </Box>
  );
};

export default NavItem;
