import React, { type PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, generatePath, useNavigate } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { refreshAccessToken } from '@airhelp/auth';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { PageLoader } from 'elements';
import { prefetchMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { setStatusBarColorByPathname } from 'utils/setStatusBarColorByPathname';
import { setLangHtmlAttribute } from 'utils/setLangHtmlAttribute';
import { setDirectionalityHtmlAttribute } from 'utils/setDirectionalityHtmlAttribute';
import { PAGE_PATHS } from 'config/routes';
import { authClient } from 'api/clients/clients';
import useFeatureFlags from 'hooks/api/useFeatureFlags';

enum UserInitializeState {
  INITIAL,
  INITIALIZING,
  INITIALIZED,
}

const AppInitializer: React.FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const [userInitializeState, setUserInitializeState] =
    useState<UserInitializeState>(UserInitializeState.INITIAL);
  const location = useLocation();
  const { isError, isLoading: isLoadingFeatureFlags } = useFeatureFlags();
  const navigate = useNavigate();
  const { locale } = useLocale();

  const { displayAddToHomeScreenBanner } = useLayout();

  const isLoading =
    isLoadingFeatureFlags ||
    userInitializeState !== UserInitializeState.INITIALIZED;

  useEffect(() => {
    async function initialize() {
      if (userInitializeState === UserInitializeState.INITIAL) {
        setUserInitializeState(UserInitializeState.INITIALIZING);

        await refreshAccessToken({ client: authClient }).catch(
          (error: unknown) => {
            error;
          },
        );
        await prefetchMyProfile(queryClient);

        setUserInitializeState(UserInitializeState.INITIALIZED);
      }
    }

    initialize();
  }, [userInitializeState, setUserInitializeState]);

  useEffect(() => {
    setStatusBarColorByPathname(
      location.pathname,
      displayAddToHomeScreenBanner,
    );
  }, [location.pathname, displayAddToHomeScreenBanner]);

  useEffect(() => {
    if (isError) {
      navigate(generatePath(PAGE_PATHS.ERROR_PAGE));
    }
  }, [isError]);

  useEffect(() => {
    setLangHtmlAttribute(locale);
    setDirectionalityHtmlAttribute(locale);
  }, [locale]);

  if (isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

export default AppInitializer;
