import { type ButtonProps, chakra, Flex, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  AltArrowForward,
  Check,
  Clock,
  type IconType,
  Info,
  SingleDocument,
} from '@airhelp/icons';
import { type FetchClaimDocumentsResponse } from '@airhelp/liftoff';

type DocumentStatus =
  FetchClaimDocumentsResponse[number]['documents'][number]['status'];

interface DocumentButtonProps extends Omit<ButtonProps, 'type'> {
  type: string;
  status: DocumentStatus;
}

const STATUS_VARIANT_ICON_PROPS: Record<
  DocumentStatus,
  { icon: IconType; size: string; fill: string; background: string }
> = {
  pending: {
    icon: Clock,
    size: '14px',
    fill: 'primary.400',
    background: 'primary.100',
  },
  accepted: {
    icon: Check,
    size: '12px',
    fill: 'greyscale.100',
    background: 'success.600',
  },
  missing: {
    icon: Info,
    size: '14px',
    fill: 'warning.500',
    background: 'greyscale.100',
  },
  rejected: {
    icon: Info,
    size: '14px',
    fill: 'warning.500',
    background: 'greyscale.100',
  },
};

const STATUS_VARIANT_PROPS: Record<
  DocumentStatus,
  {
    icon: string;
    iconBackground: string;
    text: string;
  }
> = {
  pending: {
    icon: 'primary.400',
    iconBackground: 'primary.100',
    text: 'greyscale.500',
  },
  accepted: {
    icon: 'primary.400',
    iconBackground: 'primary.100',
    text: 'greyscale.100',
  },
  missing: {
    icon: 'warning.500',
    iconBackground: 'warning.200',
    text: 'warning.500',
  },
  rejected: {
    icon: 'warning.500',
    iconBackground: 'warning.200',
    text: 'warning.500',
  },
};

export function DocumentButton({
  type,
  status,
  ...restProps
}: DocumentButtonProps) {
  const { t } = useTranslation();

  const showDocumentStatus = status !== 'accepted';
  const variantProps = STATUS_VARIANT_PROPS[status];
  const variantIconProps = STATUS_VARIANT_ICON_PROPS[status];

  return (
    <chakra.button
      data-testid="document-button"
      p={4}
      display="flex"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="greyscale.400"
      rounded="lg"
      cursor="pointer"
      role="button"
      backgroundColor="greyscale.100"
      _hover={{ bg: 'greyscale.200', _disabled: { bg: 'greyscale.100' } }}
      _disabled={{
        cursor: 'default',
      }}
      {...restProps}
    >
      <Flex
        as="span"
        position="relative"
        justify="center"
        align="center"
        w="40px"
        h="40px"
        rounded="full"
        backgroundColor={variantProps.iconBackground}
        me={2.5}
        shrink={0}
      >
        <SingleDocument color={variantProps.icon} width="20px" height="20px" />
        <Flex
          as="span"
          position="absolute"
          justify="center"
          align="center"
          w="16px"
          h="16px"
          rounded="full"
          background="greyscale.100"
          bottom={0}
          right={0}
        >
          <variantIconProps.icon
            rounded="full"
            width={variantIconProps.size}
            height={variantIconProps.size}
            backgroundColor={variantIconProps.background}
            color={variantIconProps.fill}
          />
        </Flex>
      </Flex>
      <VStack
        as="span"
        flex={1}
        gap={0}
        align="start"
        textAlign="start"
        justifyContent="center"
      >
        <Text
          fontSize="md"
          color="primary.900"
          fontWeight="medium"
          wordBreak="break-word"
        >
          {/* @ts-expect-error -- we don't have typings for all document types */}
          {t(`claims.documents.types.${type}`)}
        </Text>
        {showDocumentStatus ? (
          <Text fontSize="12px" color={variantProps.text} fontWeight="medium">
            {t(`claims.documents.status.${status}`)}
          </Text>
        ) : null}
      </VStack>
      {!restProps.disabled ? (
        <Flex as="span" p={2} justify="center" align="center">
          <AltArrowForward width={6} height={6} color="greyscale.500" />
        </Flex>
      ) : null}
    </chakra.button>
  );
}
