import { useTranslation } from 'react-i18next';
import { generatePath, useParams, useSearchParams } from 'react-router';
import { Text } from '@chakra-ui/react';
import { ProductName } from '@airhelp/plus';
import StripeCheckoutWidget from 'components/StripeCheckoutWidget';
import { PAGE_PATHS } from 'config/routes';

interface PageParams extends Record<string, string | undefined> {
  token: string;
}

const CheckoutView = () => {
  const { t } = useTranslation();
  const { token } = useParams<PageParams>();
  const [searchParams] = useSearchParams();

  const baseCallbackUrl = generatePath(
    PAGE_PATHS.UPSELL.TRIPS.PROCESSING_PAYMENT_PAGE,
    {
      token: token?.toString() || '',
    },
  );

  const callbackUrl = new URL(baseCallbackUrl, window.location.origin);

  callbackUrl.search = searchParams.toString();

  return (
    <>
      <Text fontSize="lg" fontWeight="medium" mb={5} data-testid="card-details">
        {t('payment.checkout.payment_method')}
      </Text>
      <StripeCheckoutWidget
        productName={ProductName.UPGRADE_TO_TRIP_COMPLETE}
        callbackUrl={callbackUrl.toString()}
        tripUuid={token}
      />
    </>
  );
};

export default CheckoutView;
