import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { HStack, VStack } from '@chakra-ui/react';
import supportFormal from 'assets/images/support-formal@2x.png';
import tripsRoute from 'assets/images/trips-route@2x.png';
import JourneysList from 'components/Journeys/JourneysList';
import { PAGE_PATHS } from 'config/routes';
import { useTabsState } from 'contexts/TabsContext/TabsContext';
import { useZowie } from 'contexts/ZowieContext';
import ActionCard from 'elements/ActionCard';
import { type UserProfile } from 'models/userProfile/UserProfile';
import { journeyUtils } from 'utils';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type Journey } from 'models/journey/Journey';

interface IComponent {
  userProfile: UserProfile;
  journeys: Journey[];
}

export const JourneysView: React.FC<IComponent> = ({
  journeys,
  userProfile,
}) => {
  const { openZowieChat } = useZowie();
  const { trackCtaClick } = useTrackEvent();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const policy = userProfile.policy;

  const tabId = 'journey-list-tab';
  const activeTab = useTabsState(tabId, 'Active');
  const canAddTrip = policy.dashboard?.canAddTrip;

  const getFilteredAndSortedJourneys = (journeyList: Journey[]) => {
    switch (activeTab) {
      case 'Active':
        return journeyUtils.filteredAndSortedJourneys(journeyList, true, 0);
      case 'Past':
        return journeyUtils.filteredAndSortedJourneys(journeyList, false, 0);
      default:
        return journeyList;
    }
  };

  const filteredAndSortedJourneys = getFilteredAndSortedJourneys(journeys);

  const handleProtectTripClick = () => {
    trackCtaClick('add trip button', 'trips page');
    navigate(generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE), {
      state: {
        from: 'journeys',
      },
    });
  };

  const handleSupportClick = () => {
    openZowieChat();

    trackCtaClick('need help contact support', 'trips page');
  };

  return (
    <VStack spacing={12} width="100%">
      {canAddTrip ? (
        <HStack width="100%" spacing={4} alignItems="stretch">
          <ActionCard
            header={t('journeys.protect_future_trip')}
            description={t('journeys.enjoy_peace_of_mind')}
            handleClick={handleProtectTripClick}
            imageSrc={tripsRoute}
            data-testid="action-card-add-trip"
          />
          <ActionCard
            header={t('dashboard.need_help')}
            description={t('dashboard.our_team_for_you')}
            handleClick={handleSupportClick}
            imageSrc={supportFormal}
            data-testid="action-card-support"
          />
        </HStack>
      ) : null}
      <JourneysList
        journeys={filteredAndSortedJourneys}
        activeTab={activeTab}
        userPolicy={policy}
      />
    </VStack>
  );
};

export default JourneysView;
