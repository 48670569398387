import { useTranslation } from 'react-i18next';
import { ProductName, ProductDisplayName } from '@airhelp/plus';
import { find } from 'lodash';
import { type Offer } from 'models/Offers';
import {
  OFFER_NAME_FREE,
  OFFER_NAME_LITE,
  OFFER_NAME_SMART,
  OFFER_NAME_PRO,
} from 'components/MyAccountView/PlanManagementView/plan-management-config';

export const getSmartProduct = (offers: Record<string, Offer>) => {
  const name = ProductName.AIRHELP_SMART_ANNUALLY;

  return offers[name];
};

export const getOfferByTier = (
  offers: Record<string, Offer>,
  tier?: string,
) => {
  switch (tier) {
    case 'pro':
      return offers[ProductName.AIRHELP_PRO_ANNUALLY];
    case 'lite':
      return offers[ProductName.AIRHELP_LITE_ANNUALLY];
    default:
      return offers[ProductName.AIRHELP_SMART_ANNUALLY];
  }
};

export const getUpgradeOfferByTier = (offers: Offer[], tier?: string) => {
  switch (tier) {
    case 'pro':
      return (
        find(offers, { name: ProductName.AIRHELP_PRO_ANNUALLY }) ||
        find(offers, { name: ProductName.AIRHELP_PRO_SINGLE })
      );
    case 'lite':
      return (
        find(offers, { name: ProductName.AIRHELP_LITE_ANNUALLY }) ||
        find(offers, { name: ProductName.AIRHELP_LITE_SINGLE })
      );
    case 'smart':
      return (
        find(offers, { name: ProductName.AIRHELP_SMART_ANNUALLY }) ||
        find(offers, { name: ProductName.AIRHELP_SMART_SINGLE })
      );
    default:
      return offers[0] || null;
  }
};

export const getPlanByProductName = ({
  productName,
  shortName = true,
}: {
  productName?: ProductName;
  shortName?: boolean;
}) => {
  switch (true) {
    case productName &&
      (isProAnnualMembership(productName) ||
        isProSingleMembership(productName)):
      return shortName ? OFFER_NAME_PRO : ProductDisplayName.AIRHELP_PLUS_PRO;
    case productName &&
      (isSmartAnnualMembership(productName) ||
        isSmartSingleMembership(productName)):
      return shortName
        ? OFFER_NAME_SMART
        : ProductDisplayName.AIRHELP_PLUS_SMART;
    case productName &&
      (isLiteAnnualMembership(productName) ||
        isLiteSingleMembership(productName)):
      return shortName ? OFFER_NAME_LITE : ProductDisplayName.AIRHELP_PLUS_LITE;
    default:
      return OFFER_NAME_FREE;
  }
};

export const getMembershipPlan = ({
  offers,
  renewOffer,
  isManualRenew,
  isUpgradeOffer,
  upgradeOffers,
  tier,
}: {
  offers: Record<string, Offer>;
  renewOffer?: Offer;
  upgradeOffers?: Offer[];
  isUpgradeOffer?: boolean;
  isManualRenew: boolean;
  tier?: string;
}) => {
  if (isUpgradeOffer && !!upgradeOffers) {
    return getUpgradeOfferByTier(upgradeOffers, tier);
  }

  if (isManualRenew && !!renewOffer) {
    return renewOffer;
  }

  return getOfferByTier(offers, tier);
};

export const isSmartAnnualMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_SMART_ANNUALLY;
};

export const isSmartSingleMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_SMART_SINGLE;
};

export const isLiteAnnualMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_LITE_ANNUALLY;
};

export const isLiteSingleMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_LITE_SINGLE;
};

export const isProAnnualMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_PRO_ANNUALLY;
};

export const isProSingleMembership = (productName: ProductName) => {
  return productName === ProductName.AIRHELP_PRO_SINGLE;
};

export const useGetProductTitle = ({
  productName,
}: {
  productName: ProductName;
}) => {
  const { t } = useTranslation();
  const plan = getPlanByProductName({ productName });

  return t('common.airhelp_plus_plan', { plan });
};

export const useGetProductShortTitle = ({
  productName,
}: {
  productName: ProductName;
}) => {
  const { t } = useTranslation();

  const shortPlanName = getPlanByProductName({ productName, shortName: false });

  return `${shortPlanName} ${t('common.membership')}:`;
};

export const checkPromoCodeEntitlement = (
  isManualRenew: boolean,
  isUpgradeOffer: boolean,
  claimId?: string,
) => {
  return !isManualRenew && !claimId && !isUpgradeOffer;
};

export const useGetActionNameForProduct = (productName?: ProductName) => {
  const { t } = useTranslation();

  const plan = getPlanByProductName({
    productName: productName || ProductName.AIRHELP_SMART_ANNUALLY,
  });

  if (!productName) {
    return t('payment.pay');
  }

  if (
    [
      ProductName.AIRHELP_PRO_ANNUALLY,
      ProductName.AIRHELP_SMART_ANNUALLY,
      ProductName.AIRHELP_LITE_ANNUALLY,
    ].includes(productName)
  ) {
    return t('payment.buy_airhelp', { plan });
  }

  if (productName === ProductName.UPGRADE_TO_TRIP_COMPLETE) {
    return t('payment.checkout.upgrade_title', {
      plan: t('pricing.plans.complete'),
    });
  }

  return t('payment.pay');
};
