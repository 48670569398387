export enum CommonParams {
  RENEW = 'renew',
  UPGRADE = 'upgrade',
  CHANNEL = 'channel',
  TIER = 'tier',
  CLAIM_ID = 'claimId',
  CLAIM_ENQUIRY_UUID = 'claimEnquiryUuid',
  PAYMENT_ID = 'paymentId',
  BRAND = 'brand',
  PROMO_CODE = 'promocode',
}

export const castBooleanParam = (
  params: URLSearchParams,
  param: CommonParams,
) => {
  const value = params.get(param);
  return value === 'true';
};

export const castStringParam = (
  params: URLSearchParams,
  param: CommonParams,
) => {
  return params.get(param) || '';
};
