import React from 'react';
import { Box } from '@chakra-ui/react';
import { plainToClass } from 'class-transformer';
import { generatePath, Navigate } from 'react-router';
import { type UserProfile } from 'models/userProfile/UserProfile';
import { PAGE_PATHS } from 'config/routes';
import { Offer } from 'models/Offers';
import {
  getOfferByTier,
  isProAnnualMembership,
  isProSingleMembership,
} from 'components/Membership/membershipHelper';
import { OFFER_NAME_FREE } from './plan-management-config';
import PlanComparison from './PlanComparison';

const PlanManagementView: React.FC<{
  userProfile: UserProfile;
  offers: Record<string, Offer>;
  upgradeOffers?: Offer[];
}> = ({ userProfile, offers, upgradeOffers = [] }) => {
  const policy = userProfile.policy;
  const currentProductName = policy.membership.productName || null;
  const isFreeAccount = policy.membership.isFreeAccount;
  const isProMembership =
    isProAnnualMembership(currentProductName) ||
    isProSingleMembership(currentProductName);

  const freeOffer = plainToClass(Offer, {
    id: null,
    name: OFFER_NAME_FREE,
    displayName: policy.membership?.displayName || OFFER_NAME_FREE,
    paymentFrequency: null,
    prices: {
      BRL: { code: 'BRL', price: 0 },
      CHF: { code: 'CHF', price: 0 },
      EUR: { code: 'EUR', price: 0 },
      GBP: { code: 'GBP', price: 0 },
      PLN: { code: 'PLN', price: 0 },
      RON: { code: 'RON', price: 0 },
      SEK: { code: 'SEK', price: 0 },
      USD: { code: 'USD', price: 0 },
    },
    productPerks: [],
  });

  const currentOffer = currentProductName
    ? offers[currentProductName]
    : freeOffer;

  // Redirects to Benefits page when user has plan but it is old plan
  if (!currentOffer) {
    return <Navigate to={generatePath(PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE)} />;
  }

  const proOffer = getOfferByTier(offers, 'pro');
  const smartOffer = getOfferByTier(offers, 'smart');

  const getOffersToCompare = () => {
    switch (true) {
      case isFreeAccount:
        return [freeOffer, smartOffer, proOffer];
      case isProAnnualMembership(currentProductName):
        return [smartOffer, proOffer];
      case isProSingleMembership(currentProductName):
        return [smartOffer, proOffer];
      default:
        return [currentOffer, ...upgradeOffers];
    }
  };

  const compareOffers = getOffersToCompare();

  return (
    <Box width="100%">
      <PlanComparison
        currentOffer={currentOffer}
        compareOffers={compareOffers}
        isFreeAccount={isFreeAccount}
        isProMembership={isProMembership}
      />
    </Box>
  );
};

export default PlanManagementView;
