import { AuthenticationRequiredError } from '@airhelp/session';
import { type HTTPClientError, isHTTPClientError } from '@airhelp/http-client';
import config from 'config';
import { convertDomainUrl } from 'utils/domains';

export const regionalAuthUrl = convertDomainUrl(
  window.location.origin,
  config.authUrl,
);

const SSO_AUTH_URL = `${regionalAuthUrl}/api/${config.authApiVersion}/auth`;

export enum SSO_PROVIDER {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const oAuthLink = (provider: SSO_PROVIDER) => {
  const returnUrl = new URL(window.location.href);
  return `${SSO_AUTH_URL}/${provider}?return_url=${encodeURIComponent(returnUrl.toString())}`;
};

export function isAuthError(
  error: unknown,
): error is AuthenticationRequiredError | HTTPClientError {
  return (
    error instanceof AuthenticationRequiredError ||
    (isHTTPClientError(error) && error.status === 401)
  );
}

export function isNotFoundError(error: unknown): error is HTTPClientError {
  return isHTTPClientError(error) && error.response.status === 404;
}
