import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';
import { useTranslation } from 'react-i18next';
import { PAGE_PATHS } from 'config/routes';
import ChipGroup from 'elements/ChipGroup/ChipGroup';
import { useTrackEvent } from 'utils/tracking/hooks';

enum ClaimTabs {
  PROGRESS = 'progress',
  COMPENSATION = 'compensation',
  DOCUMENTS = 'documents',
  DETAILS = 'details',
  HISTORY = 'email history',
}

interface NavigationItem {
  eventName?: string;
  key: string;
  label: string;
  isActive: boolean;
  path: string;
  showNotification: boolean;
}

interface NavigationProps {
  showDocumentNotification: boolean;
}

export function Navigation({ showDocumentNotification }: NavigationProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const navigate = useNavigate();
  const { id: claimId } = useParams();
  const tabs: NavigationItem[] = [
    {
      key: ClaimTabs.PROGRESS,
      label: t('claims.layout.progress'),
      isActive: location.pathname.endsWith(`${claimId}`),
      path: PAGE_PATHS.CLAIM.INDEX_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.COMPENSATION,
      label: t('claims.layout.compensation'),
      isActive: location.pathname.includes(ClaimTabs.COMPENSATION),
      path: PAGE_PATHS.CLAIM.COMPENSATION_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.DOCUMENTS,
      label: t('claims.layout.documents'),
      isActive: location.pathname.includes(ClaimTabs.DOCUMENTS),
      path: PAGE_PATHS.CLAIM.DOCUMENTS_PAGE,
      showNotification: showDocumentNotification,
    },
    {
      key: ClaimTabs.DETAILS,
      label: t('claims.layout.details'),
      isActive: location.pathname.includes(ClaimTabs.DETAILS),
      path: PAGE_PATHS.CLAIM.DETAILS_PAGE,
      showNotification: false,
    },
    {
      key: ClaimTabs.HISTORY,
      label: t('claims.layout.history'),
      isActive: location.pathname.includes('history'),
      path: PAGE_PATHS.CLAIM.HISTORY_PAGE,
      showNotification: false,
    },
  ];

  const onClick = (path: string, param: string) => {
    trackPageInteractions('clicked', param, 'claim details page');
    navigate(
      generatePath(path, {
        id: claimId,
      }),
    );
  };

  return (
    <ChipGroup
      maxWidth="calc(100% + 32px)"
      items={tabs}
      onItemClick={(item) => {
        onClick(item.path, item.key);
      }}
      dataTestId="claim-navigation"
    />
  );
}
