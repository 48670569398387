import React from 'react';
import { generatePath, Navigate, Outlet, useSearchParams } from 'react-router';
import { track } from 'react-tracking';
import CheckoutLayout from 'components/Layouts/CheckoutLayout';
import TripToCompleteDetailsView from 'components/Upsell/Trip/TripToCompleteDetailsView';
import { JOURNEY_DETAILS_PAGE_UPGRADE, UNKNOWN_CHANNEL } from 'config/channels';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePreferencesStore } from 'stores/preferences';
import { usePageTracking } from 'utils/tracking';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

const TripsPage = function () {
  usePageTracking();
  const [searchParams] = useSearchParams();
  const channel = searchParams.get('channel') || UNKNOWN_CHANNEL;
  const currency = usePreferencesStore((store) => store.currency);

  const { data: userProfile } = useFetchMyProfile();

  const {
    isLoading: isLoadingOffers,
    isError: isErrorOffers,
    data: offersData,
  } = useComputedOffers({
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });

  if (!userProfile || isLoadingOffers) {
    return <PageLoader />;
  }

  if (userProfile?.policy.trip?.isComplete) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  if (isErrorOffers) {
    return <Navigate to={generatePath(PAGE_PATHS.ERROR_PAGE)} />;
  }

  if (!offersData) {
    return null;
  }

  const offers = offersData.offers;

  return (
    <CheckoutLayout
      side={<TripToCompleteDetailsView offers={offers} />}
      showCurrencySwitcher={channel === JOURNEY_DETAILS_PAGE_UPGRADE}
      currency={currency}
    >
      <Outlet />
    </CheckoutLayout>
  );
};

export default track({ page_type: 'Upsell.Trips' })(TripsPage);
