import { useLocation, useRouteError } from 'react-router';
import { useEffect } from 'react';
import { AuthenticationRequiredError } from '@airhelp/session';
import * as Sentry from '@sentry/react';
import { isAuthError, isNotFoundError } from 'api/http/http';
import { PAGE_PATHS } from 'config/routes';
import { Redirect } from './Redirect';

export function DefaultErrorBoundary() {
  const { error, isErrorHandled, redirect } = useErrorAction();
  const location = useLocation();

  useEffect(() => {
    if (!isErrorHandled) {
      Sentry.withScope((scope) => {
        scope.addBreadcrumb({
          level: 'error',
          message: `An uncaught error occurred at path: ${location.pathname}`,
        });
        scope.captureException(error);
      });
    }
  }, [error, isErrorHandled, location]);

  return <Redirect to={redirect} />;
}

function useErrorAction() {
  const error = useRouteError();

  if (isAuthError(error)) {
    if (error instanceof AuthenticationRequiredError) {
      return {
        redirect: PAGE_PATHS.LOGIN_PAGE,
        error,
        isErrorHandled: true,
      };
    }
    return {
      redirect: PAGE_PATHS.SIGNUP_PAGE,
      error,
      isErrorHandled: true,
    };
  }

  if (isNotFoundError(error)) {
    return {
      redirect: PAGE_PATHS.NOT_FOUND_PAGE,
      error,
      isErrorHandled: true,
    };
  }

  return {
    redirect: PAGE_PATHS.ERROR_PAGE,
    error,
    isErrorHandled: false,
  };
}
