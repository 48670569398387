import React from 'react';
import { Outlet } from 'react-router';
import BaseLayout from 'components/Layouts';

const DefaultPageRouteLayout = () => {
  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export default DefaultPageRouteLayout;
