import React, {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import cookie from 'react-cookies';
import config from 'config';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { sentryUtils } from 'utils';
import { createCookieOptions } from 'utils/cookies';
import { getProductTier, getProductVariant } from './productInfo';

const { zowieChat: zowieChatConfig } = config;
const CHAT_COOKIE_NAME = 'should_open_chat';

interface ZowieContextInterface {
  openZowieChat: () => void;
}

declare const Chatbotize: {
  init: (arg: ChatbotizeInitOptions) => void;
  logout: () => void;
  hide: () => void;
  show: () => void;
  open: () => void;
  close: () => void;
  updateMetadata: (updateMetadata: ChatbotizeMetaData) => void;
};

interface ChatbotizeMetaData {
  locale?: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  email: string;
  extraParams?: {
    currentPlan?: string;
    productVariant?: string;
  };
}

interface ChatbotizePosition {
  desktopBottom: number;
  desktopRight: number;
  mobileBottom: number;
  mobileRight: number;
  mobileOffset: number;
}

interface ChatbotizeInitOptions {
  instanceId: string;
  showOnLoad: boolean;
  startOnOpen: boolean;
  allowEndChat: boolean;
  headerMode: string;
  position: ChatbotizePosition;
  metadata: ChatbotizeMetaData;
  onEndChat: () => void;
  onLoaded: () => void;
}

export const ZowieContext = createContext<ZowieContextInterface>({
  openZowieChat: () => null,
});

const ZowieContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const triggerOpenChat =
    cookie.load(CHAT_COOKIE_NAME) || urlParams.get('chat');
  if (triggerOpenChat) {
    cookie.save(
      CHAT_COOKIE_NAME,
      triggerOpenChat,
      createCookieOptions({ maxAge: 300 }),
    );
  }

  const { userProfile } = useMyProfile();
  const { locale } = useLocale();
  const [isInitialized, setIsInitialized] = useState(false);
  const [pendingChatOpen, setPendingChatOpen] = useState(false);

  const chatbotizeContainer = document.getElementById('chatbotize');

  const initializeZowieChat = () => {
    if (!zowieChatConfig.enabled || !userProfile) {
      return;
    }

    const productVariant = getProductVariant(userProfile);
    const productTier = getProductTier(userProfile);
    const chatbotizeAvailable = typeof Chatbotize !== 'undefined';

    try {
      if (chatbotizeContainer && chatbotizeAvailable) {
        Chatbotize.init({
          instanceId: zowieChatConfig.instanceIdAirhelp,
          showOnLoad: false,
          startOnOpen: true,
          allowEndChat: true,
          headerMode: 'white',
          position: {
            desktopBottom: 80,
            desktopRight: 32,
            mobileBottom: 126,
            mobileRight: 32,
            mobileOffset: 992,
          },
          metadata: {
            locale,
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            phoneNumber: userProfile.phoneNumber,
            email: userProfile.email,
            extraParams: {
              currentPlan: productTier,
              productVariant,
            },
          },

          onLoaded: () => {
            setIsInitialized(true);
          },
          onEndChat: () => {
            Chatbotize.logout();
            Chatbotize.hide();
          },
        });
      }
    } catch (error) {
      sentryUtils.captureException(error);
    }
  };

  const openZowieChat = () => {
    if (!isInitialized) {
      setPendingChatOpen(true);
      initializeZowieChat();
    }

    if (typeof Chatbotize === 'undefined') {
      config.isDevelopment &&
        // eslint-disable-next-line no-console -- Debug info in dev before error for the case when Chatbotize is not initialized
        console.warn(
          'Chatbotize is not initialized, probably because it is disabled, please check environment settings',
        );
      return;
    }

    Chatbotize.show();

    Chatbotize.open();
  };

  useEffect(() => {
    if (isInitialized && pendingChatOpen) {
      Chatbotize.show();
      Chatbotize.open();
      setPendingChatOpen(false);
    }
  }, [isInitialized, pendingChatOpen]);

  useEffect(() => {
    if (!isInitialized) {
      initializeZowieChat();
    }
  }, [userProfile]);

  // Logout from chatbotize when user logs out to initialize widget with chosen language
  useEffect(() => {
    if (isInitialized && Chatbotize) {
      Chatbotize.logout();
    }
    initializeZowieChat();
  }, [locale]);

  return (
    <ZowieContext.Provider value={{ openZowieChat }}>
      {children}
    </ZowieContext.Provider>
  );
};

export const useZowie = () => {
  return useContext(ZowieContext);
};

export default ZowieContextProvider;
