import React from 'react';
import { Trans } from 'react-i18next';
import { AltArrowForward } from '@airhelp/icons';
import { HStack, Image, Text } from '@chakra-ui/react';
import spotLoungeImage from 'assets/images/lounges/spot-lounge@2x.png';
import { Card } from 'elements';
import { type LoungePass } from 'models/LoungePass';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';

export enum LOUNGE_EXPERIENCE_CARD_VARIANT {
  DASHBOARD_PAGE = 'dashboard_page',
  LOUNGES_PAGE = 'lounges_page',
}

interface IComponent {
  loungePasses: LoungePass[];
  handleClick: () => void;
}

const LoungeExperienceTile: React.FC<IComponent> = ({
  loungePasses,
  handleClick,
}) => {
  const { locale } = useLocale();
  const loungesCount = loungePasses.length;

  return (
    <Card
      onClick={handleClick}
      py={0}
      px={5}
      width={{ base: '100%', lg: '376px' }}
      data-testid="lounge-experience-tile"
    >
      <HStack justifyContent="space-between">
        <Text fontSize="md" fontWeight="medium">
          <Trans
            i18nKey="lounges.your_available_lounge_passes"
            values={{ count: loungesCount }}
            components={{
              strong: (
                <Text
                  as="span"
                  fontSize="md"
                  fontWeight="medium"
                  color="danger.500"
                  display="inline"
                />
              ),
            }}
          />
        </Text>
        <HStack flexShrink={0}>
          <Image
            src={spotLoungeImage}
            alt=""
            w="128px"
            height="128px"
            position="relative"
            top={2}
          />
          <AltArrowForward
            color="primary.500"
            boxSize={6}
            transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
          />
        </HStack>
      </HStack>
    </Card>
  );
};

export default LoungeExperienceTile;
