import { createContext, type RefObject, useContext } from 'react';
import { Portal } from '@chakra-ui/react';

const SideNavContext = createContext({
  portalRef: undefined as RefObject<HTMLDivElement | null> | undefined,
});

const SideNavProvider = SideNavContext.Provider;

export function useSideNav() {
  const { portalRef } = useContext(SideNavContext);
  return {
    portalRef,
    NavigationBarPortal: ({ children }) => (
      <Portal containerRef={portalRef} appendToParentPortal={false}>
        {children}
      </Portal>
    ),
  };
}

export default SideNavProvider;
