import React from 'react';
import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import airhelpBrandSign from 'assets/images/branding-sign.svg';

const AirhelpBrandWithPlanName = ({
  label,
  description,
}: {
  label: string;
  description: string;
}) => {
  return (
    <Flex alignItems="center">
      <Image
        src={airhelpBrandSign}
        width="68px"
        height="68px"
        loading="lazy"
        alt=""
        p={4}
        me={6}
        boxShadow="md"
        borderRadius="xl"
      />
      <Box>
        <Text fontSize="sm" color="gray.700" mb={1}>
          {label}
        </Text>
        <Heading variant="h5" data-testid="airhelp-plan-name">
          {description}
        </Heading>
      </Box>
    </Flex>
  );
};
export default AirhelpBrandWithPlanName;
