export enum CLAIM_STATES {
  ACCEPTED_AFTER_LEGAL_ACTION = 'accepted_after_legal_action',
  ACCEPTED_BY_AIRHELP = 'accepted_by_airhelp',
  ACCEPTED_BY_AIRLINE = 'accepted_by_airline',
  ACCEPTED_LATE_BY_AIRLINE = 'accepted_late_by_airline',
  CLOSED = 'closed',
  COMPENSATION_COLLECTED = 'compensation_collected',
  FEE_COLLECTED_FROM_CUSTOMER = 'fee_collected_from_customer',
  LEGAL_ACTION_STARTED = 'legal_action_started',
  LEGAL_ACTION_TAKEN = 'legal_action_taken',
  LOST_AFTER_LEGAL_ACTION = 'lost_after_legal_action',
  LEGALLY_VIABLE = 'legally_viable',
  LEGALLY_VIABLE_ON_HOLD = 'legally_viable_on_hold',
  NEW = 'new',
  PAYOUT_APPROVED = 'payout_approved',
  READY_FOR_LEGAL_ACTION = 'ready_for_legal_action',
  READY_FOR_LEGAL_ASSESSMENT = 'ready_for_legal_assessment',
  REJECTED_AFTER_LEGAL_ASSESSMENT = 'rejected_after_legal_assessment',
  REJECTED_BY_AIRHELP = 'rejected_by_airhelp',
  REJECTED_BY_AIRLINE = 'rejected_by_airline',
  REPROCESSED = 'reprocessed',
  RIGHTFULLY_REJECTED_BY_AIRLINE = 'rightfully_rejected_by_airline',
  SENDING_TO_AIRLINE = 'sending_to_airline',
  SENT_TO_AIRLINE = 'sent_to_airline',
  SENT_TO_PAYOUT = 'sent_to_payout',
  SERVICE_FEE_COLLECTED = 'service_fee_collected',
  SPECIAL_CARE_REQUIRED = 'special_care_required',
  WRONGFULLY_REJECTED_BY_AIRLINE = 'wrongfully_rejected_by_airline',
}

export const CLAIM_REJECTED_STATES = new Set([
  CLAIM_STATES.CLOSED,
  CLAIM_STATES.LOST_AFTER_LEGAL_ACTION,
  CLAIM_STATES.REJECTED_AFTER_LEGAL_ASSESSMENT,
  CLAIM_STATES.REJECTED_BY_AIRHELP,
  CLAIM_STATES.REJECTED_BY_AIRLINE,
  CLAIM_STATES.RIGHTFULLY_REJECTED_BY_AIRLINE,
  CLAIM_STATES.WRONGFULLY_REJECTED_BY_AIRLINE,
]);

export enum ClaimSentiment {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
  WARNING = 'warning',
}
