import { Button, Flex, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SubmitDocument } from '@airhelp/icons';
import { useClaimDocuments } from '@airhelp/liftoff/react';
import { type FetchClaimDocumentsParams } from '@airhelp/liftoff';
import { useTrackEvent } from 'utils/tracking/hooks';
import { env } from '../../../config';
import { PassengerDocuments } from './PassengerDocuments';

export function Documents({ claimId }: FetchClaimDocumentsParams) {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { data, isLoading } = useClaimDocuments({ params: { claimId } });

  return (
    <Skeleton isLoaded={!isLoading}>
      <VStack gap={4}>
        {data?.map((record) => (
          <PassengerDocuments
            key={record.passenger.id}
            claimId={claimId}
            {...record}
          />
        ))}
        <HStack
          p={4}
          rounded="lg"
          w="full"
          justifyContent="space-between"
          backgroundColor="greyscale.100"
          mt={{
            base: 0,
            lg: 4,
          }}
        >
          <Flex justify="center" align="center" gap={4}>
            <Flex
              justify="center"
              align="center"
              w="40px"
              h="40px"
              rounded="full"
              backgroundColor="primary.100"
              me={2.5}
            >
              <SubmitDocument color="primary.400" />
            </Flex>

            <Text fontSize="md" color="primary.900" fontWeight="medium">
              {t('claims.documents.additional_documents.title')}
            </Text>
          </Flex>
          <Button
            size="sm"
            onClick={() => {
              trackCtaClick('add document', 'claim details page - documents');
              window.location.replace(
                env.VITE_EMBER_DROPZONE_REDIRECT_URL.replaceAll(
                  '{{claimId}}',
                  claimId.toString(),
                ),
              );
            }}
          >
            {t('claims.documents.additional_documents.action')}
          </Button>
        </HStack>
      </VStack>
    </Skeleton>
  );
}
