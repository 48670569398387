import React from 'react';
import { Navigate, generatePath, useParams } from 'react-router';
import { track } from 'react-tracking';
import InsuranceView from 'components/Journey/InsuranceView';
import { PAGE_PATHS } from 'config/routes';
import { PageLoader } from 'elements';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import useJourney from 'hooks/api/journey/useJourney';

interface Params extends Record<string, string | undefined> {
  id: string;
}

const InsurancePage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { id } = useParams<Params>();

  const {
    data: journeyData,
    isPending,
    isFetching,
    isError,
  } = useJourney({
    id: id ? parseInt(id) : 0,
  });

  if (isPending || isFetching) {
    return <PageLoader />;
  } else if (isError || !journeyData) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  const airPayoutProtected = journeyData.protections.airPayout.protected;
  const airLuggageProtected = journeyData.protections.airLuggage.protected;

  if (!airPayoutProtected && !airLuggageProtected) {
    return (
      <Navigate
        to={{ pathname: generatePath(PAGE_PATHS.JOURNEY.FLIGHTS_PAGE, { id }) }}
      />
    );
  }

  return <InsuranceView journey={journeyData} />;
};

export default track({ page_type: 'Journey.Insurance' })(InsurancePage);
