import React from 'react';
import { Flex, HStack, useBreakpointValue } from '@chakra-ui/react';
import { useParams } from 'react-router';
import ResponsiveLogoLink from 'components/Layouts/BaseLayout/ResponsiveLogoLink';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import LanguageSwitcher from 'elements/LanguageSwitcher';
import MobileActionBar from './MobileActionBar';
import UserAction from './UserAction';
import Breadcrumbs from './Breadcrumbs';

const TopNav: React.FC = () => {
  const { mobileHeaderConfig } = useLayout();
  const data = useParams();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const shouldDisplayMobileActionBar =
    isMobile && Boolean(mobileHeaderConfig?.title);

  const isOnClaimDetailsPage =
    data?.id && window.location.pathname.includes('claims');

  if (isMobile && isOnClaimDetailsPage) {
    return null;
  }

  if (shouldDisplayMobileActionBar) {
    return <MobileActionBar />;
  }

  return (
    <Flex
      width="100%"
      backgroundColor={{ base: 'greyscale.300', md: 'greyscale.100' }}
      height="72px"
      borderBottom={{ base: 'initial', md: '1px solid' }}
      borderBottomColor={{ base: 'initial', md: 'greyscale.400' }}
    >
      <Flex width="100%" height="100%" justifyContent="space-between">
        <ResponsiveLogoLink />
        <HStack alignItems="center" flex="1" px={{ base: 4, md: 8 }}>
          {isMobile ? null : <Breadcrumbs />}
          <HStack
            spacing={2}
            alignItems="center"
            width={{ base: '100%', md: 'initial' }}
            justifyContent="flex-end"
            flex="1"
          >
            <LanguageSwitcher />
            <UserAction />
          </HStack>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default TopNav;
