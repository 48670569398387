import { Expose, Type, plainToClass } from 'class-transformer';
import { type ProductName, TripTier, MembershipTier } from '@airhelp/plus';
import { Benefits } from './Benefits';
import { Coverage } from './Coverage';
import { Dashboard } from './Dashboard';
import { Perks } from './Perks';

class MembershipPolicy {
  @Expose() active: boolean;
  @Expose() autoRenewal: boolean;
  @Expose() canceled: boolean;
  @Expose() displayName: string;
  @Expose() endDate: string;
  @Expose() fromCustomer: boolean;
  @Expose() onGracePeriod: boolean;
  @Expose() productName: ProductName;
  @Expose() startDate: string;
  @Expose() subscriptionStartDate: string;
  @Expose() tier: MembershipTier;
  @Expose() paymentMethodAttached: boolean;

  @Expose()
  get isEssential(): boolean {
    return this.tier === MembershipTier.ESSENTIAL;
  }

  @Expose()
  get isComplete(): boolean {
    return this.tier === MembershipTier.COMPLETE;
  }

  @Expose()
  get showPaymentManagementPage(): boolean {
    return this.fromCustomer && this.active && !this.canceled;
  }

  @Expose()
  get isFreeAccount(): boolean {
    return this.tier === MembershipTier.FREE;
  }

  @Expose()
  get showPaymentManagementBanner(): boolean {
    return this.onGracePeriod || !this.paymentMethodAttached;
  }
}
class TripPolicy {
  @Expose() active: boolean;
  @Expose() partnerSlug: string;
  @Expose() tier: TripTier;

  @Expose()
  get isEssential(): boolean {
    return this.tier === TripTier.ESSENTIAL;
  }

  @Expose()
  get isComplete(): boolean {
    return this.tier === TripTier.COMPLETE;
  }
}

export class UserPolicy {
  @Expose()
  @Type(() => MembershipPolicy)
  membership: MembershipPolicy;

  @Type(() => MembershipPolicy)
  expiredMembership: MembershipPolicy | null;

  @Expose()
  @Type(() => TripPolicy)
  trip: TripPolicy | null;

  @Expose()
  @Type(() => Benefits)
  benefits: Benefits | null;

  @Expose()
  @Type(() => Perks)
  perks: Perks;

  @Expose()
  @Type(() => Coverage)
  coverage: Coverage;

  @Expose()
  @Type(() => Dashboard)
  dashboard: Dashboard;
}

export function transformToUserPolicyClass(userPolicy: unknown): UserPolicy {
  return plainToClass(UserPolicy, userPolicy);
}
