import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Text, Flex, Heading, HStack } from '@chakra-ui/react';
import {
  OFFER_NAME_FREE,
  DEFAULT_CURRENCY_PRICE_PLAN,
} from 'components/MyAccountView/PlanManagementView/plan-management-config';
import { type IPrice } from 'models/Offers';
import { formatValueToCurrency } from 'stores/preferences/currencies';
import {
  getDiscountedPrice,
  getDiscountPercentage,
} from 'components/MyAccountView/PlanManagementView/plan-management-common';
import DiscountOffBadge from '../DiscountOffBadge';

const DetailsHeaderMobile: React.FC<{
  name: string;
  priceDetails: IPrice;
  isFreeAccount: boolean;
  isCurrentPlan: boolean;
  isFeaturedPlan: boolean;
  isMostPopularPlan: boolean;
}> = ({
  name,
  priceDetails,
  isFreeAccount = false,
  isCurrentPlan = false,
  isFeaturedPlan = false,
  isMostPopularPlan = false,
}) => {
  const { t } = useTranslation();
  const isFreePlan = name === OFFER_NAME_FREE;
  const regularPrice = priceDetails.price;

  const regularPriceWithCurrency = formatValueToCurrency(
    regularPrice,
    DEFAULT_CURRENCY_PRICE_PLAN,
  );

  const discountedPrice = getDiscountedPrice(isFreeAccount, priceDetails);
  const discountPercentage = getDiscountPercentage(isFreeAccount, priceDetails);

  const discountedPriceWithCurrency = formatValueToCurrency(
    discountedPrice,
    DEFAULT_CURRENCY_PRICE_PLAN,
  );

  const showPrice = regularPriceWithCurrency && !isFreePlan;
  const showDiscountedInfo = !isFreePlan && Boolean(discountPercentage);

  return (
    <HStack
      height="70px"
      justifyContent="space-between"
      className="header"
      color={isFeaturedPlan ? 'primary.500' : 'primary.700'}
      gap={0}
      pb={1}
      zIndex="10"
    >
      <Flex
        direction="column"
        justifyContent={showPrice ? 'flex-end' : 'flex-start'}
        height="100%"
        width={isCurrentPlan ? '100%' : '55%'}
      >
        <Heading
          variant="h4"
          fontSize="2xl"
          fontWeight="700"
          textAlign="left"
          color={isFeaturedPlan ? 'primary.500' : 'primary.700'}
          pb={0}
          mb={0}
        >
          {name}
          {isCurrentPlan && !isFreePlan ? (
            <Text
              fontSize="xs"
              color="primary.700"
              textAlign="left"
              alignSelf="flex-start"
              as="span"
              ms={2}
            >
              ({t('my_account.my_benefits.plans_compared.current_plan')})
            </Text>
          ) : null}
        </Heading>
        {showPrice ? (
          <Box lineHeight="short">
            <Trans
              mt={0}
              pt={0}
              lineHeight="short"
              fontSize="xl"
              textAlign="center"
              i18nKey="my_account.my_benefits.plans_compared.price_per_year"
              values={{
                price: discountedPriceWithCurrency,
              }}
              components={{
                span: (
                  <Text
                    display="inline-block"
                    color={isFeaturedPlan ? 'primary.500' : 'primary.700'}
                    fontSize="xl"
                    lineHeight="short"
                    fontWeight="700"
                    as="span"
                  />
                ),
              }}
            />
          </Box>
        ) : null}
      </Flex>
      <Flex
        height="100%"
        maxWidth="45%"
        display={isMostPopularPlan || showDiscountedInfo ? 'flex' : 'none'}
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
        className="regular-price"
      >
        {isMostPopularPlan ? (
          <Flex
            borderRadius="lg"
            color="greyscale.100"
            backgroundColor="success.500"
            textTransform="uppercase"
            fontWeight="medium"
            fontSize="10px"
            alignItems="center"
            py={1}
            px={2}
          >
            {t('my_account.my_benefits.plans_compared.most_popular')}
          </Flex>
        ) : null}
        {showDiscountedInfo ? (
          <Flex
            fontSize="md"
            color="primary.700"
            justifyContent="flex-end"
            alignItems="center"
            mb={1}
            mt={2}
          >
            <Box
              textDecoration="line-through"
              me={2}
              color="greyscale.600"
              fontSize="xs"
              fontWeight="500"
              lineHeight="short"
              whiteSpace="nowrap"
            >
              {regularPriceWithCurrency}
            </Box>
            <DiscountOffBadge
              text={t('my_account.my_benefits.plans_compared.discount', {
                value: discountPercentage,
              })}
            />
          </Flex>
        ) : null}
      </Flex>
    </HStack>
  );
};

export default DetailsHeaderMobile;
