import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Airhelp, AirHelpLogoIcon, AirhelpPlus, Plus } from '@airhelp/logos';
import useLocalStorage from 'hooks/useLocalStorage';
import type { UserProfile } from 'models/userProfile/UserProfile';

const getLogoProps = (width: number, height: number) => ({
  width: `${width}px !important`,
  height: `${height}px !important`,
});

const ActiveAhLogo = () => {
  const [layoutCollapse] = useLocalStorage('layout-collapsed');

  return (
    <Flex
      position="relative"
      justifyContent="flex-start"
      alignContent="center"
      width={{ base: '28px', md: layoutCollapse ? '32px' : '110px' }}
      height={{ base: '28px', md: '32px' }}
      data-testid="airhelp-logo"
    >
      <AirhelpPlus
        opacity={{ md: layoutCollapse ? 0 : 1 }}
        transition="opacity 300ms ease-in-out"
        position="absolute"
        top="0"
        left="0"
        display={{ base: 'block', md: 'none', lg: 'block' }}
        {...getLogoProps(110, 32)}
      />
      <Plus
        opacity={{ lg: layoutCollapse ? 1 : 0 }}
        transition="opacity 300ms ease-in-out"
        position="absolute"
        top="0"
        left="0"
        display={{ base: 'none', md: 'block' }}
        {...getLogoProps(28, 28)}
      />
    </Flex>
  );
};

const InActiveAhLogo = () => {
  const [layoutCollapse] = useLocalStorage('layout-collapsed');

  return (
    <Flex
      position="relative"
      justifyContent="flex-start"
      alignContent="center"
      width={{ base: '28px', md: layoutCollapse ? '32px' : '95px' }}
      height="32px"
      data-testid="airhelp-logo"
    >
      <Airhelp
        opacity={{ md: layoutCollapse ? 0 : 1 }}
        transition="opacity 300ms ease-in-out"
        position="absolute"
        top="0"
        left="0"
        display={{ base: 'block', md: 'none', lg: 'block' }}
        {...getLogoProps(95, 32)}
      />
      <AirHelpLogoIcon
        opacity={{ lg: layoutCollapse ? 1 : 0 }}
        transition="opacity 300ms ease-in-out"
        position="absolute"
        top="0"
        left="0"
        display={{ base: 'none', md: 'block' }}
        {...getLogoProps(28, 28)}
      />
    </Flex>
  );
};

const AirHelpHeaderLogo = ({ userProfile }: { userProfile?: UserProfile }) => {
  const isActive = userProfile?.policy.coverage?.active;

  if (isActive) {
    return <ActiveAhLogo />;
  }
  return <InActiveAhLogo />;
};

export default AirHelpHeaderLogo;
