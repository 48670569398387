import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { generatePath, Link as ReactLink } from 'react-router';
import { useTracking } from 'react-tracking';
import { type ProductName } from '@airhelp/plus';
import { ArrowLeft } from '@airhelp/icons';
import { PAGE_PATHS } from 'config/routes';
import { type Offer } from 'models/Offers';
import {
  getPlanByProductName,
  getUpgradeOfferByTier,
  isProAnnualMembership,
  isSmartAnnualMembership,
  isLiteAnnualMembership,
} from 'components/Membership/membershipHelper';
import { useTrackEvent } from 'utils/tracking/hooks';
import {
  EcommerceEvent,
  type TrackEcommerceEvent,
} from 'utils/tracking/props/ga4/types';
import {
  OFFER_NAME_FREE,
  OFFER_NAME_LITE,
  OFFER_NAME_SMART,
  DEFAULT_CURRENCY_PRICE_PLAN,
  OFFER_NAME_PRO,
} from '../plan-management-config';
import {
  shouldFeaturePlan,
  getDiscountedPrice,
  getSearchParamsForButton,
  isEntitledToShowBuyPlan,
} from '../plan-management-common';
import PlanDetails from './PlanDetails';
import PlanDetailsLabels from './PlanDetailsLables';
import styles from './Slider.module.scss';

const PlanComparison: React.FC<{
  isFreeAccount: boolean;
  isProMembership: boolean;
  currentOffer: Offer;
  compareOffers: Offer[];
}> = ({ isFreeAccount, isProMembership, currentOffer, compareOffers }) => {
  const { t } = useTranslation();
  const { trackUpsellOption } = useTrackEvent();
  const tracking = useTracking<TrackEcommerceEvent>();
  const sliderRef = useRef<Slider>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const currentShortPlanName = getPlanByProductName({
    productName: currentOffer.name,
  });

  const isFreeCurrentPlan = currentShortPlanName === OFFER_NAME_FREE;
  const isLiteCurrentPlan = currentShortPlanName === OFFER_NAME_LITE;

  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [lastSlideSelected, setLastSlideSelected] = useState<boolean>(false);
  const [slidingSlide, setSlidingSlide] = useState<boolean>(false);

  const hasOnlyOneOffer = compareOffers.length === 1;

  const showBuyPlanButton = isEntitledToShowBuyPlan(
    currentShortPlanName,
    selectedPlan,
  );

  const ecommerceItems = compareOffers
    .filter((offer) => (offer.name as string) !== OFFER_NAME_FREE)
    .map((offer, index) => {
      const priceDetails = offer.prices[DEFAULT_CURRENCY_PRICE_PLAN];
      const discountedPrice = getDiscountedPrice(isFreeAccount, priceDetails);
      const discountValue = parseFloat(
        (priceDetails.price - discountedPrice).toString(),
      ).toFixed(2);

      const variantName = (productName: ProductName) => {
        switch (true) {
          case productName && isProAnnualMembership(productName):
            return OFFER_NAME_PRO.toLowerCase();
          case productName && isSmartAnnualMembership(productName):
            return OFFER_NAME_SMART.toLowerCase();
          case productName && isLiteAnnualMembership(productName):
            return OFFER_NAME_LITE.toLowerCase();
          default:
            return OFFER_NAME_FREE.toLowerCase();
        }
      };

      return {
        item_id: offer.name,
        item_name: 'AirHelpPlus Membership',
        discount: discountValue ?? 0,
        index: index + 1,
        item_brand: 'AirHelp',
        item_category: '1YearMembership',
        item_category2: 'Subscription',
        item_list_name: 'ahp_dashboard_lp',
        item_variant: variantName(offer.name),
        price: priceDetails.price.toString(),
        quantity: 1,
      };
    });

  const sliderSettings = {
    dots: !hasOnlyOneOffer,
    initialSlide: isProMembership ? 1 : 0,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false,
    dotsClass: [styles.dots],
    onInit: () => {
      setSelectedPlan(null);
    },
    beforeChange: (_: number, newIndex: number) => {
      if (sliderRef.current) {
        setSlidingSlide(true);

        const sliderList = sliderRef.current.innerSlider.list.children[0]
          .children as HTMLCollectionOf<HTMLElement>;

        setLastSlideSelected(newIndex === sliderList.length - 1);
      }
    },
    afterChange: (index: number) => {
      if (sliderRef.current) {
        setSlidingSlide(false);
        const sliderList = sliderRef.current.innerSlider.list.children[0]
          .children as Slider[];
        const currentSlide = sliderList[index];
        const selectedSlidePlanName = currentSlide
          .querySelector('div[data-plan]')
          .getAttribute('data-plan') as string;

        setSelectedPlan(
          (isFreeCurrentPlan && selectedSlidePlanName === OFFER_NAME_FREE) ||
            (isLiteCurrentPlan && selectedSlidePlanName === OFFER_NAME_LITE)
            ? OFFER_NAME_SMART
            : selectedSlidePlanName,
        );
      }
    },
  };

  const onUpgradeClick = () => {
    if (isFreeAccount && selectedPlan) {
      const selectedOffer = getUpgradeOfferByTier(
        compareOffers,
        selectedPlan.toLowerCase(),
      );
      const priceDetails = selectedOffer?.prices[DEFAULT_CURRENCY_PRICE_PLAN];

      if (priceDetails) {
        const discountedPrice = getDiscountedPrice(isFreeAccount, priceDetails);
        const discountValue = parseFloat(
          (priceDetails.price - discountedPrice).toString(),
        ).toFixed(2);

        const ecommerceItem = {
          item_id: selectedOffer.name,
          item_name: 'AirHelpPlus Membership',
          discount: discountValue ?? 0,
          index: 1,
          item_brand: 'AirHelp',
          item_category: '1YearMembership',
          item_category2: 'Subscription',
          item_list_name: 'ahp_dashboard_lp',
          item_variant: selectedPlan.toLowerCase(),
          price: priceDetails?.price.toString(),
          quantity: 1,
        };

        tracking.trackEvent({
          event: EcommerceEvent.SELECT_ITEM,
          ecommerce: {
            currency: DEFAULT_CURRENCY_PRICE_PLAN,
            item_list_name: 'ahp_dashboard_lp',
            items: [ecommerceItem],
          },
        });
      }
    }

    if (!isFreeAccount && selectedPlan) {
      trackUpsellOption(`select plan ${selectedPlan}`, 'ahp plans&benefits');
    }
  };

  useEffect(() => {
    if (isMobile && sliderRef.current) {
      setLastSlideSelected(false);
      setSelectedPlan(
        (isFreeCurrentPlan || isLiteCurrentPlan) && !selectedPlan
          ? OFFER_NAME_SMART
          : selectedPlan,
      );
    }
  }, [isMobile, sliderRef.current]);

  useEffect(() => {
    if (isFreeAccount) {
      tracking.trackEvent({
        event: EcommerceEvent.VIEW_ITEM,
        ecommerce: {
          currency: DEFAULT_CURRENCY_PRICE_PLAN,
          item_list_name: 'ahp_dashboard_lp',
          items: [...ecommerceItems],
        },
      });
    }
  }, [isFreeAccount]);

  return (
    <Box
      width="100%"
      position="relative"
      data-testid="plan-comparison-conatiner"
    >
      <Heading
        variant="h3"
        mb={8}
        mt={6}
        fontSize={{ base: '20px', md: '22px' }}
        display={{ base: 'none', md: 'block' }}
      >
        <IconButton
          as={ReactLink}
          to={{
            pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.BENEFITS_PAGE),
          }}
          icon={<ArrowLeft fontSize="xl" />}
          isRound
          size="lg"
          me={3}
          bgColor="greyscale.100"
          borderWidth="1px"
          borderColor="greyscale.400"
          color="primary.500"
          aria-label="pick your plan"
          data-testid="btn-pick-your-plan"
          _hover={{ bgColor: 'primary.500', color: 'greyscale.100' }}
        />
        {compareOffers.length === 0
          ? t('my_account.my_benefits.plans_compared.airhelp_plan', {
              planName: currentOffer.displayName,
            })
          : t('my_account.my_benefits.plans_compared.pick_your_plan')}
      </Heading>
      {isMobile ? (
        <Box
          borderRadius="lg"
          borderTopRightRadius={
            lastSlideSelected || hasOnlyOneOffer ? 'usnet' : '0'
          }
          borderBottomRightRadius={
            lastSlideSelected || hasOnlyOneOffer ? 'usnet' : '0'
          }
          background="greyscale.100"
          pt={2}
          pb={hasOnlyOneOffer ? 6 : 14}
          mb={{ base: '90px', md: '120px' }}
          overflow="hidden"
          width={
            lastSlideSelected
              ? 'calc((100vw + 188px) * 0.56)'
              : hasOnlyOneOffer
                ? '100%'
                : 'calc(100% + 16px)'
          }
          className="slider-container"
          transition="width 500ms"
        >
          <Box
            width="166px"
            position="absolute"
            top="86px"
            left="0"
            background="greyscale.100"
            borderTop="1px solid"
            borderTopColor="greyscale.400"
            borderBottom="1px solid"
            borderBottomColor="greyscale.400"
            borderRight="1px solid"
            borderRightColor="greyscale.400"
            pt={2}
            pb={2}
            zIndex={slidingSlide ? 1 : 0}
          >
            <PlanDetailsLabels />
          </Box>
          <Box
            width={
              hasOnlyOneOffer
                ? 'calc(100% - 197px)'
                : 'calc((100vw - 165px) * 0.56)'
            }
            height="100%"
            ms="165px"
            data-testid="plans-comparison-slider-container"
            sx={{
              '.slick-list': {
                margin: '0 -16px 0 0',
                overflow: 'unset',
                zIndex: slidingSlide ? 0 : 1,
              },
              '.slick-track': {
                display: 'flex',
              },
              '.slick-current .container': {
                opacity: 1,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: '14px !important',
                borderBottomRightRadius: '14px !important',
                padding: '1px',
                '> div': {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  borderTopRightRadius: '13px !important',
                  borderBottomRightRadius: '13px !important',
                },
              },
              '.slick-slide.slick-current': {
                opacity: 1,
                '.header': {
                  opacity: 1,
                  width: 'calc(100% + 144px)',
                  ms: '-144px',
                },
              },
              '.slick-slide.slick-active': {
                zIndex: 1,
              },
              '.slick-slide.slick-current + .slick-slide': {
                opacity: 0.4,
                '.header': {
                  opacity: 0.4,
                  paddingStart: '16px',
                  overflow: 'hidden',
                  textWrap: 'nowrap',
                  width: '100%',
                  '.regular-price': {
                    display: 'none',
                  },
                },
                '.container': {
                  boxShadow: 'none',
                },
              },
              '.slick-slide:not(.slick-current)': {
                opacity: 0,
                '.header': {
                  ms: 0,
                  me: 0,
                },
              },
            }}
          >
            <Slider ref={sliderRef} {...sliderSettings}>
              {compareOffers?.map((offer) => (
                <PlanDetails
                  offer={offer}
                  currentShortPlanName={currentShortPlanName}
                  isFreeAccount={isFreeAccount}
                  isProMembership={isProMembership}
                  isCurrentPlan={
                    currentShortPlanName ===
                    getPlanByProductName({ productName: offer.name })
                  }
                  isFeaturedPlan={shouldFeaturePlan(
                    currentOffer.name,
                    offer.name,
                  )}
                  onTouchStart={() => {
                    setSlidingSlide(true);
                  }}
                  onTouchEnd={() => {
                    setSlidingSlide(false);
                  }}
                  key={offer.name}
                />
              ))}
            </Slider>
          </Box>
          {showBuyPlanButton ? (
            <Flex
              width="100%"
              minHeight="80px"
              position="fixed"
              bottom={0}
              left="0"
              right="0"
              px={4}
              pb={3}
              pt={3}
              background="greyscale.300"
              justifyContent="flex-end"
              alignItems="center"
              zIndex="1"
              boxShadow="0px -8px 12px -4px #1E293614; 0px -2px 4px -2px #1E29360A"
            >
              <Button
                as={ReactLink}
                variant="primary"
                size="xs"
                width="100%"
                height="40px"
                textTransform="capitalize"
                onClick={onUpgradeClick}
                to={{
                  pathname: generatePath(
                    PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE,
                  ),
                  search: getSearchParamsForButton(
                    currentShortPlanName,
                    selectedPlan,
                  ),
                }}
                data-testid="btn-get-plan"
              >
                {t('my_account.my_benefits.plans_compared.get_plan', {
                  planName: selectedPlan,
                })}
              </Button>
            </Flex>
          ) : null}
        </Box>
      ) : (
        <HStack
          width="100%"
          pt={2}
          px={{ base: 4, md: 6 }}
          borderRadius="lg"
          background="greyscale.100"
          alignItems="stretch"
        >
          <PlanDetailsLabels />
          <HStack
            overflowY={{ base: 'auto', md: 'unset' }}
            width="100%"
            ms={0}
            pt={2}
            pb={6}
            alignItems="stretch"
          >
            {compareOffers?.map((offer) => (
              <PlanDetails
                offer={offer}
                currentShortPlanName={currentShortPlanName}
                isFreeAccount={isFreeAccount}
                isProMembership={isProMembership}
                isCurrentPlan={
                  currentShortPlanName ===
                  getPlanByProductName({ productName: offer.name })
                }
                isFeaturedPlan={shouldFeaturePlan(
                  currentOffer.name,
                  offer.name,
                )}
                key={offer.name}
              />
            ))}
          </HStack>
        </HStack>
      )}
    </Box>
  );
};

export default PlanComparison;
