import { Fade } from '@chakra-ui/react';
import { type FetchClaimDocumentsParams } from '@airhelp/liftoff';
import { useTranslation } from 'react-i18next';
import CallToAction, { CallToActionLinkButton } from '../../CallToAction';
import { useTrackEvent } from '../../../utils/tracking/hooks';
import { useIsDocumentRequestOpen } from './useIsDocumentRequestOpen';
import { useDocumentRequestRedirectLink } from './useDocumentRequestRedirectLink';

export function OpenDocumentRequestAlert(props: FetchClaimDocumentsParams) {
  const { isOpen } = useIsDocumentRequestOpen(props);
  const { link } = useDocumentRequestRedirectLink(props);
  const { trackCtaClick } = useTrackEvent();
  const { t } = useTranslation();

  if (!isOpen) {
    return null;
  }

  return (
    <Fade in={isOpen}>
      <CallToAction
        variant="warning"
        actions={
          <CallToActionLinkButton
            onClick={() => {
              trackCtaClick('add document', 'alert modal');
            }}
            href={link}
          >
            {t('claims.documents.missing_documents.action')}
          </CallToActionLinkButton>
        }
      >
        {t('claims.documents.missing_documents.title')}
      </CallToAction>
    </Fade>
  );
}
