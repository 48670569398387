import React from 'react';
import cookie from 'react-cookies';
import { useTranslation } from 'react-i18next';
import { useBeginPasswordReset } from '@airhelp/auth/react';
import { type BeginPasswordResetParams } from '@airhelp/auth';
import { Ok } from '@airhelp/icons';
import {
  Button,
  Flex,
  Box,
  Text,
  Heading,
  useToast,
  useBreakpointValue,
} from '@chakra-ui/react';
import { USER_RETURN_AHPLUS_COOKIE } from 'config/cookies';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { ErrorBadge } from 'elements';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { createCookieOptions } from 'utils/cookies';
import { useTrackEvent } from 'utils/tracking/hooks';
import { getMappedLocale } from 'contexts/LocaleContext/locales';

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { userProfile } = useMyProfile();
  const { locale } = useLocale();
  const { trackCtaClick } = useTrackEvent();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const toast = useToast();

  const {
    mutate: authBeginResetPassword,
    isPending,
    isError,
    isSuccess,
  } = useBeginPasswordReset({
    onSuccess: () => {
      saveCookieUserReturnUrl();

      toast({
        title: t('my_account.details.password_reset_link_sent'),
        variant: 'success',
        duration: 4000,
        position: isMobile ? 'bottom' : 'top',
        isClosable: true,
        icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
      });
    },
  });

  const isDisabled = isSuccess || isError || isPending;

  const saveCookieUserReturnUrl = () => {
    cookie.save(USER_RETURN_AHPLUS_COOKIE, '1', createCookieOptions());
  };

  const resetPasswordUser = (data: BeginPasswordResetParams) => {
    const { email, languageCode } = data;
    authBeginResetPassword({ email, languageCode });
  };

  const handleChangePassword = () => {
    trackCtaClick('reset password', 'my account page');

    userProfile &&
      resetPasswordUser({
        email: userProfile.email,
        languageCode: getMappedLocale(locale),
      });
  };

  return (
    <Flex
      backgroundColor="greyscale.100"
      borderRadius="xl"
      flexDirection="column"
      justifyContent="space-between"
      overflow="hidden"
      mt={4}
      p={{ base: 4, md: 6 }}
      width="100%"
    >
      {isError ? (
        <Box mb={3}>
          <ErrorBadge data-testid="error-text" />
        </Box>
      ) : null}
      <Flex
        width="100%"
        flexDirection={{ base: 'column', md: 'row' }}
        gap={6}
        justifyContent="space-between"
        flexShrink="none"
      >
        <Box>
          <Heading variant="h5" mb={1}>
            {t('my_account.details.change_your_password')}
          </Heading>
          <Text fontSize="md" color="primary.700">
            {t('my_account.details.password_reset_link')}
          </Text>
        </Box>
        <Button
          size="m"
          textAlign="center"
          onClick={handleChangePassword}
          isDisabled={isDisabled}
          isLoading={isPending}
          width={{ base: '100%', md: 'auto' }}
          whiteSpace="nowrap"
          alignSelf="center"
          data-testid="change-password-button"
        >
          {t('common.change_password')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ChangePassword;
