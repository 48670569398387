import { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { useClaimSuspense } from '@airhelp/webapp/react';
import { Grid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react';
import { isCountryFlagCode, UNKNOWN_COUNTRY_CODE } from '@airhelp/flags';
import { useCampaign } from '@airhelp/coral/react';
import { useTranslation } from 'react-i18next';
import { GenerateBreadcrumbs, Loader } from 'elements';
import { useClaimState } from 'hooks/useClaimState';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import config from 'config';
import { useSideNav } from 'components/Layouts/BaseLayout/SideNav/SideNavProvider';
import { useTopNav } from 'components/Layouts/BaseLayout/TopNav/TopNavProvider';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTrackEvent } from 'utils/tracking/hooks';
import { type BreadcrumbItem } from 'elements/GenerateBreadcrumbs';
import { SupportBox } from '../SupportBox';
import ReferralCard from '../ReferralCard';
import CallToAction, { CallToActionLinkButton } from '../CallToAction';
import { EXTERNAL_ROUTES, PAGE_PATHS } from '../../config/routes';
import { ClaimOverview } from './Overview/Overview';
import { Header } from './Header/Header';
import { Navigation } from './Navigation/Navigation';
import { useIsDocumentRequestOpen } from './Documents/useIsDocumentRequestOpen';
import { OpenDocumentRequestAlert } from './Documents/OpenDocumentRequestAlert';

export function Layout() {
  const isLargeScreen = useBreakpointValue({ base: false, '2xl': true });
  const { id } = useParams();
  const { t } = useTranslation();
  const { data: claim } = useClaimSuspense({
    params: { id: Number(id) },
  });
  const { isOpen: isDocumentRequestOpen } = useIsDocumentRequestOpen({
    claimId: claim.id,
  });
  const { trackCtaClick } = useTrackEvent();
  const { isCompensationCollectible } = useClaimState(Number(id));

  const sideNav = useSideNav();
  const NavigationBarPortal = sideNav.NavigationBarPortal;
  const topNav = useTopNav();
  const TopBarPortal = topNav.TopBarPortal;

  const templateAreas = useBreakpointValue({
    base: `"header"
      "main-content"`,
    '2xl': `"header header"
      "main-content faq"`,
  });

  if (!claim) {
    return null;
  }

  const flight = claim.itinerary.flights.find((f) => f.disrupted);

  // TODO - decide what to do when there is no flight available
  if (!flight) {
    return null;
  }

  const { departureAirport, arrivalAirport } = flight;
  const rawDepartureAirportCountryCode =
    departureAirport.countryCode.toLowerCase();
  const rawArrivalAirportCountryCode = arrivalAirport.countryCode.toLowerCase();

  const departureAirportCountryCode = isCountryFlagCode(
    rawDepartureAirportCountryCode,
  )
    ? rawDepartureAirportCountryCode
    : UNKNOWN_COUNTRY_CODE;
  const arrivalAirportCountryCode = isCountryFlagCode(
    rawArrivalAirportCountryCode,
  )
    ? rawArrivalAirportCountryCode
    : UNKNOWN_COUNTRY_CODE;

  const claimsBreadcrumb: BreadcrumbItem[] = [
    { id: 1, path: PAGE_PATHS.HOME_PAGE, label: t('navigation.home') },
    { id: 2, path: PAGE_PATHS.CLAIMS_PAGE, label: t('navigation.claims') },
    { id: 3, label: `${t('claims.your_claim')}: ${claim.id}` },
  ];

  return (
    <>
      <Suspense fallback={<Loader dataTestId="claim-details-loader" />}>
        <Grid
          w="full"
          columnGap={8}
          templateAreas={templateAreas}
          autoRows="min-content"
          templateColumns={{
            base: 'minmax(100%, 632px)',
            '2xl': '785px 377px',
          }}
        >
          <GridItem
            area="header"
            mt={{
              base: 4,
              xl: 0,
            }}
            mb={{
              base: 4,
              xl: 0,
            }}
          >
            <Header title={t('claims.your_claim')} />
          </GridItem>
          <GridItem area="main-content">
            <VStack spacing={6} align="stretch">
              <ClaimOverview
                departureAirportCountryCode={departureAirportCountryCode}
                arrivalAirportCountryCode={arrivalAirportCountryCode}
                departureAirportName={departureAirport.name}
                arrivalAirportName={arrivalAirport.name}
                departureAirportCode={departureAirport.iata}
                arrivalAirportCode={arrivalAirport.iata}
                isPayedOut={Boolean(claim.claimPayout.collectedAt)}
                claimId={claim.id}
              />

              {isCompensationCollectible ? (
                <CallToAction
                  variant="success"
                  actions={
                    <CallToActionLinkButton
                      onClick={() => {
                        trackCtaClick('select payment method', 'alert modal');
                      }}
                      href={EXTERNAL_ROUTES.PAYOUT_FUNNEL.CLAIM_PAYOUT_URL(
                        claim.id,
                      )}
                      data-testid="claim-payout-cta"
                    >
                      {t('claims.layout.select_payment_method')}
                    </CallToActionLinkButton>
                  }
                >
                  {t('claims.layout.compensation_is_ready')}
                </CallToAction>
              ) : null}
              <OpenDocumentRequestAlert claimId={claim.id} />
              <VStack gap={4} align="stretch">
                <Navigation showDocumentNotification={isDocumentRequestOpen} />
                <Outlet />
              </VStack>
              <ClaimReferralCard variant="section" />
            </VStack>
          </GridItem>
          {isLargeScreen ? (
            <GridItem>
              <SupportBox data-testid="support-box" />
            </GridItem>
          ) : null}
        </Grid>
      </Suspense>
      <NavigationBarPortal>
        <ClaimReferralCard />
      </NavigationBarPortal>
      <TopBarPortal>
        <GenerateBreadcrumbs breadcrumbData={claimsBreadcrumb} />
      </TopBarPortal>
    </>
  );
}

export function ClaimReferralCard({
  variant = 'box',
}: {
  variant?: 'box' | 'section';
}) {
  const { locale } = useLocale();
  const { userProfile } = useMyProfile();
  const { t } = useTranslation();

  const { data } = useCampaign({
    params: { alias: config.coralCampaignAlias },
  });

  if (!userProfile) {
    return null;
  }

  return (
    <ReferralCard
      userEmail={userProfile.email}
      campaignId={data?.id}
      lang={locale}
      variant={variant}
      i18n={{
        header: t('referral_card.header'),
        subheader: t('referral_card.subheader'),
        modal: {
          header: t('referral_card.modal.header'),
          subheader: t('referral_card.modal.subheader'),
          actionText: t('referral_card.modal.action_button'),
          copiedActionText: t('referral_card.modal.after_action_copy'),
          readMore: t('referral_card.modal.read_more'),
          readMoreLink: config.pricingUrl.replace('{{locale}}', locale),
          errorMessage: t('errors.something_went_wrong'),
        },
      }}
    />
  );
}
