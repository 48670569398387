import React, { useMemo, type JSX } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { type BANK_INPUTS } from '@airhelp/plus';
import paymentsFieldsConfig, {
  defaultFieldsConfig,
} from 'utils/airBundle/paymentsFieldsConfig';
import {
  Aba,
  AccountNumber,
  BankName,
  BranchName,
  Iban,
  BicCode,
  Swift,
  RoutingNumber,
  BranchAgencyCode,
  ChineseId,
  BranchCode,
  Tax,
  BankAddress,
  Clabe,
  BankCode,
  BankCity,
  PhoneNumber,
  Province,
  IFSCCode,
  Nationality,
  SortCode,
  PaymentCode,
  Nuban,
  BankMfo,
  BSBCode,
  BinOrIin,
  KnpOrUpdc,
} from './Inputs';

const BankFields: React.FC<{ form: UseFormReturn; country: string }> = ({
  form,
  country,
}) => {
  const { t, i18n } = useTranslation();

  const fieldsComponentsList: { [K in BANK_INPUTS]?: JSX.Element } = {
    accountNumber: AccountNumber({ form, t, countryCode: country }),
    aba: Aba({ form, t }),
    swift: Swift({ form, t }),
    bankName: BankName({ form, t }),
    iban: Iban({ form, t, countryCode: country }),
    branchName: BranchName({ form, t }),
    bankCode: BankCode({ form, t, countryCode: country }),
    routingNumber: RoutingNumber({ form, t }),
    branchAgencyCode: BranchAgencyCode({ form, t }),
    branchCode: BranchCode({ form, t, countryCode: country }),
    taxId: Tax({ form, t, countryCode: country }),
    bankAddress: BankAddress({ form, t }),
    clabe: Clabe({ form, t }),
    bankCity: BankCity({ form, t, countryCode: country }),
    phoneNumber: PhoneNumber({ form, t }),
    chineseId: ChineseId({ form, t }),
    province: Province({ form, t }),
    ifscCode: IFSCCode({ form, t }),
    nationality: Nationality({ form, t, i18n }),
    bicCode: BicCode({ form, t }),
    sortCode: SortCode({ form, t }),
    paymentCode: PaymentCode({ form, t }),
    nuban: Nuban({ form, t }),
    bankMfo: BankMfo({ form, t }),
    bsbCode: BSBCode({ form, t }),
    binOrIin: BinOrIin({ form, t }),
    knpOrUpdc: KnpOrUpdc({ form, t }),
  };

  const countrySetup = useMemo(() => paymentsFieldsConfig[country], [country]);

  const hasCustomBankFields = countrySetup?.fields?.length;

  if (hasCustomBankFields) {
    return (
      <>
        {countrySetup?.fields?.map((field: BANK_INPUTS) => {
          return (
            <React.Fragment key={field}>
              {fieldsComponentsList[field]}
            </React.Fragment>
          );
        })}
      </>
    );
  }

  return (
    <>
      {defaultFieldsConfig.map((field: BANK_INPUTS) => {
        return (
          <React.Fragment key={field}>
            {fieldsComponentsList[field]}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BankFields;
