import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router';
import { Idea } from '@airhelp/icons';
import { Collapsible } from '@airhelp/react';
import {
  Box,
  Flex,
  HStack,
  Heading,
  List,
  ListItem,
  Text,
  Link,
  useBreakpointValue,
} from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import { Card } from 'elements';

export interface ICondition {
  id: number;
  title: string | React.ReactNode;
}

interface IComponent {
  onToggle?: (isToggle: boolean) => void;
}

const Conditions: React.FC<IComponent> = ({ onToggle }) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const conditionsList: ICondition[] = [
    {
      id: 1,
      title: t('lounges.t&cs.item_1'),
    },
    {
      id: 2,
      title: t('lounges.t&cs.item_2'),
    },
    {
      id: 3,
      title: t('lounges.t&cs.item_3'),
    },
    {
      id: 4,
      title: t('lounges.t&cs.item_4'),
    },
    {
      id: 5,
      title: (
        <Trans
          i18nKey="lounges.t&cs.item_5"
          components={{
            a: (
              <Link
                as={ReactLink}
                to={{ pathname: generatePath(PAGE_PATHS.LOUNGE_FINDER_PAGE) }}
                fontSize="inherit"
                fontWeight="normal"
                _visited={{}}
              />
            ),
          }}
        />
      ),
    },
  ];

  const conditionsContent = (
    <>
      <List spacing={7}>
        {conditionsList.map((item) => (
          <ListItem
            display="flex"
            alignItems="flex-start"
            key={`lounge-pass-${item.id}`}
          >
            <Flex justifyContent="center" alignItems="center" me={4}>
              <Flex
                justifyContent="center"
                alignItems="center"
                fontSize="sm"
                fontWeight="medium"
                borderRadius="50%"
                backgroundColor="greyscale.300"
                color="primary.900"
                p={3}
                width={1}
                height={1}
              >
                {item.id}
              </Flex>
            </Flex>
            <Text fontSize="md" color="greyscale.700">
              {item.title}
            </Text>
          </ListItem>
        ))}
      </List>
      <HStack
        backgroundColor="greyscale.300"
        mt={8}
        borderRadius="md"
        px={4}
        py={3}
        flexShrink={0}
        justifyContent="space-between"
      >
        <Text color="primary.900" fontSize="md">
          {t('lounges.t&cs.1_use_only')}
        </Text>
        <Idea h={4} w="auto" color="danger.500" />
      </HStack>
    </>
  );

  if (isMobile) {
    return (
      <Card
        px={0}
        py={1}
        justifyContent="flex-start"
        data-testid="lounge-pass-terms-conditions"
      >
        <Collapsible title={t('lounges.t&cs.header')} onToggle={onToggle}>
          <Box mt={6}>{conditionsContent}</Box>
        </Collapsible>
      </Card>
    );
  }
  return (
    <Card
      maxWidth={{ base: '100%', lg: '785px' }}
      px={{ base: 4, md: 6, xl: 8 }}
      py={{ base: 4, xl: 8 }}
      justifyContent="flex-start"
      data-testid="lounge-pass-terms-conditions"
    >
      <Heading variant="h5" p={0} mb={6}>
        {t('lounges.t&cs.header')}
      </Heading>
      {conditionsContent}
    </Card>
  );
};

export default Conditions;
