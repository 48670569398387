import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router';
import {
  Box,
  Button,
  Flex,
  Heading,
  useBreakpointValue,
} from '@chakra-ui/react';
import { getSmartProduct } from 'components/Membership/membershipHelper';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import { type Offer } from 'models/Offers';
import { usePreferencesStore } from 'stores/preferences';
import {
  formatValueToCurrency,
  getLocalPriceValue,
} from 'stores/preferences/currencies';
import { useTrackEvent } from 'utils/tracking/hooks';
import upsellBannerBg from 'assets/images/bg-upsell-banner@2x.png';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { CommonParams, castBooleanParam, castStringParam } from 'utils/params';

interface IComponent {
  showPerkServices?: boolean;
}

const UpsellSmart = ({ showPerkServices = true }: IComponent) => {
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const { isLoading: isLoadingOffers, data } = useComputedOffers({
    params: {
      renew: castBooleanParam(searchParams, CommonParams.RENEW),
      upgrade: castBooleanParam(searchParams, CommonParams.UPGRADE),
      channel: castStringParam(searchParams, CommonParams.CHANNEL),
    },
  });
  const { trackUpsellOption } = useTrackEvent();
  const currency = usePreferencesStore((state) => state.currency);

  const handleUpsellSmartClick = () => {
    trackUpsellOption('get membership smart', 'home page');
  };

  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isLoadingOffers) {
    return <Loader dataTestId="loader" />;
  }

  const offers = data?.offers as Record<string, Offer>;

  const product = getSmartProduct(offers);

  const localPrice = getLocalPriceValue(product?.prices, currency);

  return (
    <Box flex="1" order={4}>
      <Flex
        p={{ base: 4, md: 8 }}
        backgroundImage={upsellBannerBg}
        backgroundSize="cover"
        backgroundPosition="right"
        borderRadius="xl"
        justifyContent="space-between"
        alignItems={{
          base: 'center',
          md: showPerkServices ? 'flex-start' : 'center',
        }}
        alignContent={{ base: 'space-between', md: 'flex-start' }}
        flexDirection={{ base: 'row', md: showPerkServices ? 'column' : 'row' }}
        width="auto"
        height="100%"
        columnGap={4}
        data-testid="upsell-smart"
      >
        {/* copy part */}
        <Heading
          variant="h5"
          color="greyscale.100"
          fontWeight={{ base: 'medium', md: 'bold' }}
          fontSize={{ base: 'sm', md: 'unset' }}
          height="auto"
        >
          {t('trips.upgrade.annual.annual_title')}
        </Heading>

        {/* button part */}
        <Box width="auto">
          <Button
            as={ReactLink}
            onClick={handleUpsellSmartClick}
            to={{
              pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY_PAGE),
              search: 'channel=dashboard_home',
            }}
            size="xs"
            variant="subtle"
            data-testid="btn-upsell-smart"
          >
            {isMobile
              ? t('common.more')
              : t('trips.upgrade.annual.button_buy_essential_annual', {
                  value: formatValueToCurrency(localPrice, currency),
                })}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};

export default UpsellSmart;
