import {
  Flex,
  StackDivider,
  Text,
  VStack,
  useBreakpointValue,
  Circle,
  type StyleProps,
} from '@chakra-ui/react';
import React, { type PropsWithChildren } from 'react';

export function Root({
  children,
  title,
}: PropsWithChildren<{ title: string }>) {
  return (
    <VStack
      as="section"
      rounded="lg"
      align="stretch"
      gap={4}
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 6,
      }}
    >
      <Text as="header" color="primary.900" fontSize="lg" fontWeight="medium">
        {title}
      </Text>
      {children}
    </VStack>
  );
}

interface DetailsRowProps {
  label: string;
  value: string;
  dataTestId?: string;
  onValueClick?: () => void;
}

export function Row({
  label,
  value,
  onValueClick,
  dataTestId,
}: DetailsRowProps) {
  const ValueComponent = onValueClick ? (
    <Text
      as="button"
      fontWeight="medium"
      fontSize="sm"
      color="primary.500"
      cursor="pointer"
      onClick={onValueClick}
      data-testid={dataTestId}
    >
      {value}
    </Text>
  ) : (
    <Text
      fontSize="sm"
      color="primary.900"
      fontWeight="medium"
      sx={{
        textWrap: 'wrap',
        wordBreak: 'break-word',
      }}
    >
      {value}
    </Text>
  );

  return (
    <Flex w="100%" justifyContent="space-between" gap={8}>
      <Text color="greyscale.600" fontSize="sm">
        {label}
      </Text>
      {ValueComponent}
    </Flex>
  );
}

export function Header({
  title,
  subtitle,
  ...props
}: {
  title?: string;
  subtitle?: string;
} & StyleProps) {
  return (
    <Flex
      gap={4}
      alignItems="center"
      {...props}
      data-testid="data-group-header"
    >
      <Circle background="primary.700" size={8}>
        <Text color="greyscale.100" fontWeight="medium" fontSize="xs">
          {title?.charAt(0)}
        </Text>
      </Circle>
      <VStack align="stretch" spacing={0}>
        <Text fontSize="md" color="primary.900" fontWeight="medium">
          {title}
        </Text>
        <Text fontSize="xs" color="greyscale.600">
          {subtitle}
        </Text>
      </VStack>
    </Flex>
  );
}

interface DataGroupProps {
  title?: string;
  subtitle?: string;
  children: React.ReactNode | React.ReactNode[];
}

export function DataGroup({ children, title, subtitle }: DataGroupProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const withHeader = Boolean(title && subtitle);

  const paddingStyles = withHeader
    ? {
        base: 4,
        md: 8,
      }
    : 0;

  return (
    <VStack
      align="stretch"
      rounded="xl"
      gap={4}
      border={withHeader ? '1px solid #DEE5ED' : undefined}
    >
      {withHeader ? (
        <Header
          title={title}
          subtitle={subtitle}
          px={4}
          py={3}
          borderBottom="1px solid #DEE5ED"
        />
      ) : null}
      <VStack
        align="stretch"
        gap={{
          base: 4,
          md: 2,
        }}
        rounded="xl"
        px={paddingStyles}
        pb={paddingStyles}
        divider={
          isMobile ? undefined : (
            <StackDivider
              borderColor="greyscale.300"
              data-testId="details-divider"
            />
          )
        }
      >
        {children}
      </VStack>
    </VStack>
  );
}
