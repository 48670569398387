import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from '@airhelp/react';

interface IComponent {
  errorMessage?: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isInvalid: boolean;
}

const PhoneNumberInput: React.FC<IComponent> = ({
  errorMessage,
  name,
  value,
  onChange,
  isInvalid,
}) => {
  const { t } = useTranslation();

  return (
    <Input
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      size="lg"
      id="phone-number"
      label={t('user.phone_number')}
      name={name}
      value={value}
      type="tel"
      inputMode="tel"
      placeholder={t('common.phone_number_placeholder')}
      onChange={onChange}
      // Arabic lang, rtl direction changes text-align to right
      textAlign="left"
      data-testid="phone-number"
    />
  );
};

export default PhoneNumberInput;
