import React from 'react';
import type { UserProfile } from 'models/userProfile/UserProfile';
import CoveredDetails from './CoveredDetails';
import NoPlanInfo from './NoPlanInfo';

const PlanInfo: React.FC<{
  userProfile: UserProfile;
}> = ({ userProfile }) => {
  const { membership, expiredMembership } = userProfile.policy;

  const showCoveredDetails =
    !membership.isFreeAccount ||
    (membership.isFreeAccount && expiredMembership?.tier);

  return showCoveredDetails ? (
    <CoveredDetails userProfile={userProfile} />
  ) : (
    <NoPlanInfo userProfile={userProfile} />
  );
};

export default PlanInfo;
