import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { generatePath, Link as ReactLink } from 'react-router';
import { Button, Text, HStack, Box, Flex, Heading } from '@chakra-ui/react';
import { ArrowRight, Check } from '@airhelp/icons';
import { MembershipTier, ProductName } from '@airhelp/plus';
import { formatDate } from 'date-fns';
import { isRtl } from 'utils/textDirection/isRtl';
import type { UserProfile } from 'models/userProfile/UserProfile';
import {
  useLocale,
  dateFnLocaleHelper,
} from 'contexts/LocaleContext/LocaleContextProvider';
import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';

function calculateProgressBarPercentage(
  startDate: string | Date,
  endDate: string | Date,
): number {
  const start = new Date(startDate).getTime();
  const end = new Date(endDate).getTime();
  const now = Date.now();

  if (now < start) {
    return 0;
  }

  if (now > end) {
    return 100;
  }

  const totalTime = end - start;
  const elapsedTime = now - start;

  const percent = Math.min(
    100,
    Math.max(0, Math.round((elapsedTime / totalTime) * 100)),
  );

  return percent;
}

function getBackgroundStyleByPercentage(percentage: number): string {
  switch (true) {
    case percentage < 30:
      return 'linear-gradient(90deg, #0030A6 0%, #0D73FF 30%, #87BAFF 50%, rgba(255, 107, 115, 0.1) 100%)';
    case percentage >= 30 && percentage <= 99:
      return 'linear-gradient(90deg, #0030A6 0%, #0D73FF 50%, #87BAFF 80%, rgba(255, 107, 115, 0.5) 100%)';
    case percentage > 99:
      return 'linear-gradient(90deg, rgba(222, 229, 237, 10) 70%, rgba(255, 107, 115, 1) 100%)';
    default:
      return 'linear-gradient(90deg, #0030A6 0%, #0D73FF 30%, #87BAFF 43%, rgba(255, 107, 115, 0.7) 100%)';
  }
}

const CoveredDetails: React.FC<{
  userProfile: UserProfile;
}> = ({ userProfile }) => {
  const { trackUpsellOption } = useTrackEvent();
  const { t, i18n } = useTranslation();
  const { locale } = useLocale();

  const {
    membership: currentMembership,
    expiredMembership,
    dashboard,
  } = userProfile.policy;

  const isExpiredMembership = !!expiredMembership?.tier;

  const membership = isExpiredMembership
    ? expiredMembership
    : currentMembership;

  if (!membership?.subscriptionStartDate) {
    return null;
  }

  const isAutoRenewal = membership.autoRenewal;
  const isFreeAccount = membership.isFreeAccount;
  const isProMembership = membership.tier === MembershipTier.PRO;

  const upsellEnabled =
    dashboard?.upsellEnabled &&
    [
      MembershipTier.FREE,
      MembershipTier.LITE,
      MembershipTier.SMART,
      MembershipTier.PRO,
    ].includes(currentMembership.tier) &&
    ![
      ProductName.AIRHELP_SMART_EXTERNAL,
      ProductName.AIRHELP_PRO_EXTERNAL,
      ProductName.AIRHELP_PRO_INTERNAL,
    ].includes(currentMembership.productName);

  const startDate = membership.subscriptionStartDate;
  const endDate = membership.endDate;

  const startingDate = formatDate(startDate, 'dd MMM yyyy', {
    locale: dateFnLocaleHelper(i18n?.language),
  });

  const endingDate = formatDate(endDate, 'dd MMM yyyy', {
    locale: dateFnLocaleHelper(i18n?.language),
  });

  const progressBarPercentage = isExpiredMembership
    ? 100
    : calculateProgressBarPercentage(startDate, endDate);

  const progressBarBackgroundStyle = getBackgroundStyleByPercentage(
    progressBarPercentage,
  );

  const checkPlansLabelButton = () => {
    switch (true) {
      case isFreeAccount || isExpiredMembership:
        return t('my_account.my_benefits.covered_plan.get_ahplus');
      case isProMembership:
        return t('my_account.my_benefits.covered_plan.check_plans');
      default:
        return t('my_account.my_benefits.covered_plan.upgrade_plan');
    }
  };

  return (
    <Box
      width="100%"
      borderRadius="lg"
      background="greyscale.100"
      p={{ base: 4, md: 6 }}
      mt={4}
    >
      <Heading
        variant="h3"
        fontSize={{ base: '20px', md: '2xl' }}
        lineHeight="short"
      >
        {isExpiredMembership ? (
          <Trans
            i18nKey="my_account.my_benefits.covered_plan.you_are_not_covered"
            components={{
              strong: (
                <Text
                  as="span"
                  color="danger.500"
                  fontSize="inherit"
                  fontWeight="bold"
                />
              ),
            }}
          />
        ) : (
          t('my_account.my_benefits.covered_plan.you_are_covered_by_ahplus', {
            planName: membership.displayName,
          })
        )}
      </Heading>
      <HStack width="100%" justifyContent="space-between" mt={4} mb={1}>
        <Box
          alignSelf="flex-start"
          color="primary.900"
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {startingDate}
        </Box>
        <Box
          alignSelf="flex-end"
          color="primary.900"
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
        >
          {endingDate}
        </Box>
      </HStack>
      <Box
        width="100%"
        height="8px"
        borderRadius="sm"
        background="greyscale.200"
        mb={4}
      >
        <Box
          width={`${progressBarPercentage}%`}
          height="8px"
          borderRadius="sm"
          background={progressBarBackgroundStyle}
          mb={4}
        />
      </Box>
      <HStack
        width="100%"
        justifyContent="space-between"
        justifyItems="center"
        mt={8}
        mb={1}
      >
        <Flex
          color="greyscale.600"
          fontSize="sm"
          fontWeight="medium"
          width="50%"
          alignItems="flex-start"
        >
          {isExpiredMembership ? (
            <Flex
              borderRadius="40px"
              color="danger.500"
              backgroundColor="danger.200"
              textTransform="uppercase"
              letterSpacing="1.5px"
              fontWeight="medium"
              py={2}
              px={3}
              fontSize="xs"
              maxH="28px"
              alignItems="center"
            >
              {t('my_account.my_benefits.covered_plan.expired')}
            </Flex>
          ) : isAutoRenewal ? (
            <Box display="inline-block" verticalAlign="baseline">
              {t('my_account.my_benefits.covered_plan.auto_renew')}
              <Check ms={1} boxSize={6} color="greyscale.600" mb={1} />
            </Box>
          ) : null}
        </Flex>
        {upsellEnabled ? (
          <Flex
            mt={0}
            pt={0}
            width="50%"
            justifyContent="flex-end"
            display="inline-flex"
          >
            <Button
              as={ReactLink}
              variant={isProMembership ? 'subtle' : 'primary'}
              size="xs"
              height="40px"
              rightIcon={
                <ArrowRight
                  w="5"
                  height="5"
                  transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
                />
              }
              to={{
                pathname: generatePath(
                  PAGE_PATHS.MY_ACCOUNT.PLAN_MANAGEMENT_PAGE,
                ),
              }}
              onClick={() => {
                trackUpsellOption('upgrade plan click', 'ahp plans&benefits');
              }}
              data-testid="btn-upgrade-plan"
            >
              {checkPlansLabelButton()}
            </Button>
          </Flex>
        ) : null}
      </HStack>
    </Box>
  );
};

export default CoveredDetails;
