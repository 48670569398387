import { ProductName } from '@airhelp/plus';
import { type IPrice } from 'models/Offers';
import {
  FREE_PROMO_DISCOUNT_PERCENT,
  FREE_PROMO_CODE,
  OFFER_NAME_FREE,
  OFFER_NAME_LITE,
  OFFER_NAME_SMART,
  OFFER_NAME_PRO,
} from './plan-management-config';

export const getDiscountedPrice = (
  isFreeAccount: boolean,
  priceDetails: IPrice,
) => {
  if (isFreeAccount) {
    return parseFloat(
      (
        priceDetails.price *
        ((100 - FREE_PROMO_DISCOUNT_PERCENT) / 100)
      ).toFixed(2),
    );
  }
  return priceDetails.discountedPrice ?? priceDetails.price;
};

export const getDiscountPercentage = (
  isFreeAccount: boolean,
  priceDetails: IPrice,
) => {
  if (isFreeAccount) {
    return FREE_PROMO_DISCOUNT_PERCENT;
  }

  return priceDetails.discountPercentage ?? 0;
};

export const shouldFeaturePlan = (
  currentOfferName: string,
  planName: string,
) => {
  switch (true) {
    case currentOfferName === OFFER_NAME_FREE &&
      planName === (ProductName.AIRHELP_SMART_ANNUALLY as string):
    case (currentOfferName === (ProductName.AIRHELP_PRO_ANNUALLY as string) ||
      currentOfferName === (ProductName.AIRHELP_PRO_SINGLE as string)) &&
      planName === (ProductName.AIRHELP_PRO_ANNUALLY as string):
    case (currentOfferName === (ProductName.AIRHELP_SMART_ANNUALLY as string) ||
      currentOfferName === (ProductName.AIRHELP_SMART_SINGLE as string)) &&
      planName === (ProductName.AIRHELP_PRO_ANNUALLY as string):
    case (currentOfferName === (ProductName.AIRHELP_LITE_ANNUALLY as string) ||
      currentOfferName === (ProductName.AIRHELP_LITE_SINGLE as string)) &&
      planName === (ProductName.AIRHELP_SMART_ANNUALLY as string):
      return true;
    default:
      return false;
  }
};

export const getSearchParamsForButton = (
  currentOfferName: string,
  planName: string | null,
) => {
  const urlSearchParams = new URLSearchParams(location.search);

  const channelsConfig = {
    [OFFER_NAME_FREE]: {
      Smart: 'pm_free_smart',
      Pro: 'pm_free_pro',
    },
    [OFFER_NAME_LITE]: {
      Smart: 'pm_lite_smart',
      Pro: 'pm_lite_pro',
    },
    [OFFER_NAME_SMART]: {
      Pro: 'pm_smart_pro',
    },
  };

  if (planName) {
    urlSearchParams.set('tier', planName.toLowerCase());

    const channel: string = channelsConfig[currentOfferName][planName] ?? '';

    if (channel) {
      urlSearchParams.set('channel', channel);
    }
  }

  currentOfferName === OFFER_NAME_FREE
    ? urlSearchParams.set('promocode', FREE_PROMO_CODE)
    : urlSearchParams.set('upgrade', 'true');

  return urlSearchParams.toString();
};

export const isEntitledToShowBuyPlan = (
  currentOfferName: string,
  selectedOfferName: string | null,
) => {
  const isEntitled = () => {
    switch (true) {
      case currentOfferName === OFFER_NAME_FREE ||
        currentOfferName === OFFER_NAME_LITE:
      case Boolean(selectedOfferName) &&
        currentOfferName !== selectedOfferName &&
        currentOfferName !== OFFER_NAME_PRO:
        return true;
      case currentOfferName === OFFER_NAME_PRO:
      default:
        return false;
    }
  };

  return isEntitled();
};
