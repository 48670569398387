import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { isIOS, isAndroid } from 'react-device-detect';
import { type LoungePass } from 'models/LoungePass';
import appleWalletIcon from 'assets/images/lounges/apple-wallet-icon@2x.png';
import googleWalletIcon from 'assets/images/lounges/google-wallet-icon@2x.png';
import PassInformationItem from './PassInformationItem';
import PassNumeration from './PassNumeration';
import PassQrCode from './PassQrCode';
import AddToWalletButton from './AddToWalletButton';

interface IComponent {
  ordinalNumber: number;
  loungePass: LoungePass;
}

const PassDetails: React.FC<IComponent> = ({ ordinalNumber, loungePass }) => {
  const { t } = useTranslation();

  const validUntil = format(
    new Date(loungePass.validUntil.substring(0, 10)),
    'dd.MM.yyyy',
  );

  return (
    <VStack
      backgroundColor="greyscale.200"
      width="100%"
      borderRadius="lg"
      px={{ base: 4, md: 8 }}
      py={5}
      align={{ base: 'center', md: 'flex-start' }}
      spacing={{ base: 3, md: 8 }}
      data-testid={`lounge-pass-${loungePass.passCode}-${ordinalNumber}`}
    >
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        columnGap={8}
        rowGap={3}
        width="100%"
        position="relative"
      >
        <PassNumeration number={ordinalNumber} />
        <PassQrCode imageUrl={loungePass.imageUrl} />
        <VStack justify="space-between">
          <VStack
            width="100%"
            height="100%"
            align="flex-start"
            justify={isIOS || isAndroid ? 'flex-start' : 'center'}
            spacing={3}
          >
            <PassInformationItem
              label={t('lounges.passes.voucher.name')}
              value={loungePass.passengerName}
            />
            <PassInformationItem
              label={t('lounges.passes.voucher.qr')}
              value={loungePass.passCode}
            />
            <PassInformationItem
              label={t('lounges.passes.voucher.expires_on')}
              value={validUntil}
            />
          </VStack>
          {isIOS ? (
            <AddToWalletButton
              url={loungePass.appleWalletUrl}
              iconSrc={appleWalletIcon}
              label={t('lounges.passes.add_to_apple_wallet')}
              eventLabel="add to apple wallet"
            />
          ) : null}
          {isAndroid ? (
            <AddToWalletButton
              url={loungePass.googleWalletUrl}
              iconSrc={googleWalletIcon}
              label={t('lounges.passes.add_to_google_wallet')}
              eventLabel="add to google wallet"
            />
          ) : null}
        </VStack>
      </Flex>
    </VStack>
  );
};

export default PassDetails;
