import React from 'react';
import { Trans } from 'react-i18next';
import { Delete, Checkmark } from '@airhelp/icons';
import { Text, Circle, VStack } from '@chakra-ui/react';
import { type PlanDetailsItem } from 'components/MyAccountView/PlanManagementView/types';
import { type TranslationKey } from 'types/i18n';

const DetailsBenefit: React.FC<{ benefit: PlanDetailsItem }> = ({
  benefit,
}) => {
  const hasContent =
    benefit.included && Boolean(benefit.content || benefit.content_tkey);

  const hasSecondRowContent = Boolean(
    benefit.content_second || benefit.content_second_tkey,
  );

  return (
    <VStack
      width="100%"
      textColor="primary.900"
      fontSize="sm"
      height="62px"
      overflow="hidden"
      gap={0}
      justifyContent="center"
      data-testid={`plan-detail-${benefit.key}`}
    >
      {hasContent ? (
        <>
          <Text
            fontWeight="500"
            fontSize={benefit.content ? 'md' : 'sm'}
            whiteSpace="nowrap"
            m={0}
            p={0}
          >
            {benefit.content ? (
              benefit.content
            ) : (
              <Trans
                i18nKey={benefit.content_tkey as unknown as TranslationKey[]}
                values={benefit.content_tkey_params}
                count={benefit.content_tkey_params?.count}
                display="inline-block"
                textColor="primary.900"
                whiteSpace="nowrap"
              />
            )}
          </Text>
          {!!hasSecondRowContent && (
            <Text
              fontWeight="400"
              color="greyscale.700"
              fontSize="sm"
              whiteSpace="nowrap"
              m={0}
              p={0}
            >
              {benefit.content_second ? (
                benefit.content_second
              ) : (
                <Trans
                  i18nKey={
                    benefit.content_second_tkey as unknown as TranslationKey[]
                  }
                  values={benefit.content_second_tkey_params}
                  display="inline-block"
                  textColor="primary.900"
                  whiteSpace="nowrap"
                />
              )}
            </Text>
          )}
        </>
      ) : (
        typeof benefit.included !== 'undefined' &&
        (benefit.included ? (
          <Checkmark color="success.600" boxSize={5} />
        ) : (
          <Circle
            size={5}
            bgColor="greyscale.400"
            justifySelf="flex-end"
            data-testid="excluded-icon"
          >
            <Delete color="greyscale.100" boxSize={3} />
          </Circle>
        ))
      )}
    </VStack>
  );
};

export default DetailsBenefit;
