import {
  useClaimSuspense,
  useSimplifiedClaimSuspense,
} from '@airhelp/webapp/react';
import { useMemo } from 'react';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import {
  CLAIM_REJECTED_STATES,
  ClaimSentiment,
  type CLAIM_STATES,
} from './claim-states';

export function useClaimState(id: number | string) {
  const { locale } = useLocale();
  const { data: claim } = useClaimSuspense({
    params: {
      id: Number(id),
    },
  });

  const { data: claimState } = useSimplifiedClaimSuspense({
    params: {
      id: Number(id),
      locale,
    },
  });

  return useMemo(() => {
    const hasClaimNextState = Boolean(claimState?.state.nextStepHeader);

    const isClaimRejected = CLAIM_REJECTED_STATES.has(
      claimState?.state.identifier as CLAIM_STATES,
    );

    const isClaimInPausedState = [
      'customer_unresponsive',
      'missing_docs_or_information',
    ].includes(claimState?.rejectionReason ?? '');

    const currentStateSentiment = isClaimRejected
      ? ClaimSentiment.NEGATIVE
      : claimState?.state.sentiment;

    // API returns claim state history as an array of already finished states, but we want to display the current state as well
    // if current state has next state, we create fake item to the state history from the next step
    // if the next state is not present it means that claim is finished / closed and we display the last 2 states from the history
    const shortClaimStateHistory = hasClaimNextState
      ? [
          {
            state: claimState.state,
            completedAt: claimState.createdAt,
          },
          {
            state: {
              identifier: 'next_state',
              short: claimState.state.nextStepHeader,
              header: claimState.state.nextStepHeader,
              comment: claimState.state.nextStepComment,
              sentiment: 'neutral',
            },
            completedAt: new Date(),
          },
        ]
      : claimState.stateHistory.slice(-2);

    const longClaimStateHistory = hasClaimNextState
      ? [
          ...claimState.stateHistory,
          {
            state: claimState.state,
            completedAt: claimState.createdAt,
          },
          {
            state: {
              identifier: 'next_state',
              short: claimState.state.nextStepHeader,
              header: claimState.state.nextStepHeader,
              comment: claimState.state.nextStepComment,
              sentiment: 'neutral',
            },
            completedAt: new Date(),
          },
        ]
      : claimState.stateHistory;

    const claimHistorySize = longClaimStateHistory.length;
    const isClaimHistoryLong = claimHistorySize > 2;

    const payout = claim?.claimPayout;
    const isCompensationCollectible =
      payout.payoutCreated && !payout.collectedAt;

    const goodwillRegulation = claim.regulation?.includes('goodwill');
    const withCompensation = Boolean(
      Number(claim.payoutValueInPreferredCurrency),
    );

    const montrealTrackWithoutCompensation =
      claim.regulation === 'mcxx' && !withCompensation;

    const canDisplayCompensation =
      !goodwillRegulation && !montrealTrackWithoutCompensation;

    return {
      isCompensationCollectible,
      isClaimInPausedState,
      isClaimRejected,
      canDisplayCompensation,
      shortStateHistory: shortClaimStateHistory,
      longStateHistory: longClaimStateHistory,
      isClaimHistoryLong,
      currentStateSentiment,
      hasClaimNextState,
      claimHistorySize: longClaimStateHistory.length,
    };
  }, [claim, claimState]);
}
