import { createContext, type RefObject, useContext } from 'react';
import { Portal } from '@chakra-ui/react';

const TopNavContext = createContext({
  portalRef: undefined as RefObject<HTMLDivElement | null> | undefined,
});

const TopNavProvider = TopNavContext.Provider;

export function useTopNav() {
  const { portalRef } = useContext(TopNavContext);
  return {
    portalRef,
    TopBarPortal: ({ children }) => (
      <Portal containerRef={portalRef} appendToParentPortal={false}>
        {children}
      </Portal>
    ),
  };
}

export default TopNavProvider;
