import React, { useState, useEffect } from 'react';
import cookie from 'react-cookies';
import { generatePath, Navigate, useLocation } from 'react-router';
import { track } from 'react-tracking';
import { Center } from '@chakra-ui/react';
import SignupView from 'components/SignupView';
import { AHP_SIGNUP_EMAIL_COOKIE } from 'config/cookies';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { usePageTracking } from 'utils/tracking';

type SignupLocationState = Record<string, string>;

const SignupPage: React.FC = () => {
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();
  const location = useLocation();
  const locationState = location.state as SignupLocationState;
  // kstoklosa - 2024-05-28 - I think that we don't need to use the `from` state from the location, but I'm leaving it here for now - could be refactored later on
  // eslint-disable-next-line react/hook-use-state -- we are disabling this rule because we want to initialize the state with a value derived from the location state or a default path
  const [from] = useState<string>(
    locationState?.from
      ? locationState?.from
      : generatePath(PAGE_PATHS.HOME_PAGE),
  );
  const cookieEmail = cookie.load(AHP_SIGNUP_EMAIL_COOKIE);
  usePageTracking();

  useEffect(() => {
    if (cookieEmail) {
      cookie.remove(AHP_SIGNUP_EMAIL_COOKIE, { path: '/' });
    }
  }, []);

  if (isUserProfileLoading) {
    return (
      <Center>
        <Loader dataTestId="loader" />
      </Center>
    );
  }

  if (!userProfile) {
    return <SignupView email={cookieEmail} />;
  }
  return <Navigate to={{ pathname: from }} />;
};

export default track({ page_type: 'Signup' })(SignupPage);
