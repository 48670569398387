import { Flag, type CountryCode } from '@airhelp/flags';
import { Flex, Text } from '@chakra-ui/react';
import { fontFamilyFraktion } from '@airhelp/design-tokens';

interface AirportProps {
  airport: string;
  airportCountryCode: CountryCode;
  direction?: 'row' | 'row-reverse';
  isRtl?: boolean;
}

export function Airport({
  airport,
  airportCountryCode,
  direction = 'row',
}: AirportProps) {
  return (
    <Flex alignItems="center" gap="6px" direction={direction} maxW="130px">
      <Flag code={airportCountryCode} size="s" rounded />
      <Text
        dir="ltr"
        color="greyscale.500"
        fontSize="11px"
        fontWeight="bold"
        textTransform="uppercase"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        lineHeight="13.2px"
        letterSpacing="-0.014em"
        fontFamily={fontFamilyFraktion}
      >
        {airport}
      </Text>
    </Flex>
  );
}
