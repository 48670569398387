import React from 'react';
import { Navigate, generatePath } from 'react-router';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { usePageTracking } from 'utils/tracking';

const IndexPage = () => {
  usePageTracking();

  return (
    <Navigate
      to={{ pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.DETAILS_PAGE) }}
    />
  );
};

export default track({ page_type: 'MyAccount.Index' })(IndexPage);
