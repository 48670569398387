import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, Text } from '@chakra-ui/react';
import { type Offer } from 'models/Offers';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { airLuggagePolicy, airPayoutPolicy } from 'utils/insurancePolicy';
import { getMembershipPlan } from 'components/Membership/membershipHelper';
import { getWebsitePageUrl } from 'utils/sites';
import { useComputedOffers } from 'hooks/api/offers/useComputedOffers';
import { castBooleanParam, castStringParam, CommonParams } from 'utils/params';

export const usePrivacyConsentLabel = () => {
  const { locale } = useLocale();

  const termsUrl = getWebsitePageUrl('termsPath', locale);
  const privacyUrl = getWebsitePageUrl('privacyPath', locale);
  let airLuggageIpidversion: string | undefined = '';
  let airPayoutIpidversion: string | undefined = '';

  const newUrlSearchParams = new URLSearchParams(location.search);
  const tier = castStringParam(newUrlSearchParams, CommonParams.TIER);
  const renew = castBooleanParam(newUrlSearchParams, CommonParams.RENEW);
  const upgrade = castBooleanParam(newUrlSearchParams, CommonParams.UPGRADE);
  const channel = castStringParam(newUrlSearchParams, CommonParams.CHANNEL);

  const { isLoading: isLoadingOffers, data: offersData } = useComputedOffers({
    params: {
      renew,
      upgrade,
      channel,
    },
  });

  if (!isLoadingOffers && offersData) {
    const offers: Record<string, Offer> = offersData.offers;
    const renewOffer = offersData.renewOffer;

    const offer: Offer | undefined | null = getMembershipPlan({
      offers,
      renewOffer,
      tier,
      isManualRenew: Boolean(newUrlSearchParams.get(CommonParams.RENEW)),
    });
    airPayoutIpidversion = offer?.airPayoutPerk?.config.ipidVersion;
    airLuggageIpidversion = offer?.airLuggagePerk?.config.ipidVersion;
  }
  const airLuggageTermsUrl = airLuggagePolicy(airLuggageIpidversion || '');
  const airPayoutTermsUrl = airPayoutPolicy(airPayoutIpidversion || '');

  return (
    <Text as="span" fontSize="sm" color="greyscale.600">
      <Trans
        i18nKey="common.agree_tc_and_privacy_consent_air_payout_and_air_luggage"
        values={{
          privacyUrl,
          termsUrl,
          airPayoutTermsUrl,
          airLuggageTermsUrl,
        }}
        components={{
          a: (
            <Link
              isExternal
              fontSize="sm"
              fontWeight="normal"
              textDecoration="none !important"
            />
          ),
        }}
      />
    </Text>
  );
};

export const useMarketingConsentLabel = () => {
  const { t } = useTranslation();

  return (
    <Text as="span" fontSize="sm" color="greyscale.600">
      {t('common.marketing_consent')} {t('optional')}
    </Text>
  );
};
