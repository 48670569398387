import { useClaimCommunicationHistorySuspense } from '@airhelp/webapp/react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { formatDate } from 'date-fns';
import { useTranslation } from 'react-i18next';
import * as VerticalStepTracker from 'components/VerticalStepTracker';
import Drawer from 'components/Drawer';
import { useTrackEvent } from 'utils/tracking/hooks';

export function History() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { id } = useParams();
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const { data } = useClaimCommunicationHistorySuspense({
    params: { id: Number(id) },
  });
  const claimHistory = data.messages.slice().reverse();
  const shortClaimHistorySize = 3;
  const initialClaimHistory = claimHistory.slice(-1 * shortClaimHistorySize);
  const claimHistorySize = claimHistory.length;
  const isClaimHistoryLong = claimHistorySize > shortClaimHistorySize;

  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 8,
      }}
    >
      <VerticalStepTracker.Root
        extendedBorder={isClaimHistoryLong ? 'top' : undefined}
      >
        {initialClaimHistory.map((stage) => (
          <CommunicationHistoryListItem
            key={stage.id}
            subject={stage.subject}
            content={stage.textContent}
            createdAt={stage.createdAt}
          />
        ))}
      </VerticalStepTracker.Root>
      {isClaimHistoryLong ? (
        <Button
          variant="secondary"
          size="s"
          onClick={() => {
            trackPageInteractions(
              'drawer open',
              'full history',
              'claim details page - email history',
            );
            onOpen();
          }}
          mt={4}
          data-testid="view-status-details-button"
        >
          {t('claims.layout.view_status_details')}
        </Button>
      ) : null}
      <Drawer
        header={t('claims.layout.claim_history')}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Box w="full" ps={4} pe={6}>
          <VerticalStepTracker.Root>
            {claimHistory.map((stage) => (
              <CommunicationHistoryListItem
                key={stage.id}
                subject={stage.subject}
                content={stage.textContent}
                createdAt={stage.createdAt}
              />
            ))}
          </VerticalStepTracker.Root>
        </Box>
        <Box p={4} pb={6}>
          <Button onClick={onClose} w="full">
            {t('common.close')}
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

function CommunicationHistoryListItem({
  subject,
  content,
  createdAt,
}: {
  subject: string;
  content: string;
  createdAt: Date;
}) {
  return (
    <VerticalStepTracker.Step
      header={subject}
      subheader={formatDate(createdAt, 'd MMMM yyyy')}
      state="upcoming"
    >
      <VerticalStepTracker.StepContent>
        <Box
          fontSize="sm"
          whiteSpace="pre-wrap"
          dir="ltr"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </VerticalStepTracker.StepContent>
    </VerticalStepTracker.Step>
  );
}
