import { type Itinerary } from '@airhelp/plus';
import { useCityTranslations } from 'utils/journey/journey';
import { isRtl } from 'utils/textDirection/isRtl';

// format itinerary option label for Select component
export const formatItineraryOptions = (
  itineraries: Itinerary[] | undefined,
  locale: string,
) => {
  if (!itineraries) {
    return [];
  }

  return itineraries.map((itinerary) => {
    const flights = itinerary.flights;

    const firstFlight = flights[0];
    const lastFlightIndex = flights.length - 1;
    const lastFlight = flights[lastFlightIndex];

    const departureAirport = firstFlight.departureAirport;
    const arrivalAirport = lastFlight.arrivalAirport;

    const departureAirportName = useCityTranslations(departureAirport);
    const departureAirportIata = departureAirport?.iata;

    const arrivalAirportName = useCityTranslations(arrivalAirport);
    const arrivalAirportIata = arrivalAirport?.iata;

    const itineraryLabel = isRtl(locale)
      ? `${arrivalAirportName} (${arrivalAirportIata}) - ${departureAirportName} (${departureAirportIata})`
      : `${departureAirportName} (${departureAirportIata}) - ${arrivalAirportName} (${arrivalAirportIata})`;

    return {
      label: itineraryLabel,
      value: itinerary.id,
    };
  });
};
