import { type PlanLabelItem } from './types';

export const FREE_PROMO_CODE = 'FREE10';

export const OFFER_NAME_FREE = 'Free';
export const OFFER_NAME_LITE = 'Lite';
export const OFFER_NAME_SMART = 'Smart';
export const OFFER_NAME_PRO = 'Pro';

// percentage amount for service fee
export const FREE_SERVICE_FEE = 50;

export const FREE_PROMO_DISCOUNT_PERCENT = 10;
export const DEFAULT_CURRENCY_PRICE_PLAN = 'EUR';

export const planBenefits: PlanLabelItem[] = [
  {
    key: 'compensation_claims',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.compensation_claims',
  },
  {
    key: 'compensation_service',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.compensation_service',
  },
  {
    key: 'flight_status',
    label_tkey: 'my_account.my_benefits.plans_compared.benefits.flight_status',
  },
  {
    key: 'dedicated_support',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.prioritized_chat',
  },
  {
    key: 'exclusive_deals',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.exclusive_deals',
  },
  {
    key: 'esim_discount',
    label_tkey: 'my_account.my_benefits.plans_compared.benefits.esim_discount',
  },
  {
    key: 'airport_lounge_access',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.airport_lounge_access',
  },
  {
    key: 'air_payout',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.airpayout_insurance',
  },
  {
    key: 'air_luggage',
    label_tkey:
      'my_account.my_benefits.plans_compared.benefits.airluggage_insurance',
  },
];
