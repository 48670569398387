import { Text, Flex, Box, Image } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import claimCompensationBg from 'assets/images/claim_compensation.png';
import noCompensationBg from 'assets/images/buka_looking.png';

function TotalCompensation({
  perPassengerCompensation,
  totalCompensation,
}: {
  perPassengerCompensation: string;
  totalCompensation: string;
}) {
  const { t } = useTranslation();
  return (
    <Box textAlign="center">
      <Text
        fontSize="xs"
        color="primary.900"
        fontWeight="bold"
        textTransform="uppercase"
        fontFamily={fontFamilyFraktion}
        mb={{
          base: 3,
          md: -2,
        }}
      >
        {t('claims.compensation.your_compensation')}
      </Text>
      <Text
        fontWeight="bold"
        color="primary.500"
        fontSize={{
          base: '36px',
          md: '5xl',
        }}
        data-testid="summary-total-compensation"
      >
        {totalCompensation}
      </Text>
      <Text color="greyscale.600" fontWeight="normal" fontSize="sm">
        {t('claims.compensation.per_passenger', {
          amount: perPassengerCompensation,
        })}
      </Text>
    </Box>
  );
}

function DisabledCompensation({
  inProgress = false,
}: {
  inProgress?: boolean;
}) {
  const { t } = useTranslation();
  const { id } = useParams();

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  return (
    <Box textAlign="center" maxW="80%">
      <Text
        fontSize="xs"
        color="primary.900"
        fontWeight="bold"
        textTransform="uppercase"
        mb={1}
      >
        {t('claims.compensation.your_results')}
      </Text>
      <Text fontSize="sm" color="primary.900" fontWeight="bold" mb={-2}>
        {inProgress
          ? t('claims.compensation.claim_in_progress')
          : t('claims.compensation.we_are_sorry')}
      </Text>
    </Box>
  );
}

export function Summary({
  perPassengerCompensation,
  totalCompensation,
  eligibleForCompensation,
  inProgress = false,
}: {
  perPassengerCompensation: string;
  totalCompensation: string;
  eligibleForCompensation: boolean;
  inProgress?: boolean;
}) {
  return (
    <Flex
      gap={0}
      h="100%"
      flexDir="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Image
        src={eligibleForCompensation ? claimCompensationBg : noCompensationBg}
        w="256px"
        h="256px"
        display={{
          base: 'none',
          md: 'block',
        }}
        data-testid="compensation-background"
      />

      {eligibleForCompensation ? (
        <TotalCompensation
          perPassengerCompensation={perPassengerCompensation}
          totalCompensation={totalCompensation}
        />
      ) : (
        <DisabledCompensation inProgress={inProgress} />
      )}
    </Flex>
  );
}
