import {
  Box,
  Drawer as ChakraDrawer,
  DrawerContent,
  DrawerOverlay,
  type DrawerContentProps,
  useBreakpointValue,
  Text,
  Flex,
  DrawerBody,
} from '@chakra-ui/react';
import { useEffect, useRef, type PropsWithChildren } from 'react';
import { Delete } from '@airhelp/icons';
import { useLocale } from '../../contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from '../../utils/textDirection/isRtl';

export default function Drawer({
  isOpen,
  onClose,
  children,
  header,
  scrollToBottomOnOpen = false,
  ...props
}: PropsWithChildren<
  {
    header?: string;
    isOpen: boolean;
    scrollToBottomOnOpen?: boolean;
    onClose: () => void;
  } & DrawerContentProps
>) {
  const { locale } = useLocale();
  const horizontalPlacement = isRtl(locale) ? 'start' : 'end';
  const placement: 'bottom' | 'end' | 'start' =
    useBreakpointValue({ base: 'bottom', lg: horizontalPlacement }) ??
    horizontalPlacement;

  const drawerContent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && scrollToBottomOnOpen) {
      const timeout = setTimeout(() => {
        drawerContent?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300); // we need small delay to wait for drawer animation to finish, otherwise drawer content won't be available

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [drawerContent, isOpen]);

  return (
    <ChakraDrawer isOpen={isOpen} onClose={onClose} placement={placement}>
      <DrawerOverlay width="full" h="full" zIndex={300} />
      <DrawerContent
        m={{
          base: 0,
          lg: 4,
        }}
        rounded="xl"
        roundedBottomEnd={{ base: 0, lg: 'xl' }}
        roundedBottomStart={{ base: 0, md: 'xl' }}
        backgroundColor="white"
        minW={{ lg: 500 }}
        {...props}
      >
        <Flex w="100%" position="relative">
          {header ? (
            <Flex
              p={4}
              as="header"
              w="100%"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text color="primary.900" fontSize="sm" fontWeight="bold">
                {header}
              </Text>
            </Flex>
          ) : null}
          <Flex
            pos="absolute"
            top={0}
            right={0}
            zIndex="1000"
            justify="center"
            align="center"
            w={7}
            h={7}
            m={2}
            backgroundColor="greyscale.300"
            borderRadius="full"
            onClick={onClose}
            cursor="pointer"
            data-testid="drawer-close-button"
          >
            <Delete fontSize="xs" color="greyscale.600" />
          </Flex>
        </Flex>
        <DrawerBody p={0}>
          <Box
            w="full"
            h="full"
            rounded="xl"
            roundedBottomEnd={{ base: 0, lg: 'xl' }}
            roundedBottomStart={{ base: 0, md: 'xl' }}
            isolation="isolate"
            overflowY="auto"
            overflowX="hidden"
            sx={{
              '::-webkit-scrollbar-track': {
                background: 'transparent',
                borderRadius: 'md',
              },
              '::-webkit-scrollbar-thumb': {
                background: 'greyscale.500',
                borderRadius: '3xl',
                height: '100px',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: { md: 'greyscale.500' },
              },
              '::-webkit-scrollbar': {
                width: 2,
              },
            }}
          >
            {children}
            <div ref={drawerContent} />
          </Box>
        </DrawerBody>
      </DrawerContent>
    </ChakraDrawer>
  );
}
