import { Suspense } from 'react';
import { useParams } from 'react-router';
import { useClaim } from '@airhelp/webapp/react';
import { Loader } from 'elements';
import { History } from './History/History';

export function ClaimHistoryView() {
  const { id } = useParams();
  const { data: claim, isLoading } = useClaim({
    params: { id: Number(id) },
  });

  if (!claim) {
    return null;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <History />
    </Suspense>
  );
}
