import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info, Ok } from '@airhelp/icons';
import {
  Button,
  Flex,
  Link,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import queryString from 'query-string';
import { useDeleteClaimEnquiry } from '@airhelp/webapp/react';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router';
import ArrowButton from 'elements/ArrowButton';
import { type ClaimEnquiry } from 'models/Claim';
import { LinkTarget } from 'types/utils';
import { useTrackEvent } from 'utils/tracking/hooks';
import { prefetchMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { PAGE_PATHS } from 'config/routes';
import { useIsClaimDetailsFeature } from 'hooks/useClaimDetails';
import DeleteModal from './DeleteModal';

interface IComponent {
  claimEnquiry: ClaimEnquiry;
  sourceParam?: string;
  onDeleteClaimClick?: () => void;
  onCompleteClaimClick?: () => void;
}

const ClaimActions: React.FC<IComponent> = ({
  claimEnquiry,
  onDeleteClaimClick,
  onCompleteClaimClick,
  sourceParam,
}) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { trackCtaClick } = useTrackEvent();
  const queryClient = useQueryClient();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const deleteClaimEnquiry = useDeleteClaimEnquiry();

  const { claimId, id, claimOrEnquiryUrl } = claimEnquiry;
  const { isPending: isLoading, isError } = deleteClaimEnquiry;
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isEnabled: isClaimDetailsFeatureEnabled } =
    useIsClaimDetailsFeature();

  const handleDeleteClaimEnquiry = async () => {
    onDeleteClaimClick && onDeleteClaimClick();

    // retry fetching session before deleting claim, to ensure user is logged in & has csrf token
    await prefetchMyProfile(queryClient);

    deleteClaimEnquiry.mutate(
      { id: claimEnquiry.id },
      {
        onSuccess: () => {
          toast({
            title: (
              <span data-testid="claim-deleted">
                {t('claims.claim_deleted')}
              </span>
            ),
            variant: 'success',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
          });
          onClose();
          trackCtaClick('delete claim button', 'claims page');
        },
        onError: () => {
          toast({
            title: (
              <span data-testid="claim-not-deleted">
                {t('claims.claim_not_deleted')}
              </span>
            ),
            variant: 'error',
            duration: 4000,
            position: isMobile ? 'bottom' : 'top',
            isClosable: true,
            icon: <Info color="greyscale.100" boxSize={5} alignSelf="center" />,
          });
        },
      },
    );
  };

  const onClaimDetailsClick = () => {
    trackCtaClick('claim details', 'claims page');
  };

  const onCloseClick = () => {
    onClose();
    deleteClaimEnquiry.reset();
  };

  const claimRedirectUrl =
    isClaimDetailsFeatureEnabled && claimId
      ? generatePath(PAGE_PATHS.CLAIM.INDEX_PAGE, { id: claimId })
      : queryString.stringifyUrl({
          url: claimOrEnquiryUrl,
          query: { src: sourceParam },
        });

  return (
    <>
      {claimId ? (
        <ArrowButton
          onClick={onClaimDetailsClick}
          externalLink={claimRedirectUrl}
          externalLinkTarget={LinkTarget.SELF}
          data-testid={`btn-claim-details-${claimId}`}
        />
      ) : (
        <Flex
          width={{ base: '100%', md: 'auto' }}
          flexDirection={{ base: 'column', md: 'row' }}
          columnGap={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          {onDeleteClaimClick ? (
            <Button
              variant="subtle"
              size="s"
              whiteSpace="nowrap"
              onClick={onOpen}
              data-testid={`btn-claim-delete-${id}`}
            >
              {t('common.delete')}
            </Button>
          ) : null}
          <Button
            as={Link}
            variant="secondary"
            onClick={onCompleteClaimClick}
            size="sm"
            width={{ base: '100%', md: 'unset' }}
            lineHeight={7}
            href={claimOrEnquiryUrl}
            target={LinkTarget.SELF}
            rel="noopener noreferrer"
            isExternal
            noOfLines={1}
            textAlign="center"
            _visited={{ color: 'primary.500' }}
            data-testid={`btn-claim-complete-${id}`}
          >
            {t('claims.complete_claim')}
          </Button>
          <DeleteModal
            isOpen={isOpen}
            onCloseClick={onCloseClick}
            isLoading={isLoading}
            isError={isError}
            handleDeleteClaimEnquiry={handleDeleteClaimEnquiry}
            externalLink={claimOrEnquiryUrl}
          />
        </Flex>
      )}
    </>
  );
};

export default ClaimActions;
