import { Flex, VStack, Text, Box, Image } from '@chakra-ui/react';
import { ID, MoneyPaper } from '@airhelp/icons';
import { type CountryCode } from '@airhelp/flags';
import { useTranslation } from 'react-i18next';
import bg from 'assets/images/claim-bg.svg';
import { useCompensationData } from 'hooks/useClaimCompensation';
import { ShortInfo } from './parts/ShortInfo';
import { Airport } from './parts/Airport';
import { Separator } from './parts/Separator';

interface ClaimOverviewProps {
  departureAirportCountryCode: CountryCode;
  departureAirportName: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: CountryCode;
  arrivalAirportName: string;
  arrivalAirportCode: string;
  isPayedOut?: boolean;
  claimId: number;
}

export function ClaimOverview({
  departureAirportCountryCode,
  departureAirportName,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportName,
  arrivalAirportCode,
  isPayedOut,
  claimId,
}: ClaimOverviewProps) {
  return (
    <Box
      rounded="3xl"
      boxShadow="lg"
      overflow="hidden"
      backgroundColor="primary.700"
      position="relative"
      height={{
        base: '158px',
        md: '112px',
      }}
      isolation="isolate"
    >
      <Image
        src={bg}
        alt="background"
        filter={{
          base: 'drop-shadow(2px 4px 6px #1E29361F) blur(7px)',
          md: 'drop-shadow(2px 4px 6px #1E29361F) blur(7px)',
        }}
        position="absolute"
        right={{
          base: '-90px',
          sm: '-180px',
          md: '-100px',
        }}
        bottom={{
          base: -2,
          md: 0,
        }}
        width="auto"
        height={{
          base: '240px',
          sm: '220px',
          md: '120px',
        }}
      />
      <Flex
        p={6}
        zIndex={2}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        width="100%"
        justifyContent="space-between"
        flexDirection={{
          base: 'column',
          md: 'row',
        }}
      >
        <FlightSummary
          departureAirportName={departureAirportName}
          departureAirportCountryCode={departureAirportCountryCode}
          departureAirportCode={departureAirportCode}
          arrivalAirportName={arrivalAirportName}
          arrivalAirportCountryCode={arrivalAirportCountryCode}
          arrivalAirportCode={arrivalAirportCode}
        />
        <CompensationInfo isPayedOut={isPayedOut} claimId={claimId} />
      </Flex>
    </Box>
  );
}

interface FlightSummaryProps {
  departureAirportCountryCode: CountryCode;
  departureAirportName: string;
  departureAirportCode: string;
  arrivalAirportCountryCode: CountryCode;
  arrivalAirportName: string;
  arrivalAirportCode: string;
}

function FlightSummary({
  departureAirportCountryCode,
  departureAirportName,
  departureAirportCode,
  arrivalAirportCountryCode,
  arrivalAirportName,
  arrivalAirportCode,
}: FlightSummaryProps) {
  return (
    <VStack
      spacing={2}
      maxW={{
        base: '100%',
        md: '300px',
        xl: '400px',
        '2xl': '320px',
      }}
      flex={1}
    >
      <Flex justifyContent="space-between" w="full">
        <Airport
          airport={departureAirportName}
          airportCountryCode={departureAirportCountryCode}
        />
        <Airport
          direction="row-reverse"
          airport={arrivalAirportName}
          airportCountryCode={arrivalAirportCountryCode}
        />
      </Flex>
      <Flex justifyContent="space-between" w="100%" gap={6}>
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {departureAirportCode}
        </Text>
        <Separator />
        <Text color="greyscale.100" fontSize="30px" fontWeight="bold">
          {arrivalAirportCode}
        </Text>
      </Flex>
    </VStack>
  );
}

interface CompensationInfoProps {
  claimId: number;
  isPayedOut?: boolean;
}

function CompensationInfo({
  claimId,
  isPayedOut = false,
}: CompensationInfoProps) {
  const { t } = useTranslation();

  const { canDisplayCompensation, formattedValues } = useCompensationData({
    claimId: Number(claimId),
  });

  const localCompensation = canDisplayCompensation
    ? formattedValues.finalCompensation
    : 'n/A';

  const compensationValue =
    !isPayedOut && canDisplayCompensation
      ? t('claims.compensation.up_to', {
          amount: localCompensation,
        })
      : localCompensation;

  return (
    <Flex
      boxShadow="0px 20px 40px -8px #1E29361F"
      gap={2}
      justifyContent={{
        base: 'space-between',
        md: 'flex-end',
      }}
    >
      <ShortInfo
        icon={ID}
        label={t('claims.layout.claim_id')}
        value={claimId}
      />
      <ShortInfo
        icon={MoneyPaper}
        reversed
        label={t('claims.layout.compensation')}
        dataTestId="overview-total-compensation"
        value={compensationValue}
      />
    </Flex>
  );
}
