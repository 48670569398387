import React from 'react';
import { useTranslation } from 'react-i18next';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import { ShieldFilled } from '@airhelp/icons';
import { AirhelpPlus } from '@airhelp/logos';
import { Text, HStack, type TextProps } from '@chakra-ui/react';
import { type Journey } from 'models/journey/Journey';

const badgeTextStyles: TextProps = {
  textTransform: 'uppercase',
  color: 'greyscale.700',
  fontWeight: '700',
  fontSize: '11px',
  fontFamily: fontFamilyFraktion,
};

interface IComponent {
  journey: Journey;
  isMobile?: boolean;
}

const ProtectionBadge: React.FC<IComponent> = ({ journey, isMobile }) => {
  const { t } = useTranslation();

  return journey.journeyProtected ? (
    <HStack
      gap={1}
      data-testid={
        isMobile ? 'mobile-protected-by-badge-b2c' : 'protected-by-badge-b2c'
      }
    >
      <ShieldFilled color="success.500" boxSize={5} />
      <Text {...badgeTextStyles}>{t('common.protected_by')}</Text>
      <AirhelpPlus width="56px" height="16px" />
    </HStack>
  ) : (
    <Text
      {...badgeTextStyles}
      data-testid={isMobile ? 'mobile-not-protected-b2c' : 'not-protected-b2c'}
    >
      {t('common.not_protected')}
    </Text>
  );
};

export default ProtectionBadge;
