import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { track } from 'react-tracking';
import DetailsView from 'components/MyAccountView/DetailsView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { usePageTracking } from 'utils/tracking';

const DetailsPage: React.FC = () => {
  usePageTracking();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  return <DetailsView />;
};

export default track({ page_type: 'MyAccount.Details' })(DetailsPage);
