import React from 'react';
import { generatePath, Navigate } from 'react-router';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { Loader } from 'elements';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';
import { ClaimDetailsView } from 'components/Claim/ClaimDetailsView';
import { useClaimDetails } from '../../hooks/useClaimDetails';

const ClaimDetailsPage: React.FC = () => {
  usePageTracking();
  useProtectedPage();

  const { isEnabled, isLoading } = useClaimDetails();

  if (isLoading) {
    return <Loader dataTestId="loader" />;
  }

  if (!isEnabled) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  return <ClaimDetailsView />;
};

export default track({ page_type: 'Claim.Details' })(ClaimDetailsPage);
