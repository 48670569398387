import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, generatePath, useParams } from 'react-router';
import { track } from 'react-tracking';
import FunnelLayout from 'components/Layouts/FunnelLayout';
import { PAGE_PATHS } from 'config/routes';
import StripePaymentContextProvider from 'contexts/StripePaymentContext/StripePaymentContext';
import { TripsFunnel } from 'funnels/TripsFunnel';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';

const TripsUpgradePage = () => {
  usePageTracking();
  useProtectedPage();

  const { t } = useTranslation();
  const { token } = useParams();

  if (!token) {
    return <Navigate to={{ pathname: generatePath(PAGE_PATHS.HOME_PAGE) }} />;
  }

  const steps = [
    t('layouts.trips.account_activated'),
    t('layouts.trips.add_coverage'),
    t('layouts.trips.checkout'),
  ];

  return (
    <StripePaymentContextProvider>
      <FunnelLayout steps={steps}>
        <TripsFunnel token={token} />
      </FunnelLayout>
    </StripePaymentContextProvider>
  );
};

export default track({ page_type: 'Trips' })(TripsUpgradePage);
