import React from 'react';
import { AltArrowBack } from '@airhelp/icons';
import { Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import useLocalStorage from 'hooks/useLocalStorage';
import { isRtl } from 'utils/textDirection/isRtl';

const CollapseButton = () => {
  const { t, i18n } = useTranslation();

  const [layoutCollapse, setLayoutCollapse] =
    useLocalStorage('layout-collapsed');

  const [collapsed, initial] = isRtl(i18n.language)
    ? ['rotate(0deg)', 'rotate(180deg)']
    : ['rotate(180deg)', 'rotate(0deg)'];

  return (
    <Button
      p={{ base: 4, md: 8 }}
      display={{ base: 'none', md: 'none', lg: 'flex' }}
      width={{ base: '100%', md: '24px', lg: '100%' }}
      minW="24px"
      height="24px"
      justifyContent="flex-start"
      leftIcon={
        <AltArrowBack
          boxSize={6}
          color="greyscale.600"
          transition="transform 300ms linear"
          transform={layoutCollapse ? collapsed : initial}
        />
      }
      color="greyscale.600"
      size="sm"
      variant="link"
      onClick={() => {
        setLayoutCollapse(!layoutCollapse);
      }}
      data-testid="collapse-side-nav-button"
      _hover={{ color: 'greyscale.600' }}
    >
      <Text
        display={{
          base: 'none',
          lg: 'flex',
        }}
        fontSize="sm"
        color="greyscale.600"
        opacity={layoutCollapse ? 0 : 1}
        transition="opacity 300ms linear"
      >
        {t('common.hide')}
      </Text>
    </Button>
  );
};

export default CollapseButton;
