import React from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import { type BillingPayment } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';
import CreditCardBlank from 'assets/images/billing/credit-card-blank.png';
import Visa from 'assets/images/billing/visa-logo.svg';
import Mastercard from 'assets/images/billing/mastercard-logo.svg';
import Amex from 'assets/images/billing/amex-logo.svg';
import Discover from 'assets/images/billing/discover-logo.svg';
import Diners from 'assets/images/billing/diners-logo.svg';
import Jcb from 'assets/images/billing/jcb-logo.svg';
import Unionpay from 'assets/images/billing/unionpay-logo.svg';
import AHIcon from 'assets/images/billing/ah-billing-icon.svg';
import Dots from './Dots';

interface IComponent {
  payment: BillingPayment;
}

const CreditCard: React.FC<IComponent> = ({ payment }) => {
  const { t } = useTranslation();

  if (!payment.cardData) {
    return null;
  }

  const {
    cardholderName,
    expirationMonth,
    expirationYear,
    last4Digits,
    brand,
  } = payment.cardData;

  const getBrandIcon = () => {
    switch (brand) {
      case 'mastercard':
        return Mastercard;
      case 'visa':
        return Visa;
      case 'unionpay':
        return Unionpay;
      case 'diners':
        return Diners;
      case 'discover':
        return Discover;
      case 'jcb':
        return Jcb;
      case 'amex':
        return Amex;
      default:
        return AHIcon;
    }
  };

  const brandIcon = getBrandIcon();

  return (
    <Flex
      flexDirection="column"
      borderRadius="3xl"
      p={6}
      maxWidth={{ base: '342px', sm: '312px' }}
      maxHeight={{ base: '100%', sm: '184px' }}
      minHeight={{ base: 'unset', sm: '184px' }}
      minWidth={{ base: 'unset', sm: '312px' }}
      width="100%"
      height="100%"
      backgroundImage={`url(${CreditCardBlank})`}
      backgroundSize={{ base: 'cover', sm: '100% 100%' }}
      backgroundRepeat="no-repeat"
      data-testid="credit-card"
    >
      <Flex width="100%" justifyContent="space-between">
        <Text fontSize="sm" fontWeight="700" color="primary.100">
          {t('payment_management.your_card')}
        </Text>
        <Image src={brandIcon} width="auto" height="auto" />
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        mt={4}
        justifyContent="space-between"
        //credit card number always in ltr direction(also for Arabic)
        dir="ltr"
      >
        <Dots />
        <Dots />
        <Dots />
        <Text
          fontSize="md"
          fontWeight="700"
          color="primary.100"
          data-testid="last-4-digits"
        >
          {last4Digits}
        </Text>
      </Flex>
      <Flex
        width="100%"
        alignItems="center"
        mt={6}
        mb={2}
        justifyContent="space-between"
      >
        <Text fontSize="xs" color="primary.100">
          {t('common.name')}
        </Text>
        <Text fontSize="xs" color="primary.100">
          {t('common.expires')}
        </Text>
      </Flex>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Text
          fontSize="md"
          fontWeight="700"
          color="primary.100"
          data-testid="card-holder-name"
        >
          {cardholderName}
        </Text>
        <Text
          fontSize="md"
          fontWeight="700"
          color="primary.100"
          data-testid="expiration"
        >
          {expirationMonth}/{expirationYear}
        </Text>
      </Flex>
    </Flex>
  );
};

export default CreditCard;
