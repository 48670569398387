import React, {
  Children,
  isValidElement,
  type PropsWithChildren,
  type ReactNode,
  useMemo,
} from 'react';
import {
  Box,
  HStack,
  VStack,
  Text,
  Collapse,
  useDisclosure,
  type BoxProps,
  Flex,
  Tag,
  type TagProps,
} from '@chakra-ui/react';
import { AltArrowExpand, Check, Delete, HamburgerMenu } from '@airhelp/icons';

const ROOT_COMPONENT_NAME = 'VerticalStepTracker';
const STEP_COMPONENT_NAME = `${ROOT_COMPONENT_NAME}.Step`;
const STEP_BADGE_COMPONENT_NAME = `${ROOT_COMPONENT_NAME}.StepBadge`;
const STEP_CONTENT_COMPONENT_NAME = `${ROOT_COMPONENT_NAME}.StepContent`;

const FIRST_STEP_TOP_CONNECTOR_SELECTOR =
  '& [data-vertical-indicator-step]:first-of-type [data-vertical-indicator-bar]:first-of-type';
const LAST_STEP_BOTTOM_CONNECTOR_SELECTOR =
  '& [data-vertical-indicator-step]:last-of-type [data-vertical-indicator-bar]:last-of-type';

export function Root({
  children,
  extendedBorder,
  extendedBorderColor = 'greyscale.400',
}: PropsWithChildren<{
  extendedBorder?: 'top' | 'bottom';
  extendedBorderColor?: string;
}>) {
  return (
    <Box
      sx={{
        [`${FIRST_STEP_TOP_CONNECTOR_SELECTOR}, ${LAST_STEP_BOTTOM_CONNECTOR_SELECTOR}`]:
          {
            backgroundPosition: 'left',
            backgroundSize: '1.5px 10px',
            backgroundRepeat: 'repeat-y',
            backgroundColor: extendedBorderColor,
            width: '1.5px',
          },
        ...((extendedBorder === 'bottom' || !extendedBorder) && {
          [FIRST_STEP_TOP_CONNECTOR_SELECTOR]: {
            backgroundImage: 'none',
            backgroundColor: 'transparent',
          },
        }),
        ...((extendedBorder === 'top' || !extendedBorder) && {
          [LAST_STEP_BOTTOM_CONNECTOR_SELECTOR]: {
            backgroundImage: 'none',
            backgroundColor: 'transparent',
          },
        }),
      }}
    >
      {children}
    </Box>
  );
}

type StepState = 'completed' | 'failed' | 'upcoming' | 'paused';

const STEP_INDICATOR_COLOR = {
  completed: 'success.600',
  failed: 'danger.500',
  upcoming: 'greyscale.400',
  paused: 'primary.600',
};

export function Step({
  children,
  header,
  subheader,
  state,
  active,
  initiallyOpen = false,
}: PropsWithChildren<{
  header: string;
  subheader?: string;
  state?: string;
  active?: boolean;
  initiallyOpen?: boolean;
}>) {
  const { child: content, childExists: hasContent } = useNamedChild(
    children,
    STEP_CONTENT_COMPONENT_NAME,
  );
  const { child: badge, childExists: hasBadge } = useNamedChild(
    children,
    STEP_BADGE_COMPONENT_NAME,
  );
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: initiallyOpen,
  });

  return (
    <HStack alignItems="stretch" data-vertical-indicator-step gap={0}>
      <VStack ps={4} pe={hasContent ? '18px' : 0}>
        <StepStatusIndicatorConnector
          color={STEP_INDICATOR_COLOR[state ?? 'upcoming']}
        />
        <StepStatusIndicator state={state ?? 'upcoming'} active={active} />
        <StepStatusIndicatorConnector
          color={STEP_INDICATOR_COLOR[state ?? 'completed']}
        />
      </VStack>
      <Box
        cursor={hasContent ? 'pointer' : 'auto'}
        backgroundColor={hasContent ? 'greyscale.300' : 'transparent'}
        py={4}
        ps={4}
        pe={9}
        borderRadius="md"
        flex={1}
        w="full"
        maxW="calc(100% - 50px)"
        position="relative"
        onClick={hasContent ? onToggle : undefined}
        my={2}
      >
        {hasContent ? (
          <AltArrowExpand
            position="absolute"
            top={0}
            right={0}
            mt={4}
            me={4}
            color="primary.500"
            w={6}
            h={6}
            transition="transform 0.2s"
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          />
        ) : null}

        <VStack alignItems="start" gap={1}>
          <HStack gap={2}>
            <Text fontSize="sm" color="primary.900" fontWeight="semibold">
              {header}
            </Text>

            {hasBadge && !hasContent ? badge : null}
          </HStack>
          {hasBadge && hasContent ? badge : null}
          {subheader ? (
            <Text fontSize="xs" color="greyscale.600">
              {subheader}
            </Text>
          ) : null}
        </VStack>
        {hasContent ? (
          <Collapse in={isOpen}>
            <Box pt={4}>{content}</Box>
          </Collapse>
        ) : null}
      </Box>
    </HStack>
  );
}

Step.displayName = STEP_COMPONENT_NAME;

function StepStatusIndicatorConnector({
  color = 'greyscale.400',
}: {
  color?: string;
}) {
  return (
    <Box
      flex={1}
      backgroundColor={color}
      w="1.5px"
      data-vertical-indicator-bar
    />
  );
}

const stepIndicatorStyles: Record<StepState, BoxProps> = {
  completed: {
    backgroundColor: 'success.600',
  },
  failed: {
    backgroundColor: 'danger.500',
  },
  upcoming: {
    backgroundColor: 'transparent',
    borderColor: 'greyscale.400',
    borderWidth: 2,
  },
  paused: {
    backgroundColor: 'primary.600',
  },
};

const activeIndicatorShadows: Record<StepState, string> = {
  completed: '0px 0px 0px 4px rgba(0, 174, 130, 0.20)',
  failed: '0px 0px 0px 4px var(--chakra-colors-danger-200)',
  paused: '0px 0px 0px 4px var(--chakra-colors-primary-300)',
  upcoming: '0px 0px 0px 4px var(--chakra-colors-greyscale-300)',
};

function StepStatusIndicator({
  state,
  active,
}: {
  state: string;
  active?: boolean;
}) {
  const styles = stepIndicatorStyles[state];
  const activeIndicatorShadow = activeIndicatorShadows[state];

  if (state === 'upcoming' && active) {
    return (
      <Box
        w={3}
        h={3}
        mx={0.5}
        backgroundColor="primary.600"
        borderRadius="full"
        borderWidth={2}
        borderColor="white"
        boxShadow="0 0 0 2px var(--chakra-colors-primary-600)"
      />
    );
  }

  if (state === 'paused') {
    return (
      <Flex
        justify="center"
        align="center"
        w={4}
        h={4}
        borderRadius="full"
        borderWidth={styles.borderWidth}
        borderColor={styles.borderColor}
        backgroundColor={styles.backgroundColor}
        boxShadow={active ? activeIndicatorShadow : 'none'}
      >
        <HamburgerMenu color="white" transform="rotate(90deg)" fontSize="7px" />
      </Flex>
    );
  }

  return (
    <Flex
      justify="center"
      align="center"
      w={4}
      h={4}
      borderRadius="full"
      borderWidth={styles.borderWidth}
      borderColor={styles.borderColor}
      backgroundColor={styles.backgroundColor}
      boxShadow={active ? activeIndicatorShadow : 'none'}
    >
      {state !== 'upcoming' &&
        (state === 'completed' ? (
          <Check fontSize="xs" color="white" />
        ) : (
          <Delete fontSize="10px" color="white" />
        ))}
    </Flex>
  );
}

export function StepContent({ children }: PropsWithChildren) {
  return children;
}

StepContent.displayName = STEP_CONTENT_COMPONENT_NAME;

export function StepBadge(props: PropsWithChildren<TagProps>) {
  return (
    <Tag
      size="xs"
      minH="auto"
      justifyContent="center"
      lineHeight={1}
      {...props}
    />
  );
}

StepBadge.displayName = STEP_BADGE_COMPONENT_NAME;

// TODO: Extract this hook into react system utils
function useNamedChild(children: ReactNode, name: string) {
  return useMemo(() => {
    const child = Children.toArray(children).find(
      (c) => isValidElement(c) && getComponentDisplayName(c) === name,
    );

    return {
      child,
      childExists: !!child,
    };
  }, [children, name]);
}

function getComponentDisplayName(element: React.ReactElement) {
  const node = element as React.ReactElement<React.ComponentType<unknown>>;
  const type = (node as unknown as React.ReactElement<React.FunctionComponent>)
    .type;
  const displayName =
    typeof type === 'function'
      ? (type as React.FunctionComponent).displayName ||
        (type as React.FunctionComponent).name ||
        'Unknown'
      : type;
  return displayName;
}
