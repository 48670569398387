import React from 'react';
import { AltArrowForward } from '@airhelp/icons';
import { Flex, IconButton } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { PAGE_PATHS } from 'config/routes';
import { isRtl } from 'utils/textDirection/isRtl';

const MobileActionBar = () => {
  const { locale } = useLocale();
  const navigate = useNavigate();
  const { mobileHeaderConfig } = useLayout();

  const handleBackButtonClick = () => {
    const backRoute = mobileHeaderConfig?.backPath || PAGE_PATHS.HOME_PAGE;

    navigate(backRoute);
  };

  return (
    <Flex
      as="header"
      position="relative"
      width="100%"
      px={4}
      py={5}
      zIndex="10"
      backgroundColor="greyscale.300"
    >
      <Flex width="20%" alignItems="center" justifyContent="flex-start">
        <IconButton
          aria-label="back"
          icon={
            <AltArrowForward
              fontSize="xl"
              transform={`rotate(${isRtl(locale) ? '0' : '180deg'})`}
            />
          }
          onClick={handleBackButtonClick}
          size="md"
          color="greyscale.600"
          borderRadius="50%"
          bg="greyscale.100"
          _active={{ bgColor: 'primary.200', boxShadow: 'none' }}
          _hover={{ bgColor: 'primary.200' }}
          data-testid="back-to"
        />
      </Flex>
      <Flex
        width="60%"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        color="primary.900"
        fontSize={{ base: 'sm', sm: 'md' }}
        fontWeight="bold"
      >
        {mobileHeaderConfig?.title}
      </Flex>
      {mobileHeaderConfig?.actionSpace ? (
        <Flex width="20%" alignItems="center" justifyContent="flex-end" me={3}>
          {mobileHeaderConfig?.actionSpace}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default MobileActionBar;
