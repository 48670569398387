import { type IconType } from '@airhelp/icons';
import {
  Flex,
  VStack,
  Text,
  Circle,
  useBreakpointValue,
} from '@chakra-ui/react';
import { fontFamilyFraktion } from '@airhelp/design-tokens';

interface ShortInfoProps {
  icon: IconType;
  label: string;
  value: string | number;
  reversed?: boolean;
  dataTestId?: string;
}

const GLASS_EFFECT_BASE_STYLE = {
  position: 'relative',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%)',
  backdropFilter: 'blur(14px)',
  gap: '12px',
};

const GLASS_EFFECT_BEFORE_BASE_ELEMENT_STYLE = {
  content: '""',
  position: 'absolute',
  inset: 0,
  padding: '1px',
  background:
    'linear-gradient(146.69deg, rgba(255, 255, 255, 0.4) 7.36%, rgba(255, 255, 255, 0) 27.24%, rgba(255, 255, 255, 0.2) 93.77%)',
  WebkitMask:
    'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
  WebkitMaskComposite: 'xor',
  maskComposite: 'exclude',
  pointerEvents: 'none',
};

function IconWrapper({ children }) {
  return (
    <Circle
      size={8}
      alignSelf="center"
      textAlign="center"
      sx={{
        ...GLASS_EFFECT_BASE_STYLE,
        '&::before': {
          ...GLASS_EFFECT_BEFORE_BASE_ELEMENT_STYLE,
          rounded: 'full',
        },
      }}
    >
      {children}
    </Circle>
  );
}

export function ShortInfo({
  icon: Icon,
  label,
  value,
  reversed = false,
  dataTestId,
}: ShortInfoProps) {
  const styles = useBreakpointValue({
    base: {
      sx: undefined,
    },
    md: {
      sx: {
        ...GLASS_EFFECT_BASE_STYLE,
        rounded: '2xl',
        '&::before': {
          ...GLASS_EFFECT_BEFORE_BASE_ELEMENT_STYLE,
          rounded: '2xl',
        },
      },
    },
  });

  return (
    <Flex
      {...styles}
      p={{
        base: 0,
        md: '16px 18px',
      }}
      gap={{
        base: 2,
        md: 3,
      }}
      alignItems="center"
      flexDirection={{
        base: reversed ? 'row-reverse' : 'row',
        md: 'row',
      }}
    >
      <IconWrapper>
        <Icon color="greyscale.100" w={4} h={4} />
      </IconWrapper>

      <VStack spacing={0} alignItems={reversed ? 'flex-end' : 'flex-start'}>
        <Text
          color={{
            base: 'greyscale.500',
            md: 'greyscale.400',
          }}
          fontSize="11px"
          textTransform="uppercase"
          letterSpacing="-0.014em"
          fontWeight="bold"
          fontFamily={fontFamilyFraktion}
        >
          {label}
        </Text>
        <Text
          color="greyscale.100"
          fontSize="md"
          data-testid={dataTestId}
          textTransform="uppercase"
          letterSpacing="-0.014em"
          fontWeight="bold"
          fontFamily={fontFamilyFraktion}
        >
          {value}
        </Text>
      </VStack>
    </Flex>
  );
}
