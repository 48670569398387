import React, { useContext } from 'react';
import { Grid } from '@chakra-ui/react';
import {
  Crossroad,
  Panels,
  PanelsFilled,
  Chair,
  ChairFilled,
  Shield,
  ShieldFilled,
} from '@airhelp/icons';
import { type UserPolicy } from '@airhelp/plus';
import { useTracking } from 'react-tracking';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router';
import { PAGE_PATHS } from 'config/routes';
import useLocalStorage from 'hooks/useLocalStorage';
import { TrackingContext } from 'contexts';
import { useTrackEvent } from 'utils/tracking/hooks';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import NavItem, { type NavBarItem } from './NavItem/NavItem';

enum NavigationElements {
  HOME = 'Home',
  JOURNEYS = 'Journeys',
  CLAIMS = 'Claims',
  LOUNGES = 'Lounges',
}

interface IComponent {
  userProfilePolicy?: UserPolicy;
}

const Navigation: React.FC<IComponent> = ({ userProfilePolicy }) => {
  const tracking = useTracking();
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { subPageType } = useContext(TrackingContext);
  const location = useLocation();
  const { data: loungePasses } = useFetchLoungePasses();
  const { dashboard } = userProfilePolicy || {};
  const [layoutCollapse] = useLocalStorage('layout-collapsed');

  const upsellEnabled = dashboard?.upsellEnabled;
  const loungesEnabled =
    dashboard?.loungesPerkEnabled || (loungePasses || []).length > 0;

  const trackClickEvent = (element: NavigationElements, label: string) => {
    tracking.trackEvent({
      element,
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_dashboard_navigation_clicked',
    });
    trackCtaClick(label, subPageType);
  };

  const navBarItems: NavBarItem[] = [
    {
      label: t('navigation.home'),
      icon: Panels,
      hoverIcon: PanelsFilled,
      route: generatePath(PAGE_PATHS.HOME_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.HOME, 'home section');
      },
      dataTestId: 'home',
    },
    {
      label: t('navigation.trips'),
      icon: Crossroad,
      hoverIcon: Crossroad,
      route: generatePath(PAGE_PATHS.JOURNEYS_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.JOURNEYS, 'journeys section');
      },
      dataTestId: 'journeys',
    },
    {
      label: t('navigation.claims'),
      icon: Shield,
      hoverIcon: ShieldFilled,
      route: generatePath(PAGE_PATHS.CLAIMS_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.CLAIMS, 'claims section');
      },
      dataTestId: 'claims',
    },
  ];

  if (loungesEnabled || upsellEnabled) {
    navBarItems.push({
      label: t('navigation.lounges'),
      icon: Chair,
      hoverIcon: ChairFilled,
      route: generatePath(PAGE_PATHS.LOUNGES_PAGE),
      action: () => {
        trackClickEvent(NavigationElements.LOUNGES, 'lounges section');
      },
      dataTestId: 'lounges',
    });
  }

  return (
    <Grid
      p={{ base: 4, md: 8 }}
      height="100%"
      width={{
        base: '92%',
        sm: '400px',
        md: '24px',
        lg: layoutCollapse ? '24px' : '100%',
      }}
      gridTemplateColumns={{ base: 'repeat(4, minmax(0, 1fr))', md: '1fr' }}
      alignItems={{ base: 'center', md: 'initial' }}
      alignContent={{ base: 'unset', md: 'flex-start' }}
      justifyContent={{ base: 'space-between', md: 'flex-start' }}
      overflowX={{ base: 'scroll', lg: 'initial' }}
      gap={{ base: 2, md: 8 }}
      css={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
      transition="width 300ms linear"
    >
      {navBarItems.map((item) => (
        <NavItem item={item} key={`${item.label}.${location.pathname}`} />
      ))}
    </Grid>
  );
};

export default Navigation;
