import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { track } from 'react-tracking';
import PassesView from 'components/LoungesView/PassesView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { Loader } from 'elements';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import { usePageTracking } from 'utils/tracking';

const PassesPage: React.FC = () => {
  usePageTracking();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.lounge_passes'),
    backPath: generatePath(PAGE_PATHS.LOUNGES_PAGE),
  });

  const { isLoading: isLoungePassesLoading, data: loungePassesData } =
    useFetchLoungePasses();
  const loungePasses = loungePassesData || [];

  return isLoungePassesLoading ? (
    <Loader />
  ) : (
    <PassesView loungePasses={loungePasses} />
  );
};

export default track({ page_type: 'Lounges.Passes' })(PassesPage);
