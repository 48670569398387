import React from 'react';
import {
  generatePath,
  Navigate,
  useParams,
  useSearchParams,
} from 'react-router';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { usePageTracking } from 'utils/tracking';

const IndexPage = () => {
  const { token } = useParams();
  const [searchParams] = useSearchParams();

  usePageTracking();

  return (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.UPSELL.TRIPS.CHECKOUT_PAGE, {
          token,
        }),
        search: searchParams.toString(),
      }}
    />
  );
};

export default track({ page_type: 'Upsell.Trips.Index' })(IndexPage);
