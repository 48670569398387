import React, { useContext } from 'react';
import { NavLink, generatePath } from 'react-router';
import { Flex, Link } from '@chakra-ui/react';
import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useHideSideNav from 'hooks/routing/useHideSideNav';
import useLocalStorage from 'hooks/useLocalStorage';
import { useTrackEvent } from 'utils/tracking/hooks';
import AirHelpHeaderLogo from './AirHelpHeaderLogo';

const ResponsiveLogoLink: React.FC = () => {
  const { subPageType } = useContext(TrackingContext);
  const { userProfile } = useMyProfile();
  const { trackCtaClick } = useTrackEvent();
  const hideSideNav = useHideSideNav();

  const [layoutCollapse] = useLocalStorage('layout-collapsed');

  const onClick = () => {
    trackCtaClick('ahp logo', subPageType);
  };

  return (
    <Flex
      width={{
        base: '100%',
        md: '88px',
        lg: layoutCollapse ? '88px' : '278px',
      }}
      borderRight={
        hideSideNav ? 'initial' : { base: 'initial', md: '1px solid' }
      }
      borderRightColor={
        hideSideNav ? 'initial' : { base: 'initial', md: 'greyscale.400' }
      }
      px={{ base: 4, md: 8 }}
      py={4}
      alignItems="end"
      transition="width 300ms linear"
    >
      <Link
        as={NavLink}
        to={generatePath(PAGE_PATHS.HOME_PAGE)}
        onClick={onClick}
        display="flex"
        justifyContent="flex-start"
        alignItems="flex-end"
        sx={{ svg: { boxSize: 'unset', lineHeight: 'unset' } }}
      >
        <AirHelpHeaderLogo userProfile={userProfile} />
      </Link>
    </Flex>
  );
};

export default ResponsiveLogoLink;
