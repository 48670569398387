import React, { useState } from 'react';
import { generatePath, Navigate } from 'react-router';
import { track } from 'react-tracking';
import { InsuranceType } from '@airhelp/plus';
import AirLuggagePayoutConfirmationView from 'components/Journey/AirLuggage/PayoutConfirmationView';
import AirPayoutPayoutConfirmationView from 'components/Journey/AirPayout/PayoutConfirmationView';
import { PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { type ComponentPageProps } from 'funnels/AirBundleFunnel';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePageTracking } from 'utils/tracking';
import { pascalCase } from 'utils/pascalCase';

const PayoutConfirmationPage: React.FC<ComponentPageProps> = ({
  journeyId,
  insuranceType,
}) => {
  const { setActiveStep } = useLayout();
  const [nextStep, setNextStep] = useState(false);
  usePageTracking();

  useEffectOnce(() => {
    setActiveStep(4);
  });

  const handleNextStep = () => {
    setNextStep(true);
  };

  if (nextStep) {
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.JOURNEY_PAGE, { id: journeyId }),
        }}
      />
    );
  }

  const PayoutConfirmationView = (() => {
    switch (insuranceType) {
      case InsuranceType.AIR_LUGGAGE:
        return AirLuggagePayoutConfirmationView;
      case InsuranceType.AIR_PAYOUT:
      default:
        return AirPayoutPayoutConfirmationView;
    }
  })();

  return <PayoutConfirmationView onStepComplete={handleNextStep} />;
};

export default track({
  page_type: (props: ComponentPageProps) =>
    `Journey.${pascalCase(props.insuranceType)}.PayoutConfirmation`,
})(PayoutConfirmationPage);
