import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as ReactLink } from 'react-router';
import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import queryString from 'query-string';
import { shadowsMd } from '@airhelp/design-tokens';
import BackButton from 'components/LoungeFinder/BackButton';
import InfoBox from 'components/LoungeFinder/InfoBox';
import { Loader } from 'elements';
import { type AirportLounge } from 'models/AirportLounge';
import { useTrackEvent } from 'utils/tracking/hooks';

interface IComponent {
  lounges: AirportLounge[];
}

const AirportLoungesView: React.FC<IComponent> = ({ lounges }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { trackCtaClick } = useTrackEvent();

  const urlParams = queryString.parse(search);
  const showBackButton = !(urlParams.back_search === 'off');

  return lounges.length > 0 ? (
    <>
      <Box
        mt={{ base: 4, sm: 4, lg: 8 }}
        mb={{ base: 4, sm: 0 }}
        mx={{ base: 0, md: 16, lg: 'auto' }}
        w={{ base: 'none', md: 'calc(100vw - 128px)', lg: '770px' }}
      >
        {showBackButton ? (
          <BackButton
            url={`/lounge-finder${search}`}
            label={t('lounge_finder.en_go_back_to_search')}
            onBackClick={() => {
              trackCtaClick('go back to search', 'lounge finder');
            }}
          />
        ) : null}
      </Box>
      <Container
        mb={{ base: 4, sm: 0 }}
        mx={{ base: 0, sm: 4, md: 16, lg: 'auto' }}
        p={{ base: 4, md: 10 }}
        bgColor="greyscale.100"
        w={{ base: 'auto', md: 'calc(100vw - 128px)', lg: '770px' }}
        maxW={{ base: 'none', lg: '770px' }}
        borderRadius={{ base: 'none', sm: 'xl' }}
        boxShadow={{ base: 'none', sm: shadowsMd }}
      >
        <Flex
          textAlign="center"
          alignItems="center"
          flexDirection="column"
          pt={4}
          pb={4}
        >
          <Text
            fontSize="xs"
            fontWeight="bold"
            textTransform="uppercase"
            color="primary.500"
            mb={2}
          >
            {t('lounge_finder.en_airhelp_lounge_finder')}
          </Text>

          <Heading variant="h4" mb={8}>
            {lounges[0].airportName}, {lounges[0].airportCode}
          </Heading>

          <Divider
            borderColor="greyscale.400"
            mb={10}
            display={{ base: 'none', md: 'block' }}
          />

          <SimpleGrid
            minChildWidth="208px"
            justifyItems="center"
            w="100%"
            spacing={8}
          >
            {lounges?.map((lounge) => (
              <Flex
                direction="column"
                w="100%"
                maxW={{ base: '100%', sm: '276px' }}
                key={`lounge-${lounge.loungeCode}`}
              >
                <Link
                  as={ReactLink}
                  to={{
                    pathname: `/lounge-finder/airport/${lounge.airportCode}/lounge/${lounge.loungeCode}`,
                    search,
                  }}
                  fontWeight="400"
                  fontSize="sm"
                  display="flex"
                  flexDirection="column"
                  color="greyscale.600"
                  h="100%"
                  _hover={{ textUnderline: 'none' }}
                >
                  {lounge.images[0] ? (
                    <Image
                      borderRadius="md"
                      bg="greyscale.400"
                      objectFit="cover"
                      w="100%"
                      maxH={{
                        base: '146px',
                        sm: 'calc(100vw * 0.67)',
                        md: '154px',
                      }}
                      src={lounge.images[0].url}
                      alt={lounge.images[0].altText}
                      title={lounge.images[0].altText}
                      mb={4}
                      key={`lounge-${lounge.loungeCode}`}
                      fallback={
                        <Flex
                          color="primary.900"
                          w="100%"
                          height="100%"
                          minH={{
                            base: '146px',
                            sm: 'calc(100vw * 0.67)',
                            md: '154px',
                          }}
                          alignItems="center"
                          justifyContent="center"
                          bg="greyscale.400"
                          borderRadius="md"
                        >
                          <Loader size={20} />
                        </Flex>
                      }
                    />
                  ) : (
                    <Flex
                      borderRadius="md"
                      justifyContent="center"
                      alignItems="center"
                      bg="greyscale.400"
                      color="greyscale.500"
                      w="100%"
                      h="100%"
                      minH="160px"
                      mb={4}
                      key={`lounge-${lounge.loungeCode}`}
                    >
                      There is no picture
                    </Flex>
                  )}
                  <Text
                    fontSize="sm"
                    fontWeight="medium"
                    color="primary.900"
                    mt={2}
                    _hover={{ color: 'greyscale.600' }}
                  >
                    {lounge.loungeName}
                  </Text>
                </Link>
              </Flex>
            ))}
          </SimpleGrid>
        </Flex>
      </Container>
    </>
  ) : (
    <InfoBox
      content={t('lounge_finder.en_lounge_not_available_at_location')}
      labelButton={t('lounge_finder.en_go_back_to_search')}
      urlButton={`/lounge-finder${search}`}
    />
  );
};

export default AirportLoungesView;
