import { env } from 'config';

export const PAGE_PATHS = {
  CLAIMS_PAGE: '/claims',
  CLAIM: {
    INDEX_PAGE: '/claims/:id',
    COMPENSATION_PAGE: '/claims/:id/compensation',
    DOCUMENTS_PAGE: '/claims/:id/documents',
    DETAILS_PAGE: '/claims/:id/details',
    HISTORY_PAGE: '/claims/:id/history',
  },
  HOME_PAGE: '/',
  SUPPORT_PAGE: '/support',
  MY_ACCOUNT_PAGE: '/my-account',
  MY_ACCOUNT: {
    DETAILS_PAGE: '/my-account/details',
    NOTIFICATIONS_PAGE: '/my-account/notifications',
    BENEFITS_PAGE: '/my-account/benefits',
    PLAN_MANAGEMENT_PAGE: '/my-account/plan-management',
    SPECIAL_OFFER_PAGE: '/my-account/special-offer',
    BILLING: {
      BILLING_PAGE: '/my-account/billing',
      PROCESSING_PAGE: '/my-account/billing/processing',
    },
  },
  LOGIN_PAGE: '/login',
  SIGNUP_PAGE: '/signup',
  RESET_PASSWORD_PAGE: '/reset-password',
  SET_PASSWORD_PAGE: '/set-password',
  FLIGHTS: {
    NEW_PAGE: '/flights/new',
    NEW: {
      ROUTE_DESTINATION_PAGE: '/flights/new/route-destination',
      CONNECTING_FLIGHTS_PAGE: '/flights/new/connecting-flights',
      FLIGHTS_DETAILS_PAGE: '/flights/new/flights-details',
      RETURN_FLIGHT_PAGE: '/flights/new/return-flight',
      AIR_LUGGAGE_PAGE: '/flights/new/air-luggage',
    },
  },
  LOUNGE_FINDER_PAGE: '/lounge-finder',
  LOUNGE_FINDER: {
    AIRPORT_PAGE: '/lounge-finder/airport/:airport_code',
    LOUNGE_PAGE: '/lounge-finder/airport/:airport_code/lounge/:lounge_id',
  },
  LOUNGES_PAGE: '/lounges',
  LOUNGES: {
    PASSES_PAGE: '/lounges/passes',
  },
  MEMBERSHIPS_PAGE: '/memberships',
  MEMBERSHIPS: {
    BUY_PAGE: '/memberships/buy',
    BUY: {
      EMAIL_PAGE: '/memberships/buy/email',
      CHECKOUT_PAGE: '/memberships/buy/checkout',
      PROCESSING_PAYMENT_PAGE: '/memberships/buy/processing-payment',
      PAYMENT_SUCCEEDED_PAGE: '/memberships/buy/payment-succeeded',
    },
  },
  JOURNEY_PAGE: '/journey/:id',
  JOURNEYS_PAGE: '/journeys',
  JOURNEY: {
    FLIGHTS_PAGE: '/journey/:id/flights',
    INSURANCE_PAGE: '/journey/:id/insurance',
    AIR_PAYOUT: {
      PAYOUT_PAGE: '/journey/:id/air-payout/payout',
      VERIFF_PAGE: '/journey/:id/air-payout/veriff',
      PAYOUT_UPDATE_PAGE: '/journey/:id/air-payout/payout-update',
      PAYOUT_CONFIRMATION_PAGE: '/journey/:id/air-payout/payout-confirmation',
      FUNNEL_PAYOUT: '/journey/:id/air-payout',
    },
    AIR_LUGGAGE: {
      PAYOUT_PAGE: '/journey/:id/air-luggage/payout',
      VERIFF_PAGE: '/journey/:id/air-luggage/veriff',
      PAYOUT_UPDATE_PAGE: '/journey/:id/air-luggage/payout-update',
      PAYOUT_CONFIRMATION_PAGE: '/journey/:id/air-luggage/payout-confirmation',
      FUNNEL_PAYOUT: '/journey/:id/air-luggage',
    },
  },
  TRIP_REGISTRATION_PAGE: '/trip-registration/:uuid/:token',
  TRIPS_PAGE: '/trips/:token',
  TRIPS: {
    ACTIVATE_PAGE: '/trips/:token/activate',
    UPGRADE_PAGE: '/trips/:token/upgrade',
    UPGRADE: {
      ANNUAL_PAGE: '/trips/:token/upgrade/annual',
      COMPLETE_PAGE: '/trips/:token/upgrade/complete',
      CHECKOUT_PAGE: '/trips/:token/upgrade/checkout',
      PROCESSING_PAYMENT_PAGE: '/trips/:token/upgrade/processing-payment',
      PAYMENT_SUCCEEDED_PAGE: '/trips/:token/upgrade/payment-succeeded',
    },
  },
  UPSELL_PAGE: '/upsell',
  UPSELL: {
    TRIPS_PAGE: '/upsell/trips/:token',
    TRIPS: {
      CHECKOUT_PAGE: '/upsell/trips/:token/checkout',
      PROCESSING_PAYMENT_PAGE: '/upsell/trips/:token/processing-payment',
      PAYMENT_SUCCEEDED_PAGE: '/upsell/trips/:token/payment-succeeded',
    },
  },
  REDIRECT_PAGE: '/r',
  ERROR_PAGE: '/error',
  NOT_FOUND_PAGE: '*',
};

export const EXTERNAL_ROUTES = {
  PAYOUT_FUNNEL: {
    CLAIM_PAYOUT_URL: (id: string | number) =>
      `${env.VITE_PAYOUT_FUNNEL_URL}/claims/${id}/payout`,
  },
  DOCUMENT_FUNNEL: {
    DOCUMENT_PROCUREMENT_URL: (id: string | number) =>
      `${env.VITE_DOCUMENT_FUNNEL_URL}/claims/${id}/documents-procurement`,
  },
};

export const ALL_AVAILABLE_ROUTES = [
  PAGE_PATHS.CLAIMS_PAGE,
  `${PAGE_PATHS.CLAIMS_PAGE}/*`,
  PAGE_PATHS.HOME_PAGE,
  `${PAGE_PATHS.JOURNEY_PAGE}/*`,
  PAGE_PATHS.JOURNEYS_PAGE,
  PAGE_PATHS.LOGIN_PAGE,
  `${PAGE_PATHS.LOUNGE_FINDER_PAGE}/*`,
  `${PAGE_PATHS.LOUNGES_PAGE}/*`,
  `${PAGE_PATHS.MEMBERSHIPS_PAGE}/*`,
  `${PAGE_PATHS.MY_ACCOUNT_PAGE}/*`,
  PAGE_PATHS.REDIRECT_PAGE,
  PAGE_PATHS.SET_PASSWORD_PAGE,
  PAGE_PATHS.SIGNUP_PAGE,
  PAGE_PATHS.SUPPORT_PAGE,
  `${PAGE_PATHS.TRIPS_PAGE}/*`,
  `${PAGE_PATHS.UPSELL_PAGE}/*`,
  PAGE_PATHS.ERROR_PAGE,
  PAGE_PATHS.NOT_FOUND_PAGE,
];
