import { type ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from 'components/Drawer';
import { TravelDetails } from './TravelDetails';

export function TravelDetailsDrawer({
  isOpen,
  onClose,
  flights,
}: {
  isOpen: boolean;
  onClose: () => void;
  flights: ComponentProps<typeof TravelDetails>['flights'];
}) {
  const { t } = useTranslation();

  const connectingFlightsLabel =
    flights.length > 1
      ? t('claims.flight_details.connecting_flights', {
          count: flights.length,
        })
      : t('claims.flight_details.direct_flight');

  return (
    <Drawer
      header={t('claims.flight_details.travel_details')}
      isOpen={isOpen}
      onClose={onClose}
      ps={{
        base: 4,
        md: 8,
      }}
      pe={{
        base: 3,
        md: 6,
      }}
      pt={4}
      pb={8}
    >
      <TravelDetails
        header={`${flights.at(0)?.departureAirport.city} - ${flights.at(-1)?.arrivalAirport.city}`}
        i18n={{
          connectingFlightsCountText: connectingFlightsLabel,
          connectionSeparatorText: t('claims.flight_details.connecting_flight'),
        }}
        flights={flights}
      />
    </Drawer>
  );
}
