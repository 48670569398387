import type { JSX } from 'react';
import {
  Text,
  Flex,
  HStack,
  Box,
  VStack,
  WrapItem,
  Link,
  type SystemStyleObject,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import {
  Airplane,
  BoxImportant,
  Law,
  Compensation,
  OpenInNew,
  AltArrowForward,
} from '@airhelp/icons';
import { useTranslation } from 'react-i18next';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';
import config from 'config';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useCompensationData } from 'hooks/useClaimCompensation';
import { Tooltip } from './Tooltip';

interface RowProps {
  freeOfCharge: boolean;
  label: string;
  value: string;
  icon: JSX.Element;
  tooltipTitle?: string;
  dataTestId?: string;
  tooltipDescription?: string;
  textStyle?: SystemStyleObject;
}

function Row({
  freeOfCharge,
  label,
  value,
  tooltipDescription,
  tooltipTitle,
  icon,
  dataTestId,
  textStyle,
}: RowProps) {
  const { t } = useTranslation();
  const showTooltip = Boolean(tooltipTitle && tooltipDescription);

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      data-testid={dataTestId}
      mb={3}
    >
      <WrapItem alignItems="center" gap={1}>
        <Box
          width="40px"
          height="40px"
          rounded="full"
          backgroundColor="primary.100"
          display="flex"
          alignItems="center"
          justifyContent="center"
          me={2}
        >
          {icon}
        </Box>
        <Text fontSize="sm" color="primary.900" sx={textStyle}>
          {label}
        </Text>
        {showTooltip ? (
          <Tooltip title={tooltipTitle} dataTestId={dataTestId}>
            {tooltipDescription}
          </Tooltip>
        ) : null}
      </WrapItem>
      {freeOfCharge ? (
        <Text fontSize="sm" color="success.500">
          {t('claims.compensation.no_fees')}
        </Text>
      ) : (
        <Text fontSize="sm" color="primary.900" sx={textStyle}>
          {value}
        </Text>
      )}
    </HStack>
  );
}

function getCompensationValue({
  canDisplayCompensation,
  absoluteValue,
  isPositive = true,
}: {
  canDisplayCompensation: boolean;
  absoluteValue: string;
  isPositive?: boolean;
}) {
  if (!canDisplayCompensation) {
    return 'n/A';
  }

  return isPositive ? absoluteValue : `- ${absoluteValue}`;
}

export function Table() {
  const { id } = useParams();
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const {
    legalFee,
    serviceFee,
    freeOfCharge,
    formattedValues,
    canDisplayCompensation,
  } = useCompensationData({ claimId: Number(id) });

  const displayLegalFee = legalFee > 0 && !freeOfCharge;

  return (
    <Flex
      backgroundColor="greyscale.100"
      rounded="lg"
      w="100%"
      px={4}
      py={{
        base: 8,
        md: 0,
      }}
      flexDir="column"
      justifyContent="space-between"
      gap={8}
    >
      <VStack spacing={3}>
        <Row
          value={getCompensationValue({
            canDisplayCompensation,
            absoluteValue: formattedValues.claimCompensation,
          })}
          label={t('claims.compensation.flight_compensation')}
          freeOfCharge={false}
          icon={<Airplane color="primary.400" w={5} h={5} />}
        />

        <Row
          value={getCompensationValue({
            canDisplayCompensation,
            absoluteValue: formattedValues.serviceFee,
            isPositive: serviceFee >= 0,
          })}
          freeOfCharge={freeOfCharge}
          dataTestId="service-fee"
          label={t('claims.compensation.service_fee')}
          tooltipTitle={t('claims.compensation.service_fee')}
          tooltipDescription={t('claims.compensation.service_fee_description')}
          icon={<BoxImportant color="primary.400" w={5} h={5} />}
        />
        {displayLegalFee ? (
          <Row
            value={getCompensationValue({
              canDisplayCompensation,
              absoluteValue: formattedValues.legalFee,
              isPositive: legalFee >= 0,
            })}
            freeOfCharge={freeOfCharge}
            icon={<Law color="primary.400" w={5} h={5} />}
            dataTestId="legal"
            label={t('claims.compensation.legal_action_fee')}
            tooltipTitle={t('claims.compensation.legal_action_fee')}
            tooltipDescription={t(
              'claims.compensation.legal_action_fee_description',
            )}
          />
        ) : null}
        <hr
          style={{
            width: '100%',
          }}
        />
        <Row
          icon={<Compensation color="primary.400" w={5} h={5} />}
          freeOfCharge={false}
          value={getCompensationValue({
            canDisplayCompensation,
            absoluteValue: formattedValues.finalCompensation,
          })}
          label={t('claims.compensation.your_compensation')}
          textStyle={{
            fontWeight: 'bold',
          }}
        />
      </VStack>

      <Link
        isExternal
        href={config.pricingUrl.replace('{{locale}}', locale)}
        onClick={() => {
          trackPageInteractions(
            'clicked',
            'read more about fees',
            'claim details page - compensation',
          );
        }}
      >
        <HStack
          justifyContent="space-between"
          width="100%"
          rounded="12px"
          border="1px solid"
          borderColor="greyscale.400"
          p={6}
          cursor="pointer"
          data-testid="compensation-fee-link"
        >
          <HStack spacing={0}>
            <OpenInNew
              w={4}
              h={4}
              me={5}
              color="primary.900"
              transform={`rotate(${isRtl(locale) ? '270deg' : '0'})`}
            />
            <Text fontSize="sm" fontWeight="medium" color="primary.900">
              {t('claims.compensation.read_more_about_fees')}
            </Text>
          </HStack>

          <AltArrowForward
            color="greyscale.500"
            w={5}
            h={5}
            transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
          />
        </HStack>
      </Link>
    </Flex>
  );
}
