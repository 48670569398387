import { type FetchClaimDocumentsParams } from '@airhelp/liftoff';
import { useClaimDocuments } from '@airhelp/liftoff/react';
import { useMemo } from 'react';
import { generatePath } from 'react-router';
import { PAGE_PATHS } from '../../../config/routes';
import { env } from '../../../config';

export function useDocumentRequestRedirectLink({
  claimId,
}: FetchClaimDocumentsParams) {
  const { data, isLoading } = useClaimDocuments({ params: { claimId } });

  return {
    isLoading,
    link: useMemo(() => {
      const requestedDocuments = data?.flatMap((record) =>
        record.documents.filter(
          (doc) =>
            doc.requestedDocument.id !== null ||
            doc.procuredDocument.id !== null,
        ),
      );

      if (!requestedDocuments?.length) {
        return generatePath(PAGE_PATHS.CLAIM.DOCUMENTS_PAGE, { id: claimId });
      }

      const hasUnsupportedDocuments = requestedDocuments.some(
        (doc) => !env.VITE_DOC_FUNNEL_SUPPORTED_DOCS.has(doc.type),
      );
      const redirectURL = hasUnsupportedDocuments
        ? env.VITE_EMBER_FUNNEL_REDIRECT_URL
        : env.VITE_DOC_FUNNEL_REDIRECT_URL;

      const firstRequestedDoc = requestedDocuments.at(0)?.requestedDocument;
      const requestedDocPassenger = data?.find((record) =>
        record.documents.some(
          (doc) => doc.requestedDocument.id === firstRequestedDoc?.id,
        ),
      )?.passenger;

      const url = new URL(
        redirectURL
          .replaceAll('{{claimId}}', claimId.toString())
          .replaceAll(
            '{{requestedDocumentUUID}}',
            firstRequestedDoc?.uuid as string,
          ),
      );

      if (requestedDocPassenger?.type === 'FellowPassenger') {
        url.search = `fellow_passenger_id=${requestedDocPassenger?.id}`;
      }

      return url.toString();
    }, [data]),
  };
}
