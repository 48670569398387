import { type UseQueryResult } from '@tanstack/react-query';
import { fetchLoungePasses } from '@airhelp/plus';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { type LoungePass, transformToLoungePassClass } from 'models/LoungePass';
import { ahplusClient } from 'api/clients/clients';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';

interface FetchLoungePassesResponse {
  loungePasses: LoungePass[];
}

const useFetchLoungePasses = (
  options?: ApiQueryOptions<FetchLoungePassesResponse, LoungePass[]>,
): UseQueryResult<LoungePass[]> => {
  return useProtectedQuery<FetchLoungePassesResponse, LoungePass[]>({
    queryKey: ['userProfile', 'loungePasses'],
    queryFn: () => fetchLoungePasses({ client: ahplusClient }),
    select: (data) =>
      data.loungePasses.map((loungePassData) =>
        transformToLoungePassClass(loungePassData),
      ),
    ...options,
  });
};

export default useFetchLoungePasses;
