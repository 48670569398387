import React from 'react';
import { useTranslation } from 'react-i18next';
import { Edit, Ok, User } from '@airhelp/icons';
import { Box, Button, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import { Card } from 'elements';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import { type AirPayoutJourney } from 'models/journey/AirPayoutJourney';
import AirBundleTagStatus from './AirBundleTagStatus';
import { type IBoxTag } from './AirBundleTagStatus/AirBundleTagStatus';

const UserCard = ({
  payoutAllowed,
  airBundleJourney,
  isOpen,
  handleEdit,
  canEdit,
  statusBox,
  paxFilled,
}: {
  payoutAllowed: boolean;
  airBundleJourney: AirLuggageJourney | AirPayoutJourney;
  isOpen?: boolean;
  handleEdit?: () => void;
  canEdit?: boolean;
  statusBox: IBoxTag;
  paxFilled?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Card
      padding={{ base: 4, md: 5 }}
      backgroundColor={isOpen ? 'primary.100' : 'greyscale.100'}
      borderBottomRadius={isOpen ? 0 : 'xl'}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          {canEdit ? (
            <Flex mb={3} display={{ base: 'flex', md: 'none' }}>
              <AirBundleTagStatus statusBox={statusBox} />
            </Flex>
          ) : null}
          <Flex align="center" justifyContent="space-between">
            <Flex align="center">
              <IconButton
                aria-label="user"
                icon={<User width={5} height={5} color="primary.500" m={2} />}
                size="lg"
                backgroundColor={isOpen ? 'greyscale.100' : 'primary.100'}
                width={9}
                minW={9}
                height={9}
                me={3}
                pointerEvents="none"
              />
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems={{ base: 'flex-start', md: 'center' }}
              >
                <Text
                  fontSize="md"
                  color="greyscale.600"
                  me={{ base: 0, md: 3 }}
                >
                  {t('common.single_passenger')}:
                </Text>
                <Text fontSize="md" fontWeight="medium">
                  {airBundleJourney?.name}
                </Text>
                {canEdit ? (
                  <Flex mx={3} display={{ base: 'none', md: 'flex' }}>
                    <AirBundleTagStatus statusBox={statusBox} />
                  </Flex>
                ) : null}
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box>
          <HStack spacing={4}>
            {payoutAllowed && canEdit ? (
              <>
                <Button
                  variant="subtle"
                  aria-label="edit"
                  size="xs"
                  backgroundColor={isOpen ? 'primary.100' : 'greyscale.100'}
                  leftIcon={<Edit width={5} height={5} color="primary.500" />}
                  onClick={handleEdit}
                  display={{ base: 'none', md: 'flex' }}
                  data-testid={`edit-button-${airBundleJourney?.id}`}
                >
                  {t('common.edit')}
                </Button>

                <IconButton
                  aria-label="edit"
                  icon={<Edit width={5} height={5} color="primary.500" />}
                  size="xs"
                  backgroundColor={isOpen ? 'primary.100' : 'greyscale.100'}
                  display={{ base: 'initial', md: 'none' }}
                  onClick={handleEdit}
                  _hover={{ backgroundColor: 'transparent' }}
                  data-testid="edit-icon"
                />
              </>
            ) : null}
            <Ok
              width={6}
              height={6}
              color={!isOpen && paxFilled ? 'success.600' : 'greyscale.500'}
            />
          </HStack>
        </Box>
      </Flex>
    </Card>
  );
};

export default UserCard;
