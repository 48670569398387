import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import {
  phoneNumberPattern,
  validatePhoneNumberCountryCode,
} from 'utils/validations';
import { PhoneNumberInput } from 'elements';

const phoneNumberLength = { min: 11, max: 12 };

const phoneNumber = ({ form, t }: { form: UseFormReturn; t: TFunction }) => {
  return (
    <Controller
      name="phoneNumber"
      control={form.control}
      rules={{
        minLength: {
          value: phoneNumberLength.min,
          message: t('errors.too_short', {
            min: phoneNumberLength.min,
            attribute: t('user.phone_number'),
          }),
        },
        maxLength: {
          value: phoneNumberLength.max,
          message: t('errors.too_long', {
            max: phoneNumberLength.max,
            attribute: t('user.phone_number'),
          }),
        },
        pattern: {
          value: phoneNumberPattern,
          message: t('errors.phone_number_invalid'),
        },
        validate: {
          countryCode: (value: string) =>
            validatePhoneNumberCountryCode(value, t),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <PhoneNumberInput
            name={name}
            value={value}
            onChange={onChange}
            isInvalid={invalid}
            errorMessage={error?.message || ''}
          />
        </Box>
      )}
    />
  );
};

export default phoneNumber;
