import React, { useContext } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { track } from 'react-tracking';
import ConnectingFlightsView from 'components/Flights/New/ConnectingFlightsView';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext } from 'contexts';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import useEffectOnce from 'hooks/useEffectOnce';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';
import useProtectedPage from 'hooks/routing/useProtectedPage';

const ConnectingFlightsPage = () => {
  usePageTracking();
  useProtectedPage();
  const { itineraryIndex, fromRoute } = useContext(EditedJourneyContext);
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const { setActiveStep } = useLayout();

  useEffectOnce(() => {
    setActiveStep(1);
  });

  const nextStep = () => {
    navigate({
      pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.FLIGHTS_DETAILS_PAGE),
      search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
    });
    trackCtaClick(
      'continue',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - connecting flights`,
    );
  };

  const previousStep = () => {
    navigate({
      pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.ROUTE_DESTINATION_PAGE),
      search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
    });
    trackCtaClick(
      'back',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - connecting flights`,
    );
  };

  return (
    <ConnectingFlightsView
      onNextStep={nextStep}
      onPreviousStep={previousStep}
    />
  );
};

export default track({ page_type: 'Flights.New.ConnectingFlights' })(
  ConnectingFlightsPage,
);
