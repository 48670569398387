import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AirportAutocomplete as ChakraAirportAutocomplete,
  type AirportOption,
  type AutocompleteProps,
} from '@airhelp/react';
import { useAirports } from '@airhelp/webapp/react';
import { type Airport } from '@airhelp/webapp';
import useDebouncedValue from 'hooks/useDebouncedValue';

type AirportAutocompleteProps = Partial<AutocompleteProps<AirportOption>> & {
  defaultAirport?: Airport;
  placeholder?: string;
  onSearch?: (searchTerm: string) => void;
  disabledIatas?: string[];
};

const AirportAutocomplete: React.FC<AirportAutocompleteProps> = ({
  defaultAirport,
  placeholder,
  onSearch,
  disabledIatas = [],
  ...props
}) => {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const searchDebounced = useDebouncedValue(searchTerm, 300);
  const isSearchStringCorrect = searchDebounced?.length >= 2;

  const { data, isLoading } = useAirports({
    params: { query: searchDebounced },
    enabled: isSearchStringCorrect,
  });

  const airlinesOptions =
    data?.airports?.map((airport) => ({
      value: airport,
      isDisabled: disabledIatas.includes(airport.iata),
    })) ?? [];

  const sortedAirportsOptions = airlinesOptions?.sort(
    (first, second) => second.value.rank - first.value.rank,
  );

  const showMenu = !!searchTerm && sortedAirportsOptions.length > 0;

  useEffect(() => {
    if (!searchDebounced || searchDebounced.length < 2) {
      return;
    }

    if (onSearch) {
      onSearch(searchTerm);
    }
  }, [searchDebounced]);

  return (
    <ChakraAirportAutocomplete
      {...props}
      menuIsOpen={showMenu}
      isLoading={isLoading}
      options={sortedAirportsOptions}
      defaultValue={defaultAirport ? { value: defaultAirport } : null}
      onInputChange={setSearchTerm}
      placeholder={placeholder || t('funnels.departure_airport_placeholder')}
    />
  );
};

export default AirportAutocomplete;
