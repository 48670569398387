import React from 'react';
import { Flex } from '@chakra-ui/react';
import Loader from 'elements/Loader';

const PageLoader: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100%"
      width="100%"
    >
      <Loader dataTestId="loader" />
    </Flex>
  );
};

export default PageLoader;
