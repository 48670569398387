import { initReactI18next } from 'react-i18next';
import i18next, * as i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import type { HttpBackendOptions } from 'i18next-http-backend';
import { triggerGlobalFormRevalidation } from '@airhelp/funnel';
import { createCookieOptions } from 'utils/cookies';
import config from 'config/index';
import { registerLocales } from './registerLocales';

// bump version if you want to purge "Browser cache"
// it's temporary, we need smarter solution to change this value (file last mod date or VERSION from github/TC)
const version = '1.0';

const supportedLngs = [
  'ar-SA',
  'cs',
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'it',
  'nb',
  'nl',
  'pl',
  'pt',
  'pt-BR',
  'ro',
  'ru',
  'tr',
  'sv',
  'zh-CN',
  'en-int',
];

export async function initI18n() {
  registerLocales();

  // Because form errors don't change with language, we need to revalidate forms
  i18next.on('languageChanged', () => {
    triggerGlobalFormRevalidation();
  });

  await i18n

    .use(HttpBackend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options

    .init<HttpBackendOptions>({
      backend: {
        loadPath: (lngs: string[]) => {
          const lng = lngs[0];

          switch (lng) {
            case 'zh-CN':
              return `${config.baseUrl}locales/zh.json`;
            case 'ar':
            case 'ar-SA':
              return `${config.baseUrl}locales/ar.json`;
            case 'en-int':
            case 'en-INT':
              return `${config.baseUrl}locales/en.json`;
            default:
              return `${config.baseUrl}locales/${lng}.json`;
          }
        },
        // Zod translations are stored under zod key in main translation file
        // but library uses `zod` namespace by default. So we extract the key
        // if the application requests `zod` namespace
        parse: (response, _, loadedNs) => {
          const ns = Array.isArray(loadedNs) ? loadedNs[0] : loadedNs;
          const data = JSON.parse(response) as Record<string, unknown>;
          if (!ns || ns !== 'zod') {
            return data;
          }

          return data.zod ?? data;
        },
        queryStringParams: { v: version },
      },

      lng: 'en',
      fallbackLng: 'en',
      ns: ['translation', 'zod'],
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      load: 'currentOnly',
      react: {
        useSuspense: false,
      },
      returnNull: false,
      detection: {
        // order and from where user language should be detected
        order: ['querystring', 'cookie'],

        // keys or params to lookup language from (webapp behaviour)
        lookupQuerystring: 'lang',
        // start using cookie user_language to find default language (webapp behaviour)
        lookupCookie: config.languageCookie,

        // optional expire and domain for set cookie
        cookieMinutes: 525600, // 365 days
        cookieDomain: createCookieOptions().domain,

        // cache user language on
        caches: ['cookie'],
      },

      debug: !config.isProduction,

      supportedLngs,
    });
}
