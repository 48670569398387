import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';
import { shadowsMd } from '@airhelp/design-tokens';
import { type AirportOption } from '@airhelp/react';
import { Box, Container, Flex, Heading, Text } from '@chakra-ui/react';
import AirportAutocomplete from 'elements/AirportAutocomplete';

interface IComponent {
  handleAirportSelect: (airport: AirportOption | null) => void;
}

const AirportSearchView: React.FC<IComponent> = ({ handleAirportSelect }) => {
  const { t } = useTranslation();
  const tracking = useTracking();

  const trackChooseLounge = (airport: string | undefined) => {
    tracking.trackEvent({
      name: 'choose_lounge',
      label: airport,
    });
  };

  const trackSearchLounge = (searchPhrase: string) => {
    tracking.trackEvent({
      name: 'search_lounge',
      searchPhrase,
    });
  };

  return (
    <Container
      mt={{ base: 4, sm: 16 }}
      mb={{ base: 4, sm: 0 }}
      px={{ base: 4, sm: 8, md: 6 }}
      pt={{ base: 4, sm: 8, md: 6 }}
      pb={{ base: 32, sm: 8, md: 6 }}
      mx={{ base: 0, sm: 'auto' }}
      bgColor={{ base: 'transparent', sm: 'greyscale.100' }}
      w={{
        base: '100%',
        sm: 'calc(100vw - 32px)',
        md: 'calc(100vw - 128px)',
        lg: '770px',
      }}
      maxW="none"
      borderRadius={{ base: 'none', sm: 'xl' }}
      boxShadow={{ base: 'none', sm: shadowsMd }}
    >
      <Flex
        textAlign="center"
        alignItems="center"
        flexDirection="column"
        pt={4}
      >
        <Text
          fontSize="xs"
          fontWeight="bold"
          textTransform="uppercase"
          color="primary.500"
          mb={2}
        >
          {t('lounge_finder.en_airhelp_lounge_finder')}
        </Text>

        <Heading variant="h2" mb={2}>
          {t('lounge_finder.en_search_available_lounges')}
        </Heading>

        <Text display={{ base: 'none', sm: 'inline' }} mb={8}>
          {t('lounge_finder.en_find_lounge')}
        </Text>

        <Box w="100%" maxW="570px" textAlign="left">
          <AirportAutocomplete
            handleChange={(airport) => {
              handleAirportSelect(airport);
              trackChooseLounge(airport?.value.name);
            }}
            label=""
            placeholder={t('lounge_finder.en_search_by')}
            onSearch={trackSearchLounge}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export default AirportSearchView;
