import { type CountryCode } from '@airhelp/flags';
import { type Locale as DatePickerLocale } from 'date-fns';
import {
  arSA,
  enGB,
  da,
  de,
  el,
  es,
  fi,
  fr,
  it,
  nb,
  nl,
  pl,
  ptBR,
  pt,
  ro,
  ru,
  tr,
  sv,
  zhCN,
} from 'date-fns/locale';

export interface LocaleObject {
  code: string;
  displayName: string;
  icon: CountryCode;
  rtl?: boolean;
  hidden?: boolean;
}

const baseSupportedLocales: Record<string, LocaleObject> = {
  'ar-SA': {
    code: 'ar-SA',
    displayName: 'العربية',
    icon: 'sa',
    rtl: true,
    hidden: false,
  },
  da: {
    code: 'da',
    displayName: 'Dansk',
    icon: 'dk',
  },
  de: {
    code: 'de',
    displayName: 'Deutsch',
    icon: 'dk',
  },
  en: {
    code: 'en',
    displayName: 'English',
    icon: 'gb-eng',
  },
  es: {
    code: 'es',
    displayName: 'Español',
    icon: 'es',
  },
  el: {
    code: 'el',
    displayName: 'Ελληνικά',
    icon: 'gr',
  },
  fr: {
    code: 'fr',
    displayName: 'Français',
    icon: 'fr',
  },
  it: {
    code: 'it',
    displayName: 'Italiano',
    icon: 'it',
  },
  nl: {
    code: 'nl',
    displayName: 'Nederlands',
    icon: 'nl',
  },
  nb: {
    code: 'nb',
    displayName: 'Norsk',
    icon: 'no',
  },
  pl: {
    code: 'pl',
    displayName: 'Polski',
    icon: 'pl',
  },
  'pt-BR': {
    code: 'pt-BR',
    displayName: 'Português (Brasil)',
    icon: 'br',
  },
  pt: {
    code: 'pt',
    displayName: 'Português (Portugal)',
    icon: 'pt',
  },
  ru: {
    code: 'ru',
    displayName: 'Русский',
    icon: 'ru',
  },
  ro: {
    code: 'ro',
    displayName: 'Română',
    icon: 'ro',
  },
  tr: {
    code: 'tr',
    displayName: 'Türkçe',
    icon: 'tr',
  },
  fi: {
    code: 'fi',
    displayName: 'Suomi',
    icon: 'fi',
  },
  sv: {
    code: 'sv',
    displayName: 'Svenska',
    icon: 'se',
  },
  'zh-CN': {
    code: 'zh-CN',
    displayName: '简体中文',
    icon: 'cn',
  },
  'en-int': {
    code: 'en-int',
    displayName: 'English (International)',
    icon: 'world',
  },
};

export const locales: Record<string, LocaleObject> =
  Object.freeze(baseSupportedLocales);

export type Locale = keyof typeof locales;

export const mapLocaleToDatePickerLocale = (
  locale: string,
): DatePickerLocale => {
  const localeToDatePickerLocale: Record<string, DatePickerLocale> = {
    'ar-SA': arSA,
    da,
    de,
    el,
    es,
    fi,
    fr,
    it,
    nb,
    nl,
    pl,
    pt,
    ro,
    ru,
    tr,
    sv,
    'pt-BR': ptBR,
    en: enGB,
    'zh-CN': zhCN,
  };

  return localeToDatePickerLocale[locale];
};

export const mapLocaleToFlagKey = (locale: Locale): CountryCode => {
  const localeToFlagKeyMap = {
    'ar-SA': 'sa',
    da: 'dk',
    de: 'de',
    en: 'gb',
    'en-int': 'world',
    es: 'es',
    el: 'gr',
    fr: 'fr',
    it: 'it',
    nl: 'nl',
    nb: 'no',
    pl: 'pl',
    'pt-BR': 'br',
    pt: 'pt',
    ru: 'ru',
    ro: 'ro',
    tr: 'tr',
    fi: 'fi',
    sv: 'se',
    'zh-CN': 'cn',
  };

  return localeToFlagKeyMap[locale];
};

export const normalizeLocale = (locale: string): Locale => {
  for (const key in locales) {
    if (key === locale) {
      return locale;
    }
  }
  const defaultLocale = 'en';

  if (locale.startsWith(defaultLocale)) {
    return defaultLocale;
  }
  if (locale.startsWith('zh')) {
    return 'zh-CN' as Locale;
  }
  if (locale.startsWith('ar')) {
    return 'ar-SA' as Locale;
  }
  return defaultLocale;
};

export const mapBrowserLanguageToLocale = (locale: string): Locale => {
  const supportedLocale: Record<string, Locale> = {
    ar: 'ar',
    'ar-sa': 'ar-SA',
    'ar-SA': 'ar-SA',
    da: 'da',
    'da-DA': 'da',
    de: 'de',
    'de-DE': 'de',
    el: 'el',
    'el-EL': 'el',
    es: 'es',
    'es-ES': 'es',
    fi: 'fi',
    'fi-FI': 'fi',
    fr: 'fr',
    'fr-FR': 'fr',
    it: 'it',
    'it-IT': 'it',
    nb: 'nb',
    'nb-NB': 'nb',
    nl: 'nl',
    'nl-NL': 'nl',
    ro: 'ro',
    'ro-RO': 'ro',
    ru: 'ru',
    'ru-RU': 'ru',
    sv: 'sv',
    'sv-SV': 'sv',
    tr: 'tr',
    'tr-TR': 'tr',
    pl: 'pl',
    'pl-PL': 'pl',
    pt: 'pt',
    'pt-PT': 'pt',
    'pt-br': 'pt-BR',
    'pt-BR': 'pt-BR',
    en: 'en',
    'en-US': 'en',
    'en-GB': 'en',
    'en-INT': 'en',
    'en-int': 'en',
    zh: 'zh-CN',
    'zh-cn': 'zh-CN',
    'zh-CN': 'zh-CN',
    'zh-Hans-CN': 'zh-CN',
  };

  const browserLanguageToLocale = supportedLocale;

  return browserLanguageToLocale[locale];
};

export const dateFnLocale = {
  'ar-SA': arSA,
  de,
  da,
  es,
  fi,
  fr,
  'en-GB': enGB,
  'en-INT': enGB,
  el,
  it,
  nl,
  nb,
  pl,
  pt,
  'pt-BR': ptBR,
  ro,
  ru,
  tr,
  sv,
  zh: 'zh-CN',
};

export const singleLanguage = (lang: string | undefined | string[]) => {
  return lang?.constructor === Array ? lang[0] : lang;
};

export const mapLocaleToVeriff = (lang: string) => {
  const veriffMapping = {
    'ar-SA': 'ar',
    'pt-BR': 'pt',
    pt: 'pt-pt',
    'zh-CN': 'zh',
    'en-int': 'en',
    'en-INT': 'en',
  };
  return veriffMapping[lang] || lang;
};

export const getMappedLocale = (lang: string) => {
  const localeMapping = {
    'ar-SA': 'ar',
    'pt-BR': 'pt',
    'en-INT': 'en',
    'en-int': 'en',
  };
  return localeMapping[lang] || lang;
};
