import {
  Text,
  Tooltip as ChakraTooltip,
  useDisclosure,
  useBreakpointValue,
  Box,
} from '@chakra-ui/react';
import { InfoOutline } from '@airhelp/icons';
import Drawer from 'components/Drawer';

export function Tooltip({
  title,
  children,
  dataTestId,
}: {
  title?: string;
  dataTestId?: string;
  children?: React.ReactNode;
}) {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (isMobile) {
    return (
      <>
        <InfoOutline
          color="primary.900"
          boxSize={3}
          cursor="pointer"
          onClick={onOpen}
          data-testid={`${dataTestId}-tooltip`}
        />
        <Drawer header={title} isOpen={isOpen} onClose={onClose}>
          <Box w="full" p={4}>
            <Text color="primary.900" fontSize="md">
              {children}
            </Text>
          </Box>
        </Drawer>
      </>
    );
  }

  return (
    <ChakraTooltip
      hasArrow
      isOpen={isOpen}
      label={children}
      color="greyscale.200"
      bg="greyscale.800"
      rounded="md"
      p={3}
      data-testid={dataTestId}
    >
      <InfoOutline
        color="primary.900"
        boxSize={3}
        cursor="pointer"
        onMouseEnter={onOpen}
        onMouseLeave={onClose}
        onClick={onToggle}
      />
    </ChakraTooltip>
  );
}
