import { useParams } from 'react-router';
import { Documents } from './Documents/Documents';

export function ClaimDocumentsView() {
  const { id } = useParams();

  if (!id) {
    throw new Error('ID param is required to render the Documents view');
  }

  return <Documents claimId={Number(id)} />;
}
