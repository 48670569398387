import { registerLocale } from 'i18n-iso-countries';
import daCountries from 'i18n-iso-countries/langs/da.json';
import deCountries from 'i18n-iso-countries/langs/de.json';
import elCountries from 'i18n-iso-countries/langs/el.json';
import enCountries from 'i18n-iso-countries/langs/en.json';
import esCountries from 'i18n-iso-countries/langs/es.json';
import fiCountries from 'i18n-iso-countries/langs/fi.json';
import frCountries from 'i18n-iso-countries/langs/fr.json';
import itCountries from 'i18n-iso-countries/langs/it.json';
import nbCountries from 'i18n-iso-countries/langs/nb.json';
import nlCountries from 'i18n-iso-countries/langs/nl.json';
import plCountries from 'i18n-iso-countries/langs/pl.json';
import ptCountries from 'i18n-iso-countries/langs/pt.json';
import roCountries from 'i18n-iso-countries/langs/ro.json';
import ruCountries from 'i18n-iso-countries/langs/ru.json';
import svCountries from 'i18n-iso-countries/langs/sv.json';
import trCountries from 'i18n-iso-countries/langs/tr.json';
import ptBrCountries from './locales/countries/pt-br.json';
import zhCnCountries from './locales/countries/zh-cn.json';

export const registerLocales = () => {
  registerLocale(deCountries);
  registerLocale(daCountries);
  registerLocale(elCountries);
  registerLocale(esCountries);
  registerLocale(enCountries);
  registerLocale(fiCountries);
  registerLocale(frCountries);
  registerLocale(itCountries);
  registerLocale(nbCountries);
  registerLocale(nlCountries);
  registerLocale(plCountries);
  registerLocale(ptCountries);
  registerLocale(ptBrCountries);
  registerLocale(ruCountries);
  registerLocale(roCountries);
  registerLocale(svCountries);
  registerLocale(trCountries);
  registerLocale(zhCnCountries);
};
