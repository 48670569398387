import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'date-fns';
import { useSimplifiedClaim } from '@airhelp/webapp/react';
import * as VerticalStepTracker from 'components/VerticalStepTracker';
import Drawer from 'components/Drawer';
import {
  CLAIM_REJECTED_STATES,
  type CLAIM_STATES,
} from 'hooks/useClaimState/claim-states';
import { useClaimState } from 'hooks/useClaimState';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';

export function Status() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { trackPageInteractions } = useTrackEvent();
  const { id } = useParams();
  const { t } = useTranslation();
  const { locale } = useLocale();

  const onStatusDetailsOpen = () => {
    trackPageInteractions(
      'drawer open',
      'view status details',
      'claim details page - progress',
    );
    onOpen();
  };

  if (!id) {
    throw new Error('Id param is required for status component to work');
  }

  const {
    shortStateHistory,
    longStateHistory,
    isClaimHistoryLong,
    hasClaimNextState,
    claimHistorySize,
    isClaimInPausedState,
    isClaimRejected,
  } = useClaimState(id);

  const { data: claim } = useSimplifiedClaim({
    params: {
      id,
      locale,
    },
  });

  if (!claim) {
    // temp solution for now - claim fetching will fail due to missing fullName in the fellow passengers response
    return null;
  }

  return (
    <Box
      rounded="xl"
      backgroundColor="greyscale.100"
      p={{
        base: 4,
        md: 5,
        lg: 8,
      }}
    >
      <VerticalStepTracker.Root
        extendedBorder={isClaimHistoryLong ? 'top' : undefined}
        extendedBorderColor={
          isClaimInPausedState
            ? 'primary.600'
            : isClaimRejected
              ? 'danger.500'
              : 'success.600'
        }
      >
        {shortStateHistory.map((stage, idx) => {
          const showAsCurrent = hasClaimNextState
            ? idx === 0
            : idx === shortStateHistory.length - 1;
          return (
            <ClaimStatusListItem
              key={stage.completedAt.toString()}
              header={stage.state.header as string}
              subheader={formatDate(stage.completedAt, 'd MMMM yyyy')}
              sentiment={stage.state.sentiment}
              identifier={stage.state.identifier}
              isCurrent={showAsCurrent}
              isPaused={idx === claimHistorySize - 1 && isClaimInPausedState}
              isNext={hasClaimNextState ? idx === 1 : false}
            />
          );
        })}
      </VerticalStepTracker.Root>

      <Button
        variant="secondary"
        size="s"
        onClick={onStatusDetailsOpen}
        mt={4}
        data-testid="view-status-details-button"
      >
        {t('claims.layout.view_status_details')}
      </Button>
      <Drawer
        header={t('claims.layout.status_details')}
        isOpen={isOpen}
        onClose={onClose}
        p={{
          base: 2,
          md: 4,
        }}
        scrollToBottomOnOpen
      >
        <VerticalStepTracker.Root>
          {longStateHistory.map((stage, idx) => {
            return (
              <ClaimStatusListItem
                key={`drawer-${stage.completedAt.toString()}`}
                header={stage.state.header ?? ''}
                subheader={formatDate(stage.completedAt, 'd MMMM yyyy')}
                description={stage.state.comment}
                sentiment={stage.state.sentiment}
                identifier={stage.state.identifier}
                isCurrent={
                  hasClaimNextState
                    ? idx === claimHistorySize - 2
                    : idx === claimHistorySize - 1
                }
                isNext={
                  hasClaimNextState ? idx === claimHistorySize - 1 : false
                }
                isPaused={idx === claimHistorySize - 1 && isClaimInPausedState}
                initiallyOpen={idx === longStateHistory.length - 1}
              />
            );
          })}
        </VerticalStepTracker.Root>
      </Drawer>
    </Box>
  );
}

function getStepIndicatorState(
  identifier: string,
  sentiment?: string,
  isNext?: boolean,
  showPausedState?: boolean,
) {
  if (!sentiment) {
    return undefined;
  }

  if (showPausedState) {
    return 'paused';
  }

  if (isNext) {
    return 'upcoming';
  }

  return CLAIM_REJECTED_STATES.has(identifier as CLAIM_STATES)
    ? 'failed'
    : 'completed';
}

interface ClaimStatusListItem {
  header: string;
  subheader?: string;
  description?: string;
  sentiment: string;
  identifier: string;
  isCurrent?: boolean;
  isNext?: boolean;
  isPaused?: boolean;
  initiallyOpen?: boolean;
}

function ClaimStatusListItem({
  sentiment,
  header,
  subheader,
  description,
  identifier,
  isCurrent = false,
  isNext = false,
  isPaused,
  initiallyOpen = false,
}: ClaimStatusListItem) {
  const { t } = useTranslation();

  const showDescription = Boolean(description);

  if (showDescription) {
    return (
      <VerticalStepTracker.Step
        header={header}
        subheader={isNext ? undefined : subheader}
        state={getStepIndicatorState(identifier, sentiment, isNext, isPaused)}
        active={isCurrent}
        initiallyOpen={initiallyOpen}
      >
        {isNext ? (
          <VerticalStepTracker.StepBadge
            backgroundColor="greyscale.100"
            textColor="success.700"
            textTransform="none"
            fontSize="xs"
            fontWeight={500}
            px={2}
            py={1.5}
          >
            {t('claims.layout.up_next')}
          </VerticalStepTracker.StepBadge>
        ) : null}
        <VerticalStepTracker.StepContent>
          {description}
        </VerticalStepTracker.StepContent>
      </VerticalStepTracker.Step>
    );
  }

  return (
    <VerticalStepTracker.Step
      header={header}
      subheader={isNext ? undefined : subheader}
      state={getStepIndicatorState(identifier, sentiment, isNext, isPaused)}
      active={isCurrent}
    >
      {isNext ? (
        <VerticalStepTracker.StepBadge
          backgroundColor="greyscale.300"
          textColor="greyscale.600"
          textTransform="none"
          fontSize="xs"
          fontWeight={500}
          px={2}
          py={1.5}
          minWidth="max-content"
        >
          {t('claims.layout.up_next')}
        </VerticalStepTracker.StepBadge>
      ) : null}
    </VerticalStepTracker.Step>
  );
}
