import { useEffect } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

const useProtectedPage = () => {
  const { userProfile } = useMyProfile();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!userProfile) {
      navigate(
        { pathname: generatePath(PAGE_PATHS.LOGIN_PAGE) },
        { state: { form: location.pathname } },
      );
    }
  }, [userProfile]);
};

export default useProtectedPage;
