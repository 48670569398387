import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Delete, ArrowRight } from '@airhelp/icons';
import { Button, type ButtonProps, useBreakpointValue } from '@chakra-ui/react';
import { type TranslationKey } from 'types/i18n';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';

interface BackToButtonProps {
  dataTestId: string;
  navigateTo: string;
  labelTKey?: TranslationKey;
  onClick?: VoidFunction;
  variantBreakpoint?: string;
}

const BackToButton: React.FC<BackToButtonProps> = ({
  dataTestId,
  navigateTo,
  labelTKey = 'common.back',
  onClick,
  variantBreakpoint = 'lg',
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLocale();

  const variantProps = useBreakpointValue<ButtonProps>({
    base: {
      background: 'greyscale.300',
      leftIcon: <Delete boxSize={6} color="primary.900" />,
      p: 2,
      iconSpacing: 0,
      ml: 2,
      _active: {
        background: 'greyscale.200',
      },
    },
    [variantBreakpoint]: {
      variant: 'secondary',
      iconSpacing: 3,
      border: 'none',
      leftIcon: (
        <ArrowRight transform={`rotate(${isRtl(locale) ? '0' : '180deg'})`} />
      ),
      alignSelf: 'flex-start',
    },
  });

  const buttonLabel = useBreakpointValue({
    base: null,
    [variantBreakpoint]: t(labelTKey),
  });

  const handleNavigate = () => {
    onClick?.();

    navigate(navigateTo);
  };

  return (
    <Button
      {...variantProps}
      onClick={handleNavigate}
      size="xs"
      data-testid={dataTestId}
    >
      {buttonLabel}
    </Button>
  );
};

export default BackToButton;
