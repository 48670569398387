import React from 'react';
import { Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';

export const NotificationDot: React.FC = () => {
  return (
    <Box backgroundColor="danger.500" borderRadius="50%" width={2} height={2} />
  );
};

const pulseRing = keyframes`
  0% {
    transform: scale(0.5);
  }
  80%, 100% {
    opacity: 0;
  }
`;

const pulseDot = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
`;

interface PulsatingNotificationDotProps {
  width?: string;
  height?: string;
}

const PulsatingNotificationDot: React.FC<PulsatingNotificationDotProps> = ({
  width,
  height,
}) => {
  return (
    <Box
      animation={`${pulseDot} 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite`}
      backgroundColor="danger.500"
      borderRadius="50%"
      maxHeight={1.5}
      width={width}
      height={height}
      maxWidth={1.5}
      mx={2}
      mt={0.5}
      _before={{
        content: "''",
        display: 'flex',
        height: '200%',
        left: '-50%',
        position: 'absolute',
        top: '-50%',
        width: '200%',
        backgroundColor: 'danger.900',
        borderRadius: '50%',
        animation: `${pulseRing} 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite`,
      }}
      data-testid="pulsating-notification-dot"
    />
  );
};

export default PulsatingNotificationDot;
