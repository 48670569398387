import React from 'react';
import { ProductName } from '@airhelp/plus';
import { generatePath, Link as ReactLink } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import { Box, Text, Flex, Heading, VStack, Button } from '@chakra-ui/react';
import { useTracking } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';
import { formatValueToCurrency } from 'stores/preferences/currencies';
import {
  EcommerceEvent,
  type TrackEcommerceEvent,
} from 'utils/tracking/props/ga4/types';
import {
  OFFER_NAME_PRO,
  OFFER_NAME_FREE,
  DEFAULT_CURRENCY_PRICE_PLAN,
} from 'components/MyAccountView/PlanManagementView/plan-management-config';
import {
  getDiscountedPrice,
  getDiscountPercentage,
  getSearchParamsForButton,
} from 'components/MyAccountView/PlanManagementView/plan-management-common';
import { type IPrice } from 'models/Offers';
import DiscountOffBadge from '../DiscountOffBadge';

const DetailsHeader: React.FC<{
  name: string;
  currentShortPlanName: string;
  priceDetails: IPrice;
  isCurrentPlan: boolean;
  isFreeAccount: boolean;
  isProMembership: boolean;
  isFeaturedPlan: boolean;
}> = ({
  name,
  currentShortPlanName,
  priceDetails,
  isCurrentPlan = false,
  isFreeAccount = true,
  isProMembership = false,
  isFeaturedPlan = false,
}) => {
  const { t } = useTranslation();
  const { trackUpsellOption } = useTrackEvent();
  const tracking = useTracking<TrackEcommerceEvent>();
  const isFreePlan = name === OFFER_NAME_FREE;
  const regularPrice = priceDetails.price;

  const regularPriceWithCurrency = formatValueToCurrency(
    regularPrice,
    DEFAULT_CURRENCY_PRICE_PLAN,
  );

  const discountedPrice = getDiscountedPrice(isFreeAccount, priceDetails);
  const discountPercentage = getDiscountPercentage(isFreeAccount, priceDetails);

  const discountedPriceWithCurrency = formatValueToCurrency(
    discountedPrice,
    DEFAULT_CURRENCY_PRICE_PLAN,
  );

  const showPrice = regularPriceWithCurrency && !isFreePlan;
  const showDiscountedInfo = !isFreePlan && Boolean(discountPercentage);

  const canBuyOrUpgradePlan = (planName: string) => {
    return (
      !isCurrentPlan && !isProMembership && ['Pro', 'Smart'].includes(planName)
    );
  };

  const showBuyOrUpgradeButton = canBuyOrUpgradePlan(name);

  const onUpgradeClick = () => {
    if (isFreeAccount && name) {
      const discountValue = (regularPrice - discountedPrice).toFixed(2);
      const offerId =
        name === OFFER_NAME_PRO
          ? ProductName.AIRHELP_PRO_ANNUALLY
          : ProductName.AIRHELP_SMART_ANNUALLY;

      const ecommerceItem = {
        item_id: offerId,
        item_name: 'AirHelpPlus Membership',
        discount: discountValue ?? 0,
        index: 1,
        item_brand: 'AirHelp',
        item_category: '1YearMembership',
        item_category2: 'Subscription',
        item_list_name: 'ahp_dashboard_lp',
        item_variant: name.toLowerCase(),
        price: regularPrice.toString(),
        quantity: 1,
      };

      tracking.trackEvent({
        event: EcommerceEvent.SELECT_ITEM,
        ecommerce: {
          currency: DEFAULT_CURRENCY_PRICE_PLAN,
          item_list_name: 'ahp_dashboard_lp',
          items: [ecommerceItem],
        },
      });
    }

    if (!isFreeAccount && name) {
      trackUpsellOption(`select plan ${name}`, 'ahp plans&benefits');
    }
  };

  return (
    <VStack
      width="100%"
      height="100%"
      minHeight="120px"
      justifyContent={isCurrentPlan ? 'center' : 'flex-start'}
      gap={isCurrentPlan ? 0 : 2}
      className="header"
    >
      <Flex
        direction="column"
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Heading
          variant="h4"
          fontSize="16px"
          fontWeight="500"
          textAlign="center"
          color={isFeaturedPlan ? 'primary.500' : 'primary.700'}
        >
          {name}
        </Heading>
        {showPrice ? (
          <Box color={isFeaturedPlan ? 'primary.500' : 'primary.700'}>
            <Trans
              mt={0}
              pt={0}
              fontSize="md"
              textAlign="center"
              i18nKey="my_account.my_benefits.plans_compared.price_per_year"
              values={{
                price: discountedPriceWithCurrency,
              }}
              components={{
                span: (
                  <Text
                    display="inline"
                    color={isFeaturedPlan ? 'primary.500' : 'primary.700'}
                    fontSize="xl"
                    fontWeight="700"
                    as="span"
                  />
                ),
              }}
            />
          </Box>
        ) : null}
        {showDiscountedInfo ? (
          <Flex
            fontSize="md"
            color="primary.700"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              textDecoration="line-through"
              me={2}
              color="greyscale.600"
              fontSize="xs"
              fontWeight="500"
              lineHeight="short"
              whiteSpace="nowrap"
            >
              {regularPriceWithCurrency}
            </Box>
            <DiscountOffBadge
              text={t('my_account.my_benefits.plans_compared.discount', {
                value: discountPercentage,
              })}
            />
          </Flex>
        ) : null}
        {isCurrentPlan && !isFreePlan ? (
          <Text
            fontSize="sm"
            color="primary.700"
            textAlign="center"
            alignSelf="center"
          >
            ({t('my_account.my_benefits.plans_compared.current_plan')})
          </Text>
        ) : null}
      </Flex>
      {showBuyOrUpgradeButton ? (
        <VStack
          fontSize="md"
          color="primary.700"
          textAlign="center"
          width="100%"
          px={3}
        >
          <Button
            as={ReactLink}
            variant={isFeaturedPlan ? 'primary' : 'secondary'}
            size="xs"
            width="100%"
            height="40px"
            to={{
              pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
              search: getSearchParamsForButton(currentShortPlanName, name),
            }}
            onClick={onUpgradeClick}
            data-testid="btn-upgrade-plan"
          >
            {isFreeAccount
              ? t('my_account.my_benefits.plans_compared.buy_plan')
              : t('my_account.my_benefits.plans_compared.upgrade_plan')}
          </Button>
        </VStack>
      ) : null}
    </VStack>
  );
};

export default DetailsHeader;
