import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useSearchParams } from 'react-router';
import { Delete } from '@airhelp/icons';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import processingFailImage from 'assets/images/membership-activation-fail@1x.jpg';
import { TwoColumnsImageLayout } from 'components/Layouts';
import { PAGE_PATHS } from 'config/routes';

const Component = ({
  handleRetryAction,
}: {
  handleRetryAction: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Flex justifyContent="center">
        <Flex
          justifyContent="center"
          alignItems="center"
          width="74px"
          height="74px"
          borderRadius="full"
          bg="danger.200"
          mb={{ base: 6, lg: 8 }}
        >
          <Delete boxSize={6} color="danger.600" />
        </Flex>
      </Flex>
      <Heading variant="h1">{t('payment.failed.title')}</Heading>
      <Text fontSize="lg" fontWeight="medium" mt={3}>
        {t('payment.failed.description')}
      </Text>
      <Button
        size="m"
        onClick={handleRetryAction}
        mt={{ base: 6, lg: 12 }}
        width={{ base: '100%', lg: 'initial' }}
        data-testid="try-again-action-button"
      >
        {t('payment.try_again')}
      </Button>
    </Box>
  );
};

const Failed = ({
  tryAgainPath,
  noLayoutWrap,
}: {
  tryAgainPath?: string;
  onPaymentFailed?: () => void;
  noLayoutWrap?: boolean;
}) => {
  const [retryAgain, setRetryAgain] = useState<boolean | null>(null);
  const [searchParams] = useSearchParams();

  if (retryAgain) {
    const retrySearch = new URLSearchParams(searchParams.toString());
    retrySearch.delete('payment_intent');
    retrySearch.delete('payment_intent_client_secret');
    retrySearch.delete('redirect_status');
    retrySearch.delete('checkout-uuid');

    if (tryAgainPath) {
      return (
        <Navigate
          to={{ pathname: tryAgainPath, search: retrySearch.toString() }}
        />
      );
    }
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
          search: retrySearch.toString(),
        }}
      />
    );
  }

  const handleRetryAction = () => {
    setRetryAgain(true);
  };

  if (noLayoutWrap) {
    return <Component handleRetryAction={handleRetryAction} />;
  }

  return (
    <TwoColumnsImageLayout imageSrc={processingFailImage} noFeaturedImages>
      <Component handleRetryAction={handleRetryAction} />
    </TwoColumnsImageLayout>
  );
};

export default Failed;
