import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import AddToHomeScreenShortcut from './AddToHomeScreenShortcut';
import TopBannerEssentialUpgrade from './TopBannerEssentialUpgrade';
import TopBannerPaymentManagement from './TopBannerPaymentManagement';

const TopBanners = () => {
  const { userProfile } = useMyProfile();
  const { displayAddToHomeScreenBanner } = useLayout();

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showPaymentManagementBanner = Boolean(
    userProfile?.policy.membership?.showPaymentManagementBanner,
  );

  if (
    !showTopBannerEssentialUpgrade &&
    !showPaymentManagementBanner &&
    !displayAddToHomeScreenBanner
  ) {
    return null;
  }

  return (
    <Flex flexDirection="column" width="100%">
      {displayAddToHomeScreenBanner ? <AddToHomeScreenShortcut /> : null}
      {showTopBannerEssentialUpgrade ? <TopBannerEssentialUpgrade /> : null}
      {showPaymentManagementBanner ? <TopBannerPaymentManagement /> : null}
    </Flex>
  );
};

export default TopBanners;
