import React from 'react';
import cookie from 'react-cookies';
import { useTranslation, Trans } from 'react-i18next';
import { NavLink, generatePath, useNavigate } from 'react-router';
import { useCreateNewPassword } from '@airhelp/auth/react';
import { AuthPasswordChangeForm } from '@airhelp/funnel';
import { Link, Text } from '@chakra-ui/react';
import { USER_RETURN_AHPLUS_COOKIE } from 'config/cookies';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useTrackEvent } from 'utils/tracking/hooks';
import { createCookieOptions } from 'utils/cookies';

const SetPasswordView = () => {
  const { t } = useTranslation();
  const { reloadProfile } = useMyProfile();
  const navigate = useNavigate();
  const { trackCtaClick } = useTrackEvent();

  const saveCookieUserReturnUrl = () => {
    cookie.save(USER_RETURN_AHPLUS_COOKIE, '1', createCookieOptions());
  };

  const {
    mutate: authCreatePassword,
    isPending,
    isError,
  } = useCreateNewPassword({
    onSuccess: async () => {
      saveCookieUserReturnUrl();
      await reloadProfile();
      navigate(generatePath(PAGE_PATHS.HOME_PAGE));
    },
  });

  const onSubmit = (password: string) => {
    const token = new URLSearchParams(location.search).get('token') as string;

    authCreatePassword({
      password,
      token,
    });
  };

  return (
    <AuthPasswordChangeForm
      errorText={
        isError ? (
          <Trans i18nKey="authentication.expired_link">
            The link you are trying to use has already expired. If you want to
            set a new password go
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.RESET_PASSWORD_PAGE)}
              onClick={() => {
                saveCookieUserReturnUrl();
              }}
              _visited={{}}
            >
              <Text as="span" fontSize="sm" color="primary.500">
                here
              </Text>
            </Link>
            . A new link will be valid 60 minutes from receiving an email.
          </Trans>
        ) : undefined
      }
      i18n={{
        actionText: t('authentication.password_form.submit'),
        callout: (
          <Text as="span" fontSize="sm" color="greyscale.600">
            {t('authentication.already_password')}
            <Link
              as={NavLink}
              to={generatePath(PAGE_PATHS.LOGIN_PAGE)}
              onClick={() => {
                trackCtaClick('login', 'login page');
              }}
              ps={1}
              fontSize="sm"
              fontWeight="normal"
              color="primary.500"
            >
              <Text as="span" fontSize="sm" color="primary.500">
                {t('authentication.login_form.log_in_here')}
              </Text>
            </Link>
          </Text>
        ),
        confirmPasswordPlaceholder: t('authentication.password_confirmation'),
        header: t('authentication.password_form.header'),
        passwordPlaceholder: t('authentication.password'),
      }}
      onPasswordChange={(password) => {
        trackCtaClick('set-password', 'setPassword page');
        onSubmit(password);
      }}
      isPasswordChangePending={isPending}
    />
  );
};

export default SetPasswordView;
