import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { track } from 'react-tracking';
import MyAccountView from 'components/MyAccountView';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import useProtectedPage from 'hooks/routing/useProtectedPage';
import { usePageTracking } from 'utils/tracking';

const MyAccountPage = () => {
  usePageTracking();
  useProtectedPage();

  const { t } = useTranslation();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  return <MyAccountView />;
};

export default track({ page_type: 'MyAccount' })(MyAccountPage);
