import React, { useEffect } from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Select } from '@airhelp/react';
import { Box, Button, Flex, HStack, Link, Text } from '@chakra-ui/react';
import { type AirLuggagePaymentPax } from '@airhelp/plus';
import config from 'config';
import AirDocumentsUploader from 'elements/AirDocumentsUploader/AirDocumentsUploader';
import useEffectOnce from 'hooks/useEffectOnce';
import { itineraryUtils } from 'utils';
import { dataTestIdFormat } from 'utils/dataTestIdFormat';
import { type AirLuggageJourney } from 'models/journey/AirLuggageJourney';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { type Option } from 'types/select';
import { AIR_LUGGAGE_DOCUMENTS } from '../PayoutPaymentView/airLuggageDocumentsConfig';

type IReducerDataById = Record<number, AirLuggagePaymentPax>;

const DetailsForm = ({
  form,
  airLuggageJourney,
  handleAddPax,
  canEdit,
  reducerDataById,
  setFormsValidity,
}: {
  form: UseFormReturn;
  airLuggageJourney: AirLuggageJourney;
  handleAddPax: (data) => void;
  canEdit: boolean;
  reducerDataById: IReducerDataById;
  setFormsValidity: (id: number, valid: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const { control, handleSubmit, setValue, clearErrors, trigger, reset } = form;
  const { id, maxBags, availableItineraries } = airLuggageJourney;

  const handleDocument = (type: string, files: File[]) => {
    setValue(type, files[0]);
    clearErrors(type);
  };
  const handleDocumentDelete = (type: string): void => {
    setValue(type, null);
    setFormsValidity(id, false);
    trigger(type);
  };

  const handleSelect = (item: Option, onChange: (item: Option) => void) => {
    onChange(item);
  };

  const itineraryOptions = itineraryUtils.formatItineraryOptions(
    availableItineraries,
    locale,
  );

  const luggagesOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  const addedData = reducerDataById[id];

  useEffect(() => {
    reset({
      payoutEligibleItineraryId: null,
    });
  }, [locale]);

  useEffectOnce(() => {
    if (addedData) {
      const { payoutEligibleItineraryId, numberOfLuggages } = addedData;
      setValue(
        'payoutEligibleItineraryId',
        itineraryOptions.find((i) => i.value === payoutEligibleItineraryId),
      );
      setValue(
        'numberOfLuggages',
        luggagesOptions.find((luggage) => luggage.value === numberOfLuggages),
      );
      setValue('pirReferenceFile', addedData.pirReferenceFile);
      setValue(
        'boardingPassOrBookingReferenceFile',
        addedData.boardingPassOrBookingReferenceFile,
      );
      setValue('passportOrIdFile', addedData.passportOrIdFile);
      setValue('baggageTagFile', addedData.baggageTagFile);
    }
  });

  return (
    <form onSubmit={handleSubmit(handleAddPax)}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        backgroundColor="greyscale.100"
        borderBottomRadius="xl"
      >
        <Box p={{ base: 4, md: 5 }} width="100%">
          {maxBags > 2 && (
            <Text
              fontSize="md"
              fontWeight="normal"
              mb={1}
              data-testid="air-luggage-payout-luggage-details-4-bags-info"
            >
              <Trans
                i18nKey="air_luggage.luggage_details.contact_mail_more_than_two_luggage"
                values={{ contactEmail: config.contactEmail, bags: 2 }}
                components={{
                  a: <Link fontSize="md" fontWeight="normal" isExternal />,
                }}
              />
            </Text>
          )}

          <HStack
            alignItems="flex-start"
            spacing={6}
            flexDirection="column"
            pt={2}
          >
            <Controller
              name="payoutEligibleItineraryId"
              control={control}
              rules={{
                required: { value: true, message: t('errors.required') },
              }}
              defaultValue={null}
              render={({
                field: { onChange, name, value },
                fieldState: { invalid, error },
              }) => (
                <Select
                  name={name}
                  isInvalid={invalid}
                  errorMessage={error?.message || ''}
                  label={t('air_luggage.luggage_details.choose_itinerary')}
                  caption={t(
                    'air_luggage.luggage_details.itinerary_where_had_the_luggage_issue',
                  )}
                  value={value}
                  options={itineraryOptions}
                  placeholder={t(
                    'air_luggage.luggage_details.choose_itinerary',
                  )}
                  handleChange={(val) => {
                    handleSelect(val as Option, onChange);
                  }}
                  dataTestId={`payout-eligible-itinerary-id-${id}`}
                />
              )}
            />
            {maxBags > 1 && (
              <Controller
                name="numberOfLuggages"
                control={control}
                rules={{
                  required: { value: true, message: t('errors.required') },
                }}
                defaultValue={null}
                render={({
                  field: { onChange, name, value },
                  fieldState: { invalid, error },
                }) => (
                  <Select
                    name={name}
                    isInvalid={invalid}
                    errorMessage={error?.message || ''}
                    label={t(
                      'air_luggage.luggage_details.number_of_luggage_delayed_or_lost',
                    )}
                    caption={t('air_luggage.luggage_details.under_your_name')}
                    value={value}
                    options={luggagesOptions}
                    placeholder="1"
                    handleChange={(val) => {
                      handleSelect(val as Option, onChange);
                    }}
                    dataTestId={`number-of-luggages-${id}`}
                  />
                )}
              />
            )}
          </HStack>
        </Box>
        {AIR_LUGGAGE_DOCUMENTS.map((document) => {
          const { name, tKey: label } = document;
          return (
            <Controller
              name={name}
              key={name}
              control={control}
              rules={{
                required: { value: true, message: t('errors.required') },
              }}
              defaultValue={null}
              render={({
                field: { value },
                fieldState: { invalid, error },
              }) => {
                return (
                  <Box
                    width="100%"
                    px={{ base: 4, md: 5 }}
                    pb={{ base: 4, md: 5 }}
                  >
                    <AirDocumentsUploader
                      documents={value ? [value] : []}
                      onDrop={(files) => {
                        handleDocument(name, files);
                      }}
                      onDelete={() => {
                        handleDocumentDelete(name);
                      }}
                      isInvalid={invalid}
                      multipleFiles={false}
                      error={error}
                      label={t(label)}
                      dataTestId={dataTestIdFormat(name, id)}
                    />
                  </Box>
                );
              }}
            />
          );
        })}

        {canEdit ? (
          <Flex
            px={{ base: 4, md: 5 }}
            pb={{ base: 4, md: 5 }}
            justifyContent="flex-end"
            width="100%"
          >
            <Button
              variant="primary"
              size="s"
              onClick={handleSubmit(handleAddPax)}
              width={{ base: '100%', md: 'initial' }}
              data-testid={`details-add-button-${id}`}
            >
              {addedData ? t('common.update') : t('common.add')}
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </form>
  );
};

export default DetailsForm;
