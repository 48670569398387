import React from 'react';
import { Outlet } from 'react-router';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useOptimizely } from 'contexts/OptimizelyContext/OptimizelyContext';
import useEffectOnce from 'hooks/useEffectOnce';

const BuyPage: React.FC = () => {
  const { loadScript: loadOptimizelyScript } = useOptimizely();
  const { locale } = useLocale();

  useEffectOnce(() => {
    if (locale === 'en') {
      loadOptimizelyScript();
    }
  });

  return <Outlet />;
};

export default BuyPage;
