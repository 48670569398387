import React from 'react';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { Input } from '@airhelp/react';
import { Box } from '@chakra-ui/react';
import { type TFunction } from 'i18next';
import paymentsFieldsConfig from 'utils/airBundle/paymentsFieldsConfig';

const DEFAULT_MAX_LENGTH_TAX = 11;
const DEFAULT_MIN_LENGTH_TAX = 14;

const tax = ({
  form,
  t,
  countryCode,
}: {
  form: UseFormReturn;
  t: TFunction;
  countryCode: string;
}) => {
  const TAX_MAX_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.tax?.maxLength ||
    DEFAULT_MAX_LENGTH_TAX;

  const TAX_MIN_LENGTH =
    paymentsFieldsConfig[countryCode]?.rules?.tax?.minLength ||
    DEFAULT_MIN_LENGTH_TAX;

  return (
    <Controller
      name="taxId"
      control={form.control}
      rules={{
        required: { value: true, message: t('errors.required') },
        pattern: {
          value: /^[A-Za-z0-9]+$/,
          message: t('errors.please_insert_valid_field', {
            field: t('common.tax_id'),
          }),
        },
        minLength: {
          value: TAX_MIN_LENGTH,
          message: t('errors.too_short', {
            attribute: t('common.tax_id'),
            min: TAX_MIN_LENGTH,
          }),
        },
        maxLength: {
          value: TAX_MAX_LENGTH,
          message: t('errors.too_long', {
            attribute: t('common.tax_id'),
            max: TAX_MAX_LENGTH,
          }),
        },
      }}
      defaultValue=""
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <Box width="100%" minHeight="106px">
          <Input
            isInvalid={invalid}
            errorMessage={error?.message || ''}
            name={name}
            label={t('common.tax_id')}
            value={value}
            placeholder={t('common.tax_id')}
            onChange={onChange}
            minLength={TAX_MIN_LENGTH}
            maxLength={TAX_MAX_LENGTH}
            data-testid="tax-id"
          />
        </Box>
      )}
    />
  );
};

export default tax;
