import React from 'react';
import { AirplaneRight, ReturnFlight } from '@airhelp/icons';
import { Flex, HStack } from '@chakra-ui/react';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { isRtl } from 'utils/textDirection/isRtl';

interface IComponent {
  flightsCount?: number;
  isReturnTrip?: boolean;
}

const TileFooter = ({ flightsCount, isReturnTrip }: IComponent) => {
  const { locale } = useLocale();

  return (
    <HStack alignSelf="flex-end" color="greyscale.700">
      {isReturnTrip ? <ReturnFlight /> : null}
      <AirplaneRight
        h={4}
        w="auto"
        transform={`rotate(${isRtl(locale) ? '180deg' : '0'})`}
      />
      <Flex
        fontWeight="bold"
        fontSize="x-small"
        borderRadius="24px"
        lineHeight="16px"
        ms={2}
        p={2}
        flex="none"
        h={5}
        alignItems="center"
        justifyContent="center"
        backgroundColor="greyscale.300"
      >
        {flightsCount ? flightsCount : 0}
      </Flex>
    </HStack>
  );
};

export default TileFooter;
