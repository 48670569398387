import {
  Flex,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { AltArrowBack, AltArrowForward, QuestionMark } from '@airhelp/icons';
import { useTranslation } from 'react-i18next';
import { RoundedButton } from 'components/RoundedButton/RoundedButton';
import { SupportBox } from 'components/SupportBox';
import Drawer from 'components/Drawer';
import { isRtl } from 'utils/textDirection/isRtl';

interface HeaderProps {
  title: string;
}

export function Header({ title }: HeaderProps) {
  const { t, i18n } = useTranslation();
  const showSupportIcon = useBreakpointValue({
    base: true,
    '2xl': false,
  });

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Flex
      placeItems="flex-start"
      flexDirection={{
        base: 'row',
        '2xl': 'column',
      }}
      justifyContent={{
        base: 'space-between',
        '2xl': 'normal',
      }}
      pb={{
        base: 0,
        md: 10,
        lg: 8,
      }}
    >
      <RoundedButton
        Icon={isRtl(i18n.language) ? AltArrowForward : AltArrowBack}
        label="back"
        navigateTo="/claims"
        dataTestId="back-to-claim-button"
      />
      <Text
        fontSize={{
          base: 'lg',
          '2xl': '24px',
        }}
        fontWeight="bold"
        color="primary.900"
        mt={{
          base: 0,
          '2xl': 10,
        }}
      >
        {title}
      </Text>
      {showSupportIcon ? (
        <RoundedButton
          Icon={QuestionMark}
          label="support"
          onClick={onOpen}
          dataTestId="support-button"
        />
      ) : null}
      {isOpen ? (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          header={t('common.support.how_can_we_help')}
          ps={{
            base: 4,
            md: 8,
          }}
          p={{
            base: 3,
            md: 6,
          }}
        >
          <SupportBox />
        </Drawer>
      ) : null}
    </Flex>
  );
}
