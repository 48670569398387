import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dolar,
  DelayedFlight,
  MoneyProfit,
  AirPayout,
  AirLuggage,
  Luggage,
  VegetarianFood,
  TechnicalSupport,
  PriceTag,
  LuggageSolid,
  Star,
  Lounge,
  ESim as ESimIcon,
  MoneyFee,
} from '@airhelp/icons';
import { orderBy } from 'lodash';
import { type ESim, type Benefits } from 'models/userPolicy';
import PlanDetailsList from '../PlanDetailsList';
import { type PlanItem } from '../PlanDetailsList/PlanDetailsList';
import Section from '../Section';

interface IComponent {
  benefits: Benefits;
  oldPlans: boolean;
  eSim: ESim | null;
}

const YourPlanBenefits: React.FC<IComponent> = ({
  benefits,
  oldPlans,
  eSim,
}) => {
  const { t } = useTranslation();

  const airPayoutTokens = benefits.airPayoutTokens;
  const airLuggageTokens = benefits.airLuggageTokens;
  const airPayoutPayout = `€${benefits.airPayoutPayout.toString()}`;
  const airLuggagePayout = `€${benefits.airLuggagePayout.toString()}`;

  const loungeAccessTokens = benefits.journeyLoungeAccessEnabled
    ? benefits.journeyLoungeAccessTokens
    : benefits.loungeAccessTokens;

  const eSimDiscount = eSim?.discount.toString() || '';
  const airLuggageTip =
    benefits.airLuggageMaxBags === 1 ? 'air_luggage_tip' : 'air_luggage_desc';

  const benefitsListNewPlans: PlanItem[] = [
    {
      title_tkey: benefits.airPayoutUnlimited
        ? 'my_account.my_benefits.your_benefits.plan_benefits.air_payout_unlimited'
        : 'my_account.my_benefits.your_benefits.plan_benefits.air_payout',
      value: { count: airPayoutTokens },
      icon: MoneyProfit,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.air_payout_tip',
        value: { amount: airPayoutPayout },
      },
      key: 'air_payout',
      included: Boolean(benefits.airPayoutIncluded),
    },
    {
      title_tkey: benefits.airLuggageUnlimited
        ? 'my_account.my_benefits.your_benefits.plan_benefits.air_luggage_unlimited'
        : 'my_account.my_benefits.your_benefits.plan_benefits.air_luggage',
      value: { count: airLuggageTokens },
      icon: Luggage,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.air_luggage_tip',
        value: {
          amount: airLuggagePayout,
          bags: benefits.airLuggageMaxBags,
        },
      },
      key: 'air_luggage',
      included: Boolean(benefits.airLuggageIncluded),
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.plan_benefits.airport_lounge_access',
      value: { count: loungeAccessTokens },
      icon: Lounge,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.airport_lounge_access_tip',
      },
      key: 'airport_lounge_access',
      included: Boolean(benefits.loungesIncluded),
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.plan_benefits.compensation_service',
      icon: MoneyFee,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.compensation_service_tip',
      },
      key: 'compensation_service',
      included: Boolean(benefits.freeCompensationIncluded),
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.plan_benefits.dedicated_support',
      icon: TechnicalSupport,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.dedicated_support_tip',
      },
      key: 'dedicated_support',
      included: true,
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.plan_benefits.flight_status',
      icon: DelayedFlight,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.flight_status_tip',
      },
      key: 'flight_status',
      included: true,
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.plan_benefits.travel_perks_and_savings',
      icon: Star,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.plan_benefits.travel_perks_and_savings_tip',
      },
      key: 'travel_perks',
      included: true,
    },
  ];

  const benefitsList: PlanItem[] = [
    {
      title_tkey: benefits.airPayoutUnlimited
        ? 'my_account.my_benefits.your_benefits.list.air_payout_unlimited'
        : 'my_account.my_benefits.your_benefits.list.air_payout',
      value: { count: airPayoutTokens },
      icon: AirPayout,
      tooltip: {
        content: `my_account.my_benefits.your_benefits.list.air_payout_${
          benefits.airPayoutUnlimited ? 'complete' : 'essential'
        }_desc`,
        value: { credits: airPayoutTokens, amount: airPayoutPayout },
      },
      key: 'air_payout',
      included: Boolean(benefits.airPayoutIncluded),
    },
    {
      title_tkey: benefits.airLuggageUnlimited
        ? 'my_account.my_benefits.your_benefits.list.air_luggage_unlimited'
        : 'my_account.my_benefits.your_benefits.list.air_luggage',
      value: { count: airLuggageTokens },
      icon: AirLuggage,
      tooltip: {
        content: `my_account.my_benefits.your_benefits.list.${airLuggageTip}`,
        value: {
          amount: airLuggagePayout,
          bags: benefits.airLuggageMaxBags,
        },
      },
      key: 'air_luggage',
      included: Boolean(benefits.airLuggageIncluded),
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.list.airport_lounge_access',
      icon: VegetarianFood,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.list.airport_lounge_access_desc',
      },
      key: 'airport_lounge_access',
      included: Boolean(benefits.loungesIncluded),
    },
    {
      title_tkey:
        'my_account.my_benefits.your_benefits.list.compensation_claims',
      value: { compensation: '€600' },
      icon: Dolar,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.list.compensation_claims_desc',
        value: { compensation: '€600' },
      },
      key: 'compensation_claims',
      included: true,
    },
    {
      title_tkey: 'my_account.my_benefits.your_benefits.list.expenses_claims',
      value: { compensation: '€6,000' },
      icon: PriceTag,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.list.expenses_claims_desc',
        value: { compensation: '€6,000' },
      },
      key: 'expenses_claims',
      included: true,
    },
    {
      title_tkey: 'my_account.my_benefits.your_benefits.list.luggage_claims',
      value: { compensation: '€1,400' },
      icon: LuggageSolid,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.list.luggage_claims_desc',
        value: { compensation: '€1,400' },
      },
      key: 'luggage_claims',
      included: true,
    },
    {
      title_tkey: 'my_account.my_benefits.your_benefits.list.esim',
      value: { discount: eSimDiscount },
      icon: ESimIcon,
      tooltip: {
        content: 'my_account.my_benefits.your_benefits.list.esim_desc',
      },
      key: 'esim',
      included: Boolean(eSim),
    },
    {
      title_tkey: 'my_account.my_benefits.your_benefits.list.live_chat_support',
      icon: TechnicalSupport,
      tooltip: {
        content:
          'my_account.my_benefits.your_benefits.list.live_chat_support_desc',
      },
      key: 'live_support',
      included: true,
    },
  ];

  const sortedBenefits = orderBy(
    oldPlans ? benefitsList : benefitsListNewPlans,
    ['included'],
    ['desc'],
  );

  return (
    <Section
      header={t('my_account.my_benefits.your_benefits.header')}
      description={t('my_account.my_benefits.your_benefits.description')}
    >
      <PlanDetailsList planDetails={sortedBenefits} />
    </Section>
  );
};

export default YourPlanBenefits;
