import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate } from 'react-router';
import { Button, Flex, Heading, Image } from '@chakra-ui/react';
import errorBuka from 'assets/images/error-buka@2x.png';
import AuthenticationView from 'components/Layouts/AuthenticationLayout/AuthenticationView';
import { PAGE_PATHS } from 'config/routes';

const NotFoundView: React.FC = () => {
  const { t } = useTranslation();
  const [redirect, setRedirect] = useState(false);

  const handleRedirect = () => {
    setRedirect(true);
  };

  if (redirect) {
    return (
      <Navigate
        to={{
          pathname: generatePath(PAGE_PATHS.HOME_PAGE),
        }}
      />
    );
  }

  return (
    <AuthenticationView>
      <Flex
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={{ base: 4, lg: 0 }}
      >
        <Image src={errorBuka} alt="not-found" width="256px" />
        <Heading variant="h5" textAlign="center" mb={{ base: 4, lg: 8 }}>
          {t('error_404.title')}
        </Heading>
        <Button onClick={handleRedirect} width="100%">
          {t('error_404.button')}
        </Button>
      </Flex>
    </AuthenticationView>
  );
};

export default NotFoundView;
