import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Navigate, useSearchParams } from 'react-router';
import { track } from 'react-tracking';
import { PAGE_PATHS } from 'config/routes';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { usePageTracking } from 'utils/tracking';
import CardProcessingView from 'components/MyAccountView/BillingView/CardProcessingView';

const CardProcessingPage: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  usePageTracking();

  usePageNavigationHeader({
    title: t('navigation.my_account'),
    backPath: generatePath(PAGE_PATHS.HOME_PAGE),
  });

  const setupIntentId = searchParams.get('setup_intent') as string;

  return setupIntentId ? (
    <CardProcessingView setupIntentId={setupIntentId} />
  ) : (
    <Navigate
      to={{
        pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE),
      }}
    />
  );
};

export default track({ page_type: 'MyAccount.CardProcessing' })(
  CardProcessingPage,
);
