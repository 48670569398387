import React from 'react';
import { Box, VStack, useBreakpointValue } from '@chakra-ui/react';
import { ProductName } from '@airhelp/plus';
import { mergeWith } from 'lodash';
import { t } from 'i18next';
import { type Offer } from 'models/Offers';
import { getPlanByProductName } from 'components/Membership/membershipHelper';
import {
  OFFER_NAME_FREE,
  OFFER_NAME_LITE,
  FREE_SERVICE_FEE,
  DEFAULT_CURRENCY_PRICE_PLAN,
  planBenefits,
} from 'components/MyAccountView/PlanManagementView/plan-management-config';
import { type PlanDetailsItem } from 'components/MyAccountView/PlanManagementView/types';
import DetailsBenefit from './DetailsBenefit';
import DetailsHeader from './DetailsHeader';
import DetailsHeaderMobile from './DetailsHeaderMobile';

const PlanDetails: React.FC<{
  offer: Offer;
  currentShortPlanName: string;
  isFreeAccount: boolean;
  isProMembership: boolean;
  isCurrentPlan: boolean;
  isFeaturedPlan: boolean;
  onTouchStart?: () => void;
  onTouchEnd?: () => void;
}> = ({
  offer,
  currentShortPlanName,
  isFreeAccount,
  isProMembership,
  isCurrentPlan,
  isFeaturedPlan,
  onTouchStart,
  onTouchEnd,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const shortName = getPlanByProductName({
    productName: offer.name,
  });

  const isMostPopularPlan =
    !isCurrentPlan && offer.name === ProductName.AIRHELP_SMART_ANNUALLY;

  const isFreePlan = shortName === OFFER_NAME_FREE;
  const isLitePlan = shortName === OFFER_NAME_LITE;

  const airPayoutTokens = offer.airPayoutPerk?.credits || 0;
  const airPayout = `€${offer.airPayoutPerk?.config.payout.toString()}`;

  const airLuggageTokens = offer.airLuggagePerk?.credits || 0;
  const airLuggagePayout = `€${offer.airLuggagePerk?.config.payout.toString()}`;

  const loungeAccessTokens = offer.loungeAccessPerk?.credits || 0;
  const eSimDiscount = offer.esimPerk?.config.discount.toString() || '';

  const planBenefitsWithDetails: PlanDetailsItem[] = [
    {
      key: 'compensation_claims',
      included: true,
    },
    {
      key: 'compensation_service',
      content: isFreePlan
        ? t('my_account.my_benefits.plans_compared.service_fee', {
            amount: FREE_SERVICE_FEE,
          })
        : `0%`,
      included: true,
    },
    {
      key: 'flight_status',
      included: true,
    },
    {
      key: 'dedicated_support',
      included: !isFreePlan,
    },
    {
      key: 'exclusive_deals',
      included: !isFreePlan,
    },
    {
      key: 'esim_discount',
      content: `${eSimDiscount}%`,
      included: !isFreePlan,
    },
    {
      key: 'airport_lounge_access',
      content_tkey: 'my_account.my_benefits.plans_compared.trips',
      content_tkey_params: { count: loungeAccessTokens },
      included: !isFreePlan,
    },
    {
      key: 'air_payout',
      content_tkey: 'my_account.my_benefits.plans_compared.trips',
      content_tkey_params: { count: airPayoutTokens },
      content_second_tkey: 'my_account.my_benefits.plans_compared.pay_per_trip',
      content_second_tkey_params: { amount: airPayout },
      included: !isFreePlan,
    },
    {
      key: 'air_luggage',
      content_tkey: 'my_account.my_benefits.plans_compared.trips',
      content_tkey_params: { count: airLuggageTokens },
      content_second_tkey: 'my_account.my_benefits.plans_compared.pay_per_trip',
      content_second_tkey_params: { amount: airLuggagePayout },
      included: !isFreePlan,
    },
  ];

  const planBenefitsMerged = planBenefits.map((benefit) => {
    const match = planBenefitsWithDetails.find(
      (benefitDetails) => benefitDetails.key === benefit.key,
    );

    return mergeWith({}, benefit, match, (objValue, srcValue) => {
      return srcValue !== undefined ? srcValue : objValue;
    });
  });

  const priceDetails = offer.prices[DEFAULT_CURRENCY_PRICE_PLAN];

  return (
    <VStack
      minWidth="145px"
      width="100%"
      alignItems="stretch"
      justifySelf="flex-start"
      data-plan={shortName}
      data-featured={isFeaturedPlan}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      zIndex="1"
    >
      {isMobile ? (
        <DetailsHeaderMobile
          name={shortName}
          priceDetails={priceDetails}
          isFreeAccount={isFreeAccount}
          isCurrentPlan={isCurrentPlan}
          isFeaturedPlan={isFeaturedPlan}
          isMostPopularPlan={isMostPopularPlan}
        />
      ) : (
        <DetailsHeader
          name={shortName}
          currentShortPlanName={currentShortPlanName}
          priceDetails={priceDetails}
          isFreeAccount={isFreeAccount}
          isProMembership={isProMembership}
          isCurrentPlan={isCurrentPlan}
          isFeaturedPlan={isFeaturedPlan}
        />
      )}
      <Box
        width="100%"
        height="100%"
        borderRadius="14px"
        background={
          isFeaturedPlan
            ? 'linear-gradient(90deg, #117bff 0%, #8C62FC 60%, #fd6b74 100%)'
            : 'greyscale.400'
        }
        boxShadow={
          isFreePlan || isLitePlan
            ? 'none'
            : '0px 12px 16px -4px rgba(30, 41, 54, 0.08),0px 4px 8px -4px rgba(30, 41, 54, 0.04)'
        }
        p={{ base: '0px', md: '1px' }}
        className="container"
      >
        <VStack
          background="greyscale.100"
          borderRadius="13px"
          px={{ base: 2, md: 4 }}
          py={{ base: 2, md: 0 }}
          alignItems="stretch"
          justifySelf="flex-end"
          justifyContent="center"
          gap={0}
          width="100%"
          height="100%"
        >
          {planBenefitsMerged.map((benefit) => (
            <DetailsBenefit benefit={benefit} key={benefit.key} />
          ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default PlanDetails;
