import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import useLocalStorage from 'hooks/useLocalStorage';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { useSideNav } from '../SideNavProvider';
import CollapseButton from './CollapseButton';
import Navigation from './Navigation';

const NavBar: React.FC = () => {
  const { userProfile } = useMyProfile();
  const { portalRef } = useSideNav();
  const [layoutCollapse] = useLocalStorage('layout-collapsed');

  if (!userProfile) {
    return null;
  }

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showPaymentManagementBanner = Boolean(
    userProfile?.policy.membership?.showPaymentManagementBanner,
  );

  const getPaddingTop = () => {
    if (showPaymentManagementBanner && showTopBannerEssentialUpgrade) {
      return '200px';
    }
    if (showTopBannerEssentialUpgrade || showPaymentManagementBanner) {
      return '136px';
    }
    return '72px';
  };

  return (
    <Box
      as="nav"
      backgroundColor="greyscale.100"
      height={{ base: '78px', md: '100%' }}
      borderRight="1px solid"
      borderRightColor="greyscale.400"
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      paddingTop={{ base: 0, md: getPaddingTop() }}
      textAlign={{ base: 'center', lg: 'left' }}
      transition="width 300ms ease-in-out"
      width={{
        base: '100%',
        md: '88px',
        lg: layoutCollapse ? '88px' : '278px',
      }}
      zIndex={{ base: '1000', md: '1' }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        alignItems={{ base: 'center', md: 'flex-start' }}
      >
        <Navigation userProfilePolicy={userProfile?.policy} />
        {layoutCollapse ? null : (
          <Box
            ref={portalRef}
            px={4}
            display={{ base: 'none', lg: 'block' }}
            width={layoutCollapse ? 0 : '100%'}
            transition="width 300ms ease-in-out"
          />
        )}
        <CollapseButton />
      </Flex>
    </Box>
  );
};

export default NavBar;
