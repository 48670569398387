import React, { useContext, useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router';
import { track } from 'react-tracking';
import RouteDestinationView from 'components/Flights/New/RouteDestinationView';
import { PAGE_PATHS } from 'config/routes';
import { EditedJourneyContext } from 'contexts';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { usePageTracking } from 'utils/tracking';
import { useTrackEvent } from 'utils/tracking/hooks';
import useProtectedPage from 'hooks/routing/useProtectedPage';

const RouteDestinationPage = () => {
  usePageTracking();
  useProtectedPage();

  const { itineraryIndex, fromRoute } = useContext(EditedJourneyContext);
  const { trackCtaClick } = useTrackEvent();
  const navigate = useNavigate();
  const { setActiveStep } = useLayout();

  useEffect(() => {
    setActiveStep(0);
  }, []);

  const nextStep = () => {
    navigate({
      pathname: generatePath(PAGE_PATHS.FLIGHTS.NEW.CONNECTING_FLIGHTS_PAGE),
      search: `?itineraryIndex=${itineraryIndex}&src=${fromRoute}`,
    });

    trackCtaClick(
      'continue',
      `${itineraryIndex === 0 ? 'outbound' : 'inbound'} - route destination`,
    );
  };

  return <RouteDestinationView onNextStep={nextStep} />;
};

export default track({ page_type: 'Flights.New.RouteDestination' })(
  RouteDestinationPage,
);
