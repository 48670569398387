import React from 'react';
import { generatePath } from 'react-router';
import { buildSteps, step } from '@airhelp/funnel';
import { ReactRouterFunnel, useFunnelInit } from '@airhelp/funnel/react-router';
import { ContentSpinner } from '@airhelp/react';
import { PAGE_PATHS } from 'config/routes';
import { NotFoundPage } from 'pages';
import {
  AirLuggagePage,
  ConnectingFlightsPage,
  FlightsDetailsPage,
  ReturnFlightPage,
  RouteDestinationPage,
} from 'pages/Flights';

export interface ComponentPageProps {
  defaultActiveStep: number;
}

export const useInitFlightsFunnel = () => {
  return useFunnelInit(() => {
    const basePath = generatePath(PAGE_PATHS.FLIGHTS.NEW_PAGE);

    return {
      basePath,
      steps: buildSteps([
        step({
          name: 'route-destination',
          uri: '/route-destination',
          Component: () => <RouteDestinationPage />,
        }),
        step({
          name: 'connecting-flights',
          uri: '/connecting-flights',
          Component: () => <ConnectingFlightsPage />,
        }),
        step({
          name: 'flights-details',
          uri: '/flights-details',
          Component: () => <FlightsDetailsPage />,
        }),
        step({
          name: 'return-flight',
          uri: '/return-flight',
          Component: () => <ReturnFlightPage />,
        }),
        step({
          name: 'air-luggage',
          uri: '/air-luggage',
          Component: () => <AirLuggagePage />,
        }),
      ]),
    };
  });
};

export const FlightsFunnel = () => {
  const definition = useInitFlightsFunnel();
  return (
    <ReactRouterFunnel
      definition={definition}
      loadingIndicator={<ContentSpinner />}
      notFoundStep={<NotFoundPage />}
    />
  );
};
