import React from 'react';
import { Box } from '@chakra-ui/react';

const DiscountOffBadge = ({ text }: { text: string }) => {
  return (
    <Box
      borderRadius="lg"
      color="success.500"
      backgroundColor="success.200"
      textTransform="uppercase"
      fontWeight="medium"
      fontSize="10px"
      maxHeight="28px"
      maxWidth="115px"
      width="100%"
      whiteSpace="nowrap"
      overflow="hidden"
      textOverflow="ellipsis"
      textAlign="center"
      py={1}
      px={2}
    >
      {text}
    </Box>
  );
};

export default DiscountOffBadge;
